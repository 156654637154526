/* eslint-disable max-len */
export default {
    nl: {
        aboutToExpire: 'Verloopt binnenkort',
        absentOn: 'Afwezig op',
        accept: 'Accepteren',
        Accept: 'Ter acceptatie',
        acceptAuthSingle: 'U staat op het punt om {number} verzoek te accepteren',
        acceptAuthPlural: 'U staat op het punt om {number} verzoeken te accepteren',
        acceptance: 'acceptatie',
        Accepted: 'Geaccepteerd',
        acceptedSelection: 'Selectie geaccepteerd!',
        acceptedPartialSelection: 'Selectie deels geaccepteerd!',
        accreditation: 'Accreditatie',
        'accreditation.deleteDialog.body': `Weet je zeker dat je de accreditatiepoging opnieuw wilt proberen? Dit proces wordt 's nachts uitgevoerd.`,
        'accreditation.deleteDialog.title': 'Bevestig nieuwe poging',
        'accreditation.deleteDialog.confirmButton': 'Probeer Opnieuw',
        'accreditation.status': `{status, select,
            accepted {Geaccepteerd}
            failed {Mislukt}
            badResponse {Ongeldig antwoord}
            error {Fout}
            pending {In behandeling}
            notValid {Ongeldig}
            other {Geen status}
        }`,
        'accreditation.process': 'Verwerken',
        'accreditation.processed': 'Verwerkt',
        'accreditation.endDate': 'Voldaan op',
        'accreditation.info': 'Accreditatie instellingen',
        'accreditation.processSuccess': 'Accreditaties zijn verwerkt',
        'accreditation.processTitle': 'Accreditaties verwerken',
        'accreditation.processText':
            'Alle resultaten van deze opleiding zullen direct worden verwerkt. Het is mogelijk dat dit enige tijd duurt.',
        'accreditation.processDoYouWantToContinue': 'Wil je doorgaan met verwerken?',
        accreditationDeleted: 'Accreditatie verwijderd',
        accreditations: 'Accreditaties',
        achieved: 'Behaald',
        AchievedResults: 'Behaalde resultaten',
        achievedOn: 'Behaald op',
        Achievement: 'Resultaat',
        AchievementIsAboutToExpire: 'Uw resultaat verloopt binnenkort!',
        AchievementIsExpired: 'Uw resultaat is verlopen',
        AchievementIsFailed: 'U bent gezakt',
        AchievementIsMandatory: 'Uw resultaat is verplicht!',
        AchievementIsPassed: 'Catalogusitem behaald / voldaan',
        achievementRead: 'Ik heb de handleiding / het protocol gelezen',
        achievementStatus: `{status, select,
            aborted {Afgebroken}
            absent {Afwezig}
            canceled {Geannuleerd}
            completed {Voldaan}
            deleted {Verwijderd}
            expired {Verlopen}
            failed {Niet voldaan}
            faulted {Fout}
            not_registered {Niet ingeschreven}
            not_started {Niet gestart}
            passed {Voldaan}
            started {Gestart}
            registered {Ingeschreven}
            unregistered {Uitgeschreven}
            other {Onbekend}
        }`,
        achievementStatusForUserCourseResults: `{status, select,
            aborted {Afgebroken}
            absent {Afwezig}
            canceled {Geannuleerd}
            completed {Voldaan}
            deleted {Verwijderd}
            expired {Voldaan (verlopen)}
            failed {Niet voldaan}
            faulted {Fout}
            not_registered {Niet ingeschreven}
            not_started {Niet gestart}
            passed {Voldaan}
            started {Gestart}
            registered {Ingeschreven}
            unregistered {Uitgeschreven}
            other {Onbekend}
        }`,
        activate: 'Activeren',
        ActivateELearning: 'E-learning (de)activeren',
        active: 'Actief',
        Active: 'Actief',
        activities: 'Activiteiten',
        activity: 'Activiteit',
        activityName: 'Naam activiteit',
        add: 'toevoegen',
        Add: 'Toevoegen',
        addAccreditation: 'Accreditatie toevoegen',
        AddAFavoriteFirst: 'Reset het filter en kies je eerste favoriete item!',
        addAnActivity: 'Voeg een activiteit toe',
        addAnswer: 'Antwoord toevoegen',
        addAssessment: 'Beoordeling toevoegen',
        addAssessors: 'Voeg gebruikers toe die anderen mogen toetsen',
        addAssets: 'Hulpmiddelen toevoegen',
        addAttachments: 'Bijlage(n) toevoegen',
        addBlock: 'Blok toevoegen',
        addCategory: 'Categorie toevoegen',
        addComment: 'Opmerking toevoegen',
        addCourse: 'Klassikale opleiding toevoegen',
        addEmployee: 'Medewerker toevoegen',
        addExemption: 'Vrijstelling toevoegen',
        addExternalCatalogItem: 'Externe content toevoegen',
        addFiles: {
            one: 'Selecteer bestand...',
            many: 'Selecteer bestanden...',
        },
        addForm: 'Formulier toevoegen',
        addGroup: 'Groep toevoegen',
        addIndividuals: 'Individuen toevoegen',
        Additional: 'Extra',
        addMailTemplate: 'E-mailsjabloon toevoegen',
        addNew: 'Nieuwe toevoegen',
        AddNewsItem: 'Nieuwsbericht toevoegen',
        addNewPrice: 'Nieuwe prijs toevoegen',
        addOption: 'Optie toevoegen',
        addPlanners: 'Voeg gebruikers toe aan wie u planner-rechten wilt geven aan afdelingen',
        addPlanning: 'Rooster toevoegen',
        AddPortalGroup: 'Afdeling toevoegen',
        addQuestion: 'Vraag toevoegen',
        address: 'Adres',
        addressGroup: 'Adresgegevens',
        addReservations: 'Reserveringen toevoegen',
        addReviewers:
            'Voeg hier gebruikers toe die "alleen lezen" rechten op de afdelingen moeten hebben',
        addSchedule: 'Sessie toevoegen',
        addSubcategory: 'Subcategorie toevoegen',
        addTeacher: 'Docent toevoegen',
        AddTextAndPressEnter: 'Voer een tekst in en druk op ENTER',
        addTextField: 'Tekstveld toevoegen',
        addTheorem: 'Stelling toevoegen',
        addToVirtualQueue: 'Ik heb interesse in deze training',
        addToVirtualQueueDialogHeader: 'Interesse kenbaar maken voor dit catalogusitem?',
        addToVirtualQueueDialogMessage:
            'Jouw interesse voor dit catalogusitem wordt vastgelegd. Bij voldoende interesse kan besloten worden om in de toekomst nieuwe sessies beschikbaar te stellen.',
        addTraining: 'Opleiding toevoegen',
        addUser: 'Gebruiker toevoegen',
        addUsers: 'Gebruikers toevoegen',
        administration: 'Beheer',
        administrators: 'Beheerders',
        administratorsAdd: 'Beheerders toevoegen',
        advised: 'Geadviseerd',
        agree: 'Eens',
        all: 'Alle',
        allAssignmentsAreDeleted: 'Alle toewijzingen zijn verwijderd',
        allMandatoryFieldsMustBeFilled: 'Alle verplichte velden moeten worden ingevuld',
        AlmostExpired: 'Bijna verlopen',
        allDepartments: 'Alle afdelingen',
        Allignment: 'Uitlijning',
        allPresent: 'Allemaal aanwezig',
        allowedToFollow: 'Mag doen',
        allowMandatoryAssignments: 'Verplichte toewijzingen',
        allowMandatoryAssignmentsDescription:
            'Zet verplicht maken van catalogusitems applicatiebreed aan of uit',
        allowNonExistingCombinations: 'Niet bestaande combinaties toestaan',
        allowOnlyYourOwnNames: 'Alleen eigen benamingen toestaan',
        allowQueue: 'Wachtrij',
        AllowEnroll: 'Inschrijven toestaan',
        AllowUnenroll: 'Uitschrijven toestaan',
        allowVirtualQueue: 'Interesselijst',
        'allowVirtualQueue.explainer':
            'Gebruikers kunnen hun interesse kenbaar maken als er geen opleidingen gepland zijn',
        allQuestionsAreMandatory: 'Alle vragen zijn verplicht',
        allTypes: 'Alles',
        always: 'Altijd',
        answerText: 'Antwoord tekst',
        applicationParts: 'Applicatie onderdelen',
        apply: 'Toepassen',
        appointment: 'afspraak',
        appointmentAt: 'Afspraak op',
        approved: 'Bekwaam',
        approvedDate: 'Bekwaam datum',
        aProblemHasOccuredContactTheAdministrator:
            'Er is een probleem opgetreden. Neem contact op met de beheerder',
        archive: 'Archief',
        AreYouSureYouWantToDeactivateThisPackage: 'Weet u zeker dat u dit pakket wilt deactiveren?',
        AreYouSureYouWantToDeleteThisPackage: 'Weet u zeker dat u dit pakket wilt verwijderen?',
        areYouSureYouWantToDelete_what_: `Weet u zeker dat u {what, select,
            role {deze rol}
            other {''{what}''}
        } wilt verwijderen?`,
        areYouSureYouWantToDeleteAllAssignmentsForThisAssessor:
            'Weet u zeker dat u alle toewijzingen voor deze beoordelaar wilt verwijderen?',
        areYouSureYouWantToDeleteThisAssessment:
            'Weet u zeker dat u deze beoordeling wilt verwijderen?',
        areYouSureYouWantToDeleteThisBlock: 'Weet je zeker dat je dit blok wilt verwijderen?',
        areYouSureYouWantToDeleteThisCategory: 'Weet u zeker dat u deze categorie wilt verwijderen',
        areYouSureYouWantToDeleteThisComment: 'Weet u zeker dat u dit commentaar wilt verwijderen?',
        areYouSureYouWantToDeleteThisConnection:
            'Weet u zeker dat u deze toewijzing wilt verwijderen',
        areYouSureYouWantToDeleteThisForm: 'Weet u zeker dat u dit formulier wilt verwijderen?',
        areYouSureYouWantToDeleteThisGroup: 'Weet u zeker dat u deze groep wilt verwijderen',
        areYouSureYouWantToDeleteThisIndividualSkill:
            'Weet u zeker dat u deze individuele bekwaamheid wilt verwijderen',
        areYouSureYouWantToDeleteThisMessage: 'Weet u zeker dat u dit bericht wilt verwijderen',
        areYouSureYouWantToDeleteThisQuestion: 'Weet u zeker dat u deze vraag wilt verwijderen',
        areYouSureYouWantToDeleteThisSection: 'Weet u zeker dat u dit blok wilt verwijderen',
        areYouSureYouWantToDeleteThisSkill: 'Weet u zeker dat u deze bekwaamheid wilt verwijderen',
        areYouSureYouWantToDeleteThisTextField: 'Weet u zeker dat u dit tekstveld wilt verwijderen',
        areYouSureYouWantToDisconnectThisEmployee:
            'Weet u zeker dat u deze medewerker wilt ontkoppelen?',
        areYouSureYouWantToUnsubscribe: 'Weet u zeker dat u zich uit wilt schrijven?',
        are_you_sure_you_want_to_delete_this_accreditation:
            'Weet u zeker dat u deze accreditatie wilt verwijderen?',
        are_you_sure_you_want_to_delete_this_location:
            'Weet u zeker dat u deze locatie wilt verwijderen?',
        are_you_sure_you_want_to_delete_this_schedule:
            'Weet u zeker dat u deze sessie wilt verwijderen?',
        are_you_sure_you_want_to_delete_this_tool:
            'Weet u zeker dat u dit hulpmiddel wilt verwijderen?',
        are_you_sure_you_want_to_remove_this_user_from_list:
            'Weet u zeker dat u deze gebruiker uit de lijst wilt verwijderen?',
        areYouSureYouWantToUnregister: 'Weet u zeker dat u zich wilt uitschrijven?',
        areYouSureYouWantToUnregisterThisStudent:
            'Weet u zeker dat u deze deelnemer wilt uitschrijven?',
        askPermission: 'Toestemming aanvragen',
        assessedOn: 'Beoordelingsdatum',
        assessEmployees: 'Medewerkers beoordelen',
        assessment: 'Beoordeling',
        assessmentName: 'Naam beoordelingsformulier',
        assessmentDescription: 'Beschrijving beoordeling',
        assessmentForm: 'Beoordelingsformulier',
        assessmentsForms: 'Beoordelingsformulieren',
        assessmentResult: 'Beoordelingsresultaat',
        assessments: 'Beoordelingen',
        assessmentIncomplete: 'Beoordeling is niet volledig ingevuld',
        assessmentMustContainAtLeastOneSection: 'Beoordeling moet tenminste één blok bevatten',
        assessmentSectionsMustContainAtLeastOneQuestion:
            'Blokken moeten tenminste één vraag bevatten',
        assessmentQuestionMustHaveYesNoOptions: 'Vraag moet ja/nee als opties hebben',
        assessor: 'Beoordelaar',
        assessors: 'Toetsers',
        assessorsManagement: 'Beoordelaarsbeheer',
        assessorsView: 'Beoordelaarsweergave',
        examinerWithName: 'Toetser: {name}',
        examinerAppointments: 'Toetsafspraken',
        examinerAppointmentsOverview: 'Lijst van toetsafspraken',
        examinerFirstAvailableTimeSlot: 'Eerst beschikbare tijdstip',
        examinermanagement: 'Toetserbeheer',
        examinerNoLocation: 'Geen locatie gevonden!',
        assign: 'Toewijzen',
        'assignMatrix.title': `{type, select,
            department {Gehele afdeling}
            group {Gebruiker / groep}
            user {Gebruiker}
            other {{type}}
        }`,
        'assignMatrix.department.explainer': 'Ongeacht functie',
        assignCatalogItems: 'Catalogusitems toewijzen',
        assignCatalogItemsBulk: 'Welke catalogusitems wil je toewijzen?',
        assignCatalogItemsBulkChooseDomain: 'Kies een domein',
        assignCatalogItemsBulkChooseFunctions: 'En aan welke functies?',
        assignCatalogItemsBulkMandatory: 'Verplicht maken?',
        assignCatalogItemsBulkToDomainOrDepartment:
            'Wil je toewijzen op domein of afdeling / functie?',
        assignCatalogItemsBulkSelectAllFunctions: 'Alle functies selecteren',
        assignCatalogItemsBulkWithDepartments: 'Aan welke afdelingen wil je deze toewijzen?',
        AssignCertificateToSelectedCatalogItems:
            'Wijs certificaat toe aan geselecteerde catalogusitems',
        assignedTrainings: 'Toegewezen opleidingen',
        assignEmployee: 'Medewerker toewijzen',
        assignEmployees: 'Medewerkers toewijzen',
        assignedIndividual: 'Individueel toegewezen',
        assignedToFunction: 'Toegewezen aan functie',
        assignIndividual: 'Individu toewijzen',
        assignIndividuals: 'Individuen toewijzen',
        assignManagers: 'Toewijzen managers',
        assignments: 'Toewijzingen',
        assignmentsIndividual: 'Individuele toewijzingen',
        assignedTo: 'Toegewezen aan',
        assignToDepartmentFunction: 'Toewijzen aan afdeling / functie',
        asSoonAsAPlaceBecomesAvailableTheNextParticipantWillBeRegisteredAutomatically:
            'Als maximum aantal deelnemers is bereikt, kan een gebruiker zich inschrijven op de wachtrij om automatisch ingeschreven te worden zodra een plek vrij komt.',
        asSoonAsSpaceBecomesAvailableTheEntireQueueWillReceiveAnInvitation:
            'Zodra er plek vrijkomt krijgt de volledige wachtrij een uitnodiging',
        at: 'om',
        atLeastOneFunctionMustBeSelected: 'Er moet tenminste een functie geselecteerd worden.',
        atLeastOneBlockMustAdded: 'Er moet tenminste een blok toegevoegd worden.',
        atLeastOneActivityMustAdded: `Er moet minimaal één activiteit aan het blok worden toegevoegd. In het geval van Basis/Vervolg moet zowel voor Basis als Vervolg tenminste een activiteit toegevoegd worden`,
        attachedCertificate: 'Gekoppeld certificaat',
        attachedUsers: 'Gekoppelde medewerker(s)',
        attachments: 'Bijlage(n)',
        attendanceList: 'Presentielijst',
        attendees: 'Aanwezigen',
        askAuthorizationToManager:
            'Er is toestemming nodig om deze e-learning te volgen, wil je deze aanvragen?',
        authorizationNeededByManager: 'Autorisatie vereist door manager',
        authorizationRequest: `{ number, select,
            1 {Beoordeel 1 verzoek}
            other {Beoordeel {number} verzoeken}
        }`,
        AuthorizationRequests: 'Autorisatie',
        AvailableLabels: 'Beschikbare labels',
        AvailableVersions: 'Beschikbare versies',
        beginDate: 'Startdatum',
        beginTime: 'Starttijd',
        back: 'terug',
        Back: 'Terug',
        basic: 'Basis',
        belowYouCanSeeThePlanningOfYourLessons:
            'Rooster (indien van toepassing) en eventuele opmerking deelnemer',
        belowYouSeeTheListWhereYouNeedToUpdateThePresence:
            'Hieronder zie je de lijst waarvan je de presentie moet bijwerken',
        belowYouSeeThePlanningOfTheLessonsForWhichYouAreRegistered:
            'Hieronder zie je het rooster van de lessen waarvoor je ingeschreven bent',
        betaHeadline: 'Benieuwd naar de nieuwe vormgeving? Probeer deze gerust uit!',
        bhpStatusEnrolled: 'Ingeschreven',
        bhpStatusAchieved: 'Behaald op',
        bhpValidity: 'Geldigheid',
        block: 'Blok',
        blockName: 'Naam blok',
        Bold: 'Vet',
        both: 'Beide',
        bulkAssignmentsSaved: 'Gelukt, catalogusitems zijn toegewezen',
        buy: 'Betalen',
        by: 'Door',
        calendar: 'Kalender',
        calculateSupportColors: 'Steunkleuren automatisch berekenen',
        cancel: 'Annuleren',
        cancelled: 'Geannuleerd',
        Cancelled: 'Geannuleerd',
        cancelPendingRequest: 'Wil je de lopende aanvraag intrekken?',
        cancelSchedule: 'Planning annuleren',
        canTest: 'Kan aftoetsen',
        capacity: 'Capaciteit',
        capacityReached: 'Rooster is vol',

        catalog: 'Catalogus',
        'catalog.fab.course': 'Klassikale opleiding toevoegen',
        'catalog.fab.elearning': 'E‑learning (de)activeren',
        'catalog.participants.heading.lastAction': 'Laatste actie',
        'catalog.participants.filter.action': 'Actie',
        'catalog.participants.filter.name': 'Naam medewerker',
        'catalog.participants.filter.dateFrom': 'Datum vanaf',
        'catalog.participants.filter.dateUntil': 'Datum t/m',
        'catalog.participants.heading.result': 'Resultaat',
        'catalog.participants.heading.bestResult': 'Beste resultaat',
        'catalog.participants.heading.expirationDate': 'Verloopdatum',
        'catalog.participants.heading.currentStatus': 'Huidige status',
        'catalog.participants.heading.date': 'Datum',
        catalogAuthorizationRequests: 'Catalogus autorisatieverzoeken',
        catalogAssignmentWarning:
            'Alle geselecteerde catalogusitems worden definitief toegewezen op basis van de gemaakte keuzes.',
        catalogAssignmentWarning2: 'Dit kan alleen handmatig ongedaan gemaakt worden.',
        CatalogInterests: 'Interesselijst',
        CatalogInterestsDetails: 'Catalogusitems op jouw interesselijst',
        catalogItem: 'Catalogusitem',
        catalogItemCompleted: ' - laatst behaald op: ',
        catalogItemAboutToExpire: 'Catalogusitem verloopt binnenkort!',
        catalogItemExpired: 'Catalogusitem is verlopen!',
        catalogItemMandatory: 'Catalogusitem is verplicht!',
        catalogItemPassed: 'Catalogusitem behaald / voldaan',
        'catalogItem.status': `{status, select,
            disabled {[UITGESCHAKELD]}
            deleted {[VERWIJDERD]}
            other {}
        }`,
        'catalogItem.replaces': 'Deze cursus vervangt de volgende cursus(sen)',
        'catalogItem.replacedBy': 'Deze cursus is vervangen door de volgende cursus',
        'catalogEdit.general': 'Catalogusitem algemeen',
        categories: 'Categorieën',
        categoriesNotFound: 'Geen beschikbare categorieën gevonden',
        category: 'Categorie',
        categoryAdd: 'Categorie toevoegen',
        categoryDelete: 'Categorie verwijderen',
        categoryDeleteWarning: 'Weet je zeker dat je deze categorie wilt verwijderen?',
        categoryDeleted: 'Categorie verwijderd',
        categoryEdit: 'Categorie bewerken',
        categoryName: 'Categorienaam',
        Center: 'Centreren',
        'cert-all': 'Alles',
        'cert-yes': 'Alles met certificaat',
        'cert-no': 'Alles zonder certificaat',
        certificate: 'Certificaat',
        certificates: 'Certificaten',
        certificateApply1: `Het certificaat ''{certificaat}'' `,
        certificateApply2: 'wordt toegepast op {number} catalogusitem(s).',
        certificateAssign: 'Certificaten toewijzen',
        certificateDefinition: 'Certificaatdefinitie',
        certificateDefinitions: 'Certificaten',
        certificateDefinitions2: 'Certificaatdefinities',
        certificateDeleteConfirm: 'Weet u zeker dat u deze certificaat-definitie wilt verwijderen?',
        certificateDeleteWarning: 'Deze definitie wordt {number} keer gebruikt in catalogusitems!',
        certificateHide: 'Verberg items met certificaat:',
        certificateLabelDeleteHeader: 'Geselecteerde label verwijderen?',
        certificateLabelDelete: 'Wilt u dit label verwijderen?',
        certificateLabelNew: 'Nieuw leeg label',
        certificateLabelUsername: 'Label: gebruikersnaam',
        certificateLabelBirthdate: 'Label: Geboortedatum',
        certificateLabelCoursename: 'Label: Cursusnaam',
        certificateLabelResultdate: 'Label: Datum behaald',
        certificateLabelPoints: 'Label: Score',
        certificateShow: 'Toon items met certificaat:',
        changeCategory: 'Categorie wijzigen',
        changeComment: 'Commentaar wijzigen',
        changed: 'aangepast',
        changeOrder: 'Volgorde wijzigen',
        changePassword: 'Wijzig wachtwoord',
        changeProfile: 'Wijzig profiel',
        changeYourPassword: 'Wijzig uw wachtwoord',
        changeRegistration: 'Inschrijving wijzigen',
        changeSsoAccount: 'Wijzig account',
        checkAppointments: 'Bestaande afspraken blijven staan!',
        checkPortfolioItems: 'Controleer een dossieritem',
        checkPortfolioItemsPlural: 'Controleer {number} dossieritems',
        chosenDate: 'Gekozen datum',
        chosenTraining: 'Gekozen opleiding',
        chooseAnEvaluationForm: 'Kies een evaluatieformulier',
        chooseAnExaminer: 'Kies een toetser',
        chooseATimeslot: 'Kies een tijdstip',
        chooseASkillThatYouWantToLinkToTheFunctions:
            'Kies een bekwaamheid die je aan de functie wilt koppelen',
        chooseASkillThatYouWantToLinkToThisDepartment:
            'Kies een bekwaamheid die je aan deze afdeling wilt koppelen.',
        chooseCertificate: 'Kies certificaat',
        chooseDateAndRegion: 'Kies datum en regio voor het inplannen van een toetsmoment',
        chooseDeleteToPreventThisUserFromReviewingAnySkill: `Kies voor ''VERWIJDEREN'' om te zorgen dat deze gebruiker nergens meer mag beoordelen.`,
        chooseFunctions: 'Kies functies',
        chooseImportToMakeThisUsersAnAssessorForTheSameActivitiesAsAnotherUser: `Kies voor ''IMPORTEREN'' om deze gebruiker beoordelaar te maken bij dezelfde activiteiten als een andere gebruiker.`,
        choosePortfolioDefinition: 'Kies een dossieritem definitie',
        ChooseWhichRolesYouWantToUncouple: 'Kies welke rollen u wilt ontkoppelen',
        city: 'Stad',
        class: 'Klas',
        classDeleted: 'Klas verwijderd',
        classes: 'Klassen',
        classical: 'Klassikaal',
        Classical: 'Klassikaal',
        classicalTheoryLesson: 'Klassikale theorieles',
        classroom: 'Klassikaal',
        Classroom: 'Klassikaal',
        'classroom.conflictMessage.maxCapacityLowerThanMinCapacity':
            'Max. aantal deelnemers is lager dan min. aantal deelnemers',
        'classroom.teacheroverviewfilter.all': 'Alles',
        'classroom.teacheroverviewfilter.active': 'Actief',
        'classroom.teacheroverviewfilter.cancelled': 'Geannuleerd',
        ClassroomActivities: 'Klassikale activiteiten',
        ClassroomActivity: 'Klassikale activiteit',
        ClassroomCancel: 'Klassikale opleiding annuleren',
        ClassroomDequeue: 'Uitschrijven van wachtrij',
        ClassroomEnroll: 'Inschrijven bij klassikale opleiding',
        ClassroomEnqueue: 'Inschrijven op wachtrij',
        ClassroomExaminerActivity: 'Klassikale- en toetsactiviteiten',
        ClassroomUnenroll: 'Uitschrijven bij klassikale opleiding',
        classroomTraining: 'Klassikale opleidingen',
        classroomTypes: 'Klassikaal',
        clear: 'Vrijmaken',
        ClearImage: 'Verwijder afbeelding',
        ClearList: 'Leeg lijst',
        clickOnRegisterToRegisterYourself: `Klik op ''INSCHRIJVEN'' om u zelf in te schrijven.`,
        close: 'Afsluiten',
        closeDown: 'Sluiten',
        closeRegistration: 'Inschrijving sluiten',
        code: 'code',
        Code: 'Code',
        combineSkillExams: 'Toetsmomenten combineren',
        colleague: 'Collega',
        color: 'Kleur',
        comment: 'Opmerking',
        commentDate: 'Datum van de opmerking',
        comments: 'Opmerkingen',
        communication: 'Communicatie',
        compatibility: 'Compatibiliteit',
        complete: 'Volledig',
        completed: 'Voldaan',
        Completed: 'Afgehandeld',
        completedStatus: 'Voldaan',
        completionDate: 'Afrondingsdatum',
        'components.acaDepartmentSearch.label': 'Zoek op (sub)afdeling',
        composition: 'e-Learning',
        compulsory: 'Verplicht',
        compulsoryNumber: 'Aantal verplicht',
        confirm: 'Bevestigen',
        confirmDelete: `Weet u zeker dat u ''{name}'' wilt verwijderen?`,
        ConfirmNewPassword: 'Bevestig nieuw wachtwoord',
        ConfirmUnenroll: 'Weet u zeker dat u wilt uitschrijven?',
        ConflictingEnrollmentFound: 'Er is een conflicterende inschrijving gevonden',
        ConflictingSchedule:
            'Kan schema niet aanmaken omdat deze conflicteert met een bestaand schema.',
        connect: 'Koppelen',
        connected: 'Gekoppeld',
        connectFunctions: 'Functies koppelen',
        connectSkill: 'Bekwaamheid koppelen',
        contactGroup: 'Contact',
        contactInformation: 'Contactinformatie',
        contentEndDate: 'Deze cursus is vanaf datum {date} niet meer te starten!',
        contentExpired: 'Deze cursus is verlopen en kan daarom niet meer gestart worden!',
        contents: 'Inhoud',
        continue: 'Doorgaan',
        copied: 'Gekopieerd',
        copy: 'Kopiëren',
        copyAssignments: 'Toewijzingen kopiëren',
        copyAssignmentsToOtherUsers: 'Toewijzingen kopiëren naar andere gebruikers',
        copyNoun: 'Kopie',
        country: 'Land',
        course: 'Klassikale opleiding',
        courseHasBeenReplaced: 'Deze cursus is vervangen door',
        CourseProvider: 'Cursusaanbieder',
        courseResetTitle: 'Gebruiker mag voortgang training resetten',
        courseResetAllow: 'Mag altijd',
        courseResetDeny: 'Mag nooit',
        courseResetDefault: 'Afhankelijk van globale instelling',
        courses: 'Klassikale opleidingen',
        courseSelected: 'opleiding geselecteerd',
        Costs: 'Kosten',
        created: 'Gestart',
        creationDate: 'Aanmaakdatum',
        createNewSkill: 'Maak nieuwe bekwaamheid',
        createNewAssessment: 'Maak nieuwe beoordeling',
        createNewForm: 'Maak nieuw formulier',
        current: 'Actueel',
        currentRegistrations: 'Actuele inschrijvingen',
        CurrentPassword: 'Huidig wachtwoord',
        currentStartDates: 'Actuele startdata',
        currentStatus: 'Huidige status',
        dashboard: 'Dashboard',
        'dashboard.widgets.adminnotification.title': 'Weekplanning',
        'dashboard.widgets.adminnotification.subtitle': 'Bekijk alle waarschuwingen',
        'dashboard.widgets.notification.title': 'Mijn berichten',
        'dashboard.widgets.notification.subtitle': 'Bekijk ongelezen berichten',
        dashboardLinks: 'Dashboard links',
        dashboardTitle: 'Dashboard welkomsttekst',
        dashboardTile: 'Deze tekst wordt bovenaan het dashboard getoond',
        'dashboard.widget.hl.traject': 'Mijn leertraject(en)',
        'dashboard.widget.hl.community': 'Community',
        date: 'Datum',
        dateAndInfo: 'Datum & info',
        DateAndTime: 'Datum & tijd',
        dateAscending: 'Datum oplopend',
        dateDescending: 'Datum aflopend',
        dateIsNotKnown: 'Datum is niet bekend',
        dateOfAssessment: 'Beoordelingsdatum',
        dateOfBirth: 'Geboortedatum',
        dateOfExpiry: 'Verloopdatum',
        dateShownInterest: 'Interesse getoond op',
        dateStatusFrom: 'Datum status vanaf:',
        dateStatusTill: 'Datum status t/m:',
        dateToExpire: 'Gaat binnen {number} maanden verlopen',
        day: 'Dag',
        dayAbout: '{number} dag',
        dayAgo: '{number} dag geleden',
        dayClassroomTraining: 'daagse klassikale opleiding',
        dayParts: 'dagdelen',
        days: 'Dagen',
        daysAbout: '{number} dagen',
        daysAgo: '{number} dagen geleden',
        Deactivate: 'Deactiveer',
        DeactivatePackage: 'Deactiveer pakket',
        definitionAvailable: 'Beschikbaar',
        definitionAllowMultiple: 'Meerdere aanvragen mogelijk',
        definitionReviewByTeacher: 'Beoordelen door docent',
        definitionReviewByManager: 'Beoordelen door manager',
        definitionAcceptByTeacher: 'Accepteren door docent',
        definitionAcceptByManager: 'Accepteren door manager',
        delete: 'Verwijderen',
        deleteAnswer: 'Antwoord verwijderen',
        deleteAllAssignments: 'Verwijder alle toewijzingen',
        deleteAllAssignmentsForThisExaminer: 'Alle toewijzingen voor deze beoordelaar verwijderen',
        deleteApprovalDate: 'Basisdatum opnieuw berekenen',
        deleteAuthSingle: 'U staat op het punt om {number} verzoek te verwijderen',
        deleteAuthPlural: 'U staat op het punt om {number} verzoeken te verwijderen',
        delete_what_: `{what, select,
            role {Rol}
            other {Item}
        } verwijderen`,
        deleteAssessment: 'Beoordeling verwijderen',
        deleteAssessor: 'Beoordelaar verwijderen',
        deleteAssignments: 'Toewijzingen verwijderen',
        deleteCategory: 'Categorie verwijderen',
        deleteExistingAssignments: 'Bestaande toewijzingen verwijderen',
        deleteForm: 'Formulier verwijderen',
        deleted: 'Verwijderd',
        deleteConfirmationForAssignmentForCatalogItem: `Weet u zeker dat u de toewijzing voor de opleiding ''{catalogItemName}'' wilt verwijderen?`,
        deleteConfirmationForAllCatalogAssignments: `Weet u zeker dat je alle toewijzingen van dit catalogusitem wilt
                        verwijderen? Dat betekent dat dit catalogusitem aan geen enkele afdeling, functie,
                        groep of gebruiker meer toegewezen is. Geen enkele gebruiker kan dit
                        catalogusitem dan vinden in de catalogus (exclusief beheerder)`,
        deleteConfirmationForAssignmentForGroups: `Weet u zeker dat u de toewijzing voor de afdeling/klas ''{groupName}'' wilt verwijderen?`,
        deleteConfirmationForAssignmentForUsers: `Weet u zeker dat u de toewijzing voor de gebruiker/groep ''{displayName}'' wilt verwijderen?`,
        deleteConfirmationForAdmin: `Weet u zeker dat u de gebruiker/groep ''{displayName}'' wilt verwijderen uit beheerders?`,
        deleteConfirmationForAssessors: `Weet u zeker dat u de gebruiker/groep ''{displayName}'' wilt verwijderen uit toetsers?`,
        deleteConfirmationForAssessorActivity: `Weet je zeker dat je de activiteit ''{displayName}'' uit de beoordelaar wilt verwijderen?`,
        deleteConfirmationForDepartmentReviewers: `Weet u zeker dat u de gebruiker/groep ''{displayName}'' wilt verwijderen uit afdeling rapporteurs?`,
        deleteConfirmationForPlanners: `Weet u zeker dat u de gebruiker/groep ''{displayName}'' wilt verwijderen uit planners?`,
        deleteConfirmationForResult: `Weet u zeker dat u het resultaat wilt verwijderen voor ''{groupName}''?`,
        deleteBasicApprovalDate: `Weet u zeker dat u de basisdatum van ''{groupName}'' opnieuw wilt berekenen?`,
        deleteGroup: 'Groep verwijderen',
        DeleteNewsItem: `Weet u zeker dat u nieuwsbericht ''{newsItemTitle}'' wilt verwijderen?`,
        deleteConfirmationForLink: `Weet u zeker dat u deze link ''{link}'' wilt verwijderen?`,
        deleteOption: 'Optie verwijderen',
        DeletePackage: 'Verwijder pakket',
        DeletePortalGroup: `Weet u zeker dat u afdeling ''{portalGroupName}'' wilt verwijderen?`,
        deletePortfolio: 'Weet u zeker dat u dit dossieritem wilt verwijderen?',
        deletePortfolioDefinition: 'Weet u zeker dat u deze dossierdefinitie wilt verwijderen?',
        deletedPortfolioDefinition: 'Dossierdefinitie verwijderd',
        deleteQuestion: 'Vraag verwijderen',
        deleteSchedule: 'Sessie verwijderen',
        deleteSection: 'Blok verwijderen',
        deleteSkill: 'Bekwaamheid verwijderen',
        deleteSkillAssignment: 'Toewijzing verwijderen',
        deleteTextField: 'Tekstveld verwijderen',
        deleteMessage: 'Bericht verwijderen',
        deleteUser: 'Gebruiker verwijderen',
        department: 'Afdeling',
        departmentDeleted: 'Afdeling verwijderd',
        departmentFunction: 'Afdeling / functie',
        departmentNoActiveMembers: 'De gekozen afdeling heeft geen (actieve) gebruikers',
        departments: 'Afdelingen',
        'departmentSaveMessage.added': 'Afdeling succesvol opgeslagen',
        'departmentSaveMessage.deleted': 'Afdeling succesvol verwijderd',
        'departmentSaveMessage.conflict': 'Er bestaat al een afdeling met dezelfde naam',
        'departmentSaveMessage.generalError':
            'Er is een probleem opgetreden. Neem contact op met de beheerder',
        departmentsSelectAll: 'Alle afdelingen selecteren',
        departmentReviewers: 'Afdeling rapporteurs',
        dequeue: 'Uit wachtrij',
        description: 'Omschrijving',
        disagree: 'Oneens',
        DirectLink: 'Directe link',
        disable: 'Deactiveren',
        disableAccount: 'Account deactiveren',
        disabledAccount: 'Uitgeschakeld account',
        disableUserAccountDialog: 'Account deactiveren',
        disableUserAccountPerDate: 'Account deactiveren vanaf',
        discard: 'Niet opslaan',
        discardChanges:
            'U heeft wijzigingen gemaakt en deze nog niet opgeslagen. Als u dit scherm nu verlaat, worden de wijzigingen niet opgeslagen.',
        discardChangesShort: 'Niet opgeslagen wijzigingen gaan verloren.',
        disconnectEmployee: 'Medewerker ontkoppelen?',
        disconnectIndividual: 'Individu ontkoppelen',
        displayName: 'Volledige naam',
        displayFromDate: 'Weergave vanaf',
        displayUntilDate: 'Weergave tot',
        displaySettings: 'Weergave instellingen',
        documents: 'Documenten',
        documentDeleted: 'Document verwijderd',
        documentAlwaysVisible: 'Document altijd zichtbaar',
        documentVisibleAfterRegistration: 'Document zichtbaar na inschrijving',
        domain: 'Domein',
        done: 'Klaar',
        DoNotShowThisVideoAnymore: 'Laat deze video niet meer zien',
        DoubleTeacher: 'Dubbele docent',
        DoubleLocation: 'Dubbele locatie',
        down: 'Omlaag',
        download: 'Downloaden',
        downloadCertificate: 'Download certificaat',
        downloadCertificates: 'Download certificaten',
        downloadCertificatesNoAttendance: 'Er zijn nog geen deelnemers aanwezig gemeld',
        downloadAttendanceList: 'Download presentielijst',
        downloadAttendanceListDescription:
            'Kies hieronder welke additionele informatie u aan het rapport wilt toevoegen',
        dragAndDrop: 'Slepen en neerzetten',
        duplicate: 'Dupliceren',
        duplicateAssessor: 'Beoordelaar dupliceren',
        duplicateBlock: 'Blok dupliceren',
        duplicatePlanning: 'Planning dupliceren',
        duplicateQuestion: 'Vraag dupliceren',
        duplicateTextField: 'Tekstveld dupliceren',
        duration: 'Looptijd',
        'duration.start': 'Looptijd start datum',
        'duration.end': 'Looptijd eind datum',
        edit: 'Bewerken',
        editAccreditation: 'Bewerk accreditatie',
        editApprovalDate: 'Basisdatum aanpassen',
        editAssessment: 'Beoordeling bewerken',
        editBlockTitle: 'Bloktitel bewerken',
        editCatalog: 'Bewerk catalogus',
        editCategory: 'Categorie bewerken',
        editCourse: 'Klassikale opleiding bewerken',
        editEvaluationForm: 'Evaluatieformulier bewerken',
        editForm: 'Formulier bewerken',
        editLink: 'Link bewerken',
        editLocation: 'Locatie bewerken',
        EditNewsItem: 'Nieuwsbericht wijzigen',
        editOption: 'Optie bewerken',
        editPlanning: 'Rooster bewerken',
        EditPortalGroup: 'Afdeling bewerken',
        editQuestion: 'Vraag bewerken',
        editSkill: 'Bekwaamheid bewerken',
        editTool: 'Hulpmiddel bewerken',
        elearning: 'E-learning',
        elearningmanagement: 'E-learningbeheer',
        elearningReset: 'E-learning reset',
        enrollDepartmentUsers: 'Medewerkers van de afdeling inschrijven',
        Error404: 'Fout 404',
        email: 'E-mail',
        emailaddress: 'E-mailadres',
        emailWork: 'E-mail werk',
        emailPrivate: 'Privé e-mail',
        employee: 'Medewerker',
        employeeAndRoleAreMandatory: 'Medewerker en rol zijn verplicht',
        employeeNumber: 'Personeelsnummer (gebruikersnaam)',
        employees: 'Medewerkers',
        enableAccount: 'Account activeren',
        end: 'Eind',
        endDate: 'Einddatum',
        endTime: 'Eindtijd',
        enqueue: 'In wachtrij',
        enroll: 'Inschrijven',
        enrollAnotherEmployeeAfterProcessing: 'Na verwerking nog een medewerker inschrijven',
        enrollEmployees: 'Medewerkers inschrijven',
        enrollment: 'Inschrijfbevestiging',
        enrollmentFailed: 'Inschrijving niet mogelijk',
        enrollmentSucceeded: 'Inschrijving geslaagd',
        Enrollments: 'Inschrijvingen',
        enrollmentDeadline: 'Inschrijftermijn',
        errorEnrolling:
            'Er is iets foutgegaan bij het inschrijven, inschrijving is ongedaan gemaakt!',
        enrollmentDeadlineInDays: 'Inschrijftermijn in dagen',
        enrollReservationError: `{ status, select,
            NotExists {Reserveringscode bestaat niet}
            Expired {Reserveringscode is verlopen}
            AlreadyUsed {Reserveringscode is al gebruikt}
            AlreadyEnrolled {Persoon die je probeert in te schrijvern is al ingeschreven}
            NoValidSchedule {Planning is ongeldig of is al geweest}
            other {{status}}
        }`,
        enterReviews: 'Beoordelingen',
        entire: 'Gehele',
        errorDownloadingFile: 'Fout bij downloaden! Melding:',
        errorMessageText: 'Waarschuwingstekst (te gebruiken bij o.a. storing)',
        errorMessageTextHeadline: 'Waarschuwingstekst',
        errorMessageTextSubtitle: 'Opvallende melding bovenaan iedere pagina',
        errorNavigateToElearning: 'De e-learning kan niet gestart worden',
        evaluateTheCoursesThatYouHaveAccomplished: 'Evalueer de cursussen die u heeft gevolgd',
        evaluation: 'Evaluatie',
        evaluationFormName: 'Evaluatie formulier naam',
        evaluations: 'Evaluaties',
        evaluationForm: 'Evaluatieformulier',
        evaluationForms: 'Evaluatieformulieren',
        everyoneVisible: 'Iedereen zichtbaar',
        examUpdated: 'Toetsresultaat bijgewerkt',
        examiner: 'Beoordelaar',
        Examiner_not_found_in_activity: 'Toetser was niet gekoppeld aan deze activiteit',
        Examiner_region_description_address_required: 'Adres is verplicht',
        ExaminerNotFoundInActivity: 'Toetser was niet gekoppeld aan deze activiteit',
        examinerReview: 'Beoordelingsaanvraag',
        examinerReviews: 'Beoordelingsaanvragen',
        examinerReviewDescription: 'U heeft één openstaande beoordelingsaanvraag',
        examinerReviewDescriptionPlural: 'U heeft {number} openstaande beoordelingsaanvragen',
        examiners: 'Beoordelaars',
        examinersCannotBeUsed:
            'Er zijn beoordelaars toegewezen aan activiteiten van deze bekwaamheid. Het is daarom niet mogelijk om de toetser functionaliteit te activeren. Verwijder eerst de beoordelaars.',
        examinersUserCanSelectTimeSlot: 'Gebruiker kan tijdslot kiezen',
        examinersUserCanSelectExaminer: 'Gebruiker kan toetser kiezen',
        examinersUserCanCombineExams: 'Gebruiker kan toetsmomenten combineren',
        examinersStandardDurationTimeSlot: 'Standaard duur toetsblok in minuten',
        examinersStandardDurationExam: 'Standaard duur toets',
        examinersRegionLocation: 'Regio / locatie',
        examinersRegionsLocations: 'Regios / locaties',
        examinersAddRegionLocation: 'Regio / locatie toevoegen',
        examinersDeleteRegionLocation: 'Regio / locatie "{location}" verwijderen?',
        examinersAddAvailability: 'Voeg beschikbaarheid toe',
        examinersEditAvailability: 'Beschikbaarheid bewerken',
        examinersAddSchedule: 'Voeg een schema toe',
        examinersScheduleDetails: 'Activiteiten op {date} - {timeslot}',
        examinersNoReservations: 'Geen reserveringen',
        examinersHasReservations: 'Wel reserveringen',
        examinersDeleteCurrentSchedule: 'Huidig schema verwijderen?',
        examinersPlannedExams: 'Ingeplande toetsmomenten',
        examinersAvailability: 'Beschikbaarheid',
        examinerScheduleMandatoryStartEndDate: 'Vul een begin en eind datum/tijd in',
        examinerScheduleSameStartEndTime: 'Start en eindtijd mogen niet gelijk zijn',
        examinerScheduleStartEndDateNotTheSame: 'Start en einddatum moeten gelijk zijn',
        examinerScheduleEndDateBeforeStartDate: 'Einddatum mag niet voor begindatum liggen',
        examinerScheduleNoRegion: 'Regio is verplicht',
        examinerAvailableSpace:
            'Er is tijd vrij bij een eerder gepland toetsmoment, wil je toetsen combineren?',
        examinerValidResult:
            'Je hebt nog een resultaat wat geldig is tot {date}. Toch een nieuwe afspraak inplannen?',
        examinerCombineTest: 'Toetsmoment combineren?',
        examinerCombineTestYes: 'Ja, combineer toetsen',
        examinerCombineTestNo: 'Nee, nieuw toetsmoment inplannen',
        examinerDurationOfTheExam: 'Duur van het toetsmoment (minuten)',
        examinerNewAppointment: 'Nieuwe afspraak',
        examinerCombineToWhichAppointment: 'Aan welk toetsmoment wil je deze toevoegen?',
        'examiner.appointment.activity': 'Wil je de activiteit ',
        'examiner.appointment.combine': 'combineren met het onderstaande toetsmoment?',
        'examiner.appointment.examiner': 'Toetser: ',
        'examiner.appointment.dateTime': 'Datum en tijd: ',
        'examiner.appointment.regionLocation': 'Regio / locatie: ',
        examinerNoAppointmentsTitle: 'Ingeplande toetsmomenten',
        examinerNoAppointmentsDescription: 'Er zijn geen ingeplande toetsmomenten gevonden',
        examinerDeleteAppointment: 'Toetsmoment verwijderen?',
        exceededRegistrationPeriod: 'Het is niet meer mogelijk je in te schrijven.',
        exceededUnRegistrationPeriod:
            'Je wilt een deelnemer uitschrijven of overschrijven maar de uitschrijf termijn is bereikt. "Je kan een deelnemer niet meer uitschrijven. Neem contact op met de opleiders."',
        exceededUnRegistrationPeriodSelf:
            'Je wilt jezelf uitschrijven of overschrijven maar de uitschrijf termijn is bereikt. "Je kan niet meer jezelf uitschrijven. Neem contact op met de opleiders."',
        exemption: 'Vrijstelling',
        exemptionEdit: 'Vrijstelling geven',
        exemptionsHaveBeenGranted:
            'Vrijstellingen zijn verleend. Let op, het kan enkele minuten duren voordat dit zichtbaar is in het bekwaamheidspaspoort',
        exemptionUntil_date_: 'Vrijstelling tot {date}',
        exemptionValidTill: 'Vrijstelling tot',
        expertise: 'Dossier-item',
        expertiseDeleted: 'Expertise verwijderd',
        expertiseTodoTasks: 'U heeft de volgende taken:',
        Expertises_accept: 'Dossieritems accepteren',
        Expertises_authorize: 'Dossieritems autoriseren',
        Expertises_review: 'Dossieritems beoordelen',
        Expertises_updated_status: 'Dossiertaken bijgewerkt',
        expirationDate: 'Verloopdatum',
        expirationPeriod: 'Verloopperiode',
        existingAssignments: 'Bestaande toewijzingen',
        ExpirationPeriod: 'Verloopperiode',
        expires: 'Verloopt',
        expiresOn: 'Verloopt op',
        expiresOn_date_: 'Verloopt op {date}',
        expired: 'Verlopen',
        expiredOn_date_: 'Verlopen op {date}',
        explanationOrquestion: 'Toelichting/vraag',
        export: 'Exporteren',
        exportOptions: 'Export opties',
        exportAssignments: 'Toewijzingen exporteren',
        exportAssignmentsToOtherUsers: 'Toewijzingen exporteren naar andere gebruikers',
        exportPDF: 'PDF exporteren',
        extendExpirationDate: 'Verloopdatum verlengen',
        ExternalRegistrationNumber: '{name}-nummer',
        ExtraOptions: 'Extra opties',
        ExternalCost: 'Externe kosten',
        failed: 'Gezakt',
        Failed: 'Gezakt',
        false: 'nee',
        favoriteTrainings: 'Favorieten',
        FavoriteWidgetInfo: 'Bekijk jouw favoriete catalogusitems',
        FailedAlreadyRegistered: 'Al ingeschreven',
        FailedAlreadyRegisteredOnThisPlanning: 'De persoon is al ingeschreven op dit rooster.',
        FailedCapacityReachedPlanningIsFull:
            'De persoon kan niet worden ingeschreven op dit rooster, want dit rooster is al vol.',
        FailedConflictingEnrollmentOnOtherCourse: 'Al ingeschreven op een andere cursus.',
        FailedMaxNumRegistrationsReachedOnCourse:
            'De persoon kan niet worden ingeschreven op dit rooster, want het maximaal aantal inschrijvingen van de cursus is bereikt.',
        filterByDate: 'Filter op datum',
        filterOnCatalogName: 'Filter op catalogusnaam',
        filterOnCategory: 'Filter categorie',
        filterOnCategories: 'Filter op categorieën',
        filterOnCategoryOrSkill: 'Filter op categorie of bekwaamheid',
        filterOnCluster: 'Filter cluster',
        filterOnDepartmentName: 'Filter op afdelingsnaam',
        filterOnFunction: 'Filter op functie',
        filterOnName: 'Filter op naam',
        filterOnDescription: 'Filter op omschrijving',
        filterOnSkill: 'Filter bekwaamheid',
        filter: 'Filter',
        filters: 'Filters',
        findUnderlyingDepartment: 'Zoek onderliggende afdeling',
        finished: 'afgerond',
        firstName: 'Voornaam',
        fivePointLikertScale: '5-Punts Likertschaal',
        Float: 'Richting',
        font: 'Lettertype',
        forbidden: 'Geen toegang',
        forbiddenDepartment: 'U bent niet bevoegd om deze afdeling in te zien.',
        forms: 'Formulieren',
        formIncomplete: 'Formulier is niet volledig ingevuld',
        formName: 'Naam formulier',
        formDescription: 'Beschrijving formulier',
        formMustContainAtLeastOneSection: 'Formulier moet minstens 1 blok bevatten',
        formSectionsMustContainAtLeastOneQuestion: 'Blokken moeten minstens 1 vraag bevatten',
        from: 'van',
        fromMax: 'van max.',
        fromNow: 'Vanaf nu',
        fromTime: 'Vanaf',
        Full: 'Uitvullen',
        fullProfile: 'Volledig profiel',
        function: 'Functie',
        functions: 'Functies',
        functionsManagement: 'Functies beheren',
        fromLeft: 'Vanaf links',
        fromTop: 'Vanaf bovenkant',
        general: 'Algemeen',
        getPwa: 'Installeer app',
        getContactIfQuestion: 'Neem bij vragen contact op met de beheerder',
        giveManagersRightsToSubDepartments: 'Geef managers ook rechten op onderliggende afdelingen',
        gotoCatalogitem: 'Naar catalogusitem',
        gotoChildLevel: 'Ga naar onderliggend niveau',
        gotoReport: 'Naar rapport',
        group: 'Groep',
        groupName: 'Groepsnaam',
        groups: 'Groepen',
        groupEnrollmentTitle:
            'Kies op welk rooster je de medewerkers van de geselecteerde afdeling in wilt schrijven',
        groupEnrollmentEmptyPlanning: 'Nog geen deelnemers ingeschreven',
        HasTaggedRegistration: 'HasTaggedRegistration',
        Headings: 'Kopteksten',
        headingColor: 'Titelkleur',
        Help: 'Ondersteuning',
        hideNoActionNotAssigned: 'Verberg niets gedaan / niet toegewezen',
        hideNotAssigned: 'Verberg niet toegewezen / niet van toepassing',
        hlapps: 'Werk- & leervormen',
        'hlapps.back': 'Terug naar Werk- & Leervormen',
        'hlapps.back.mobile': 'Werk- & Leervormen',
        'hlapps.name': `{id, select,
            1 {Community}
            2 {Leer- & Ontwikkelscan}
            12 {Box}
            13 {Kracht- & Ontwikkelpunten}
            17 {Mijn Leertraject}
            other {Werk- & Leervorm}
        }`,
        'hlapps.subtitle': `{id, select,
            1 {Onmoet elkaar online}
            2 {Maak ontwikkeling zichtbaar}
            12 {Raadpleeg broninformatie}
            13 {Ga voor persoonlijke groei}
            17 {Bekijk jouw opleidingsoverzicht}
            other {''{id}''}
        }`,
        'hlapps.rib': 'Dagstart & Reflectie',
        'hlapps.rib.sub': 'Deel praktijksituaties',
        'hlapps.obs': 'Online Beeldsessie',
        'hlapps.obs.sub': 'Ga in gesprek',
        'hlapps.userman': 'Gebruikersbeheer',
        'hlapps.userman.sub': 'Wie doen er mee?',
        'hlapps.productman': 'Productbeheer',
        'hlapps.productman.sub': 'Wat gaan we doen?',
        helpAndFeedback: 'Hulp en feedback',
        hereYouFindAnOverviewOfAllAvailableElearningTrainingsExpertiseItemsAndClassActivities:
            'Hier vindt u een overzicht van alle beschikbare e-learning opleidingen, expertise items en klassikale activiteiten',
        hereYouFindAnOverviewOfAllAvailableDepartments:
            'Hier vindt u een overzicht van alle beschikbare afdelingen',
        hereYouFindAnOverviewOfAllAvailableFunctions: 'Alle beschikbare functies',
        hereYouFindAnOverviewOfAllAvailableTrainings:
            'Hier vindt u een overzicht van de beschikbare formele opleidingen, e-learning en klassikaal.',
        hereYouFindAnOverviewOfTheDepartmentsWhereYouAreAManager:
            'Hier vindt u een overzicht van de afdelingen waar u manager van bent',
        hereYouFindAnOverviewOfTheDepartmentsWhereYouWork:
            'Hier vindt u een overzicht van de afdelingen waar u werkt',
        hereBelowYouCanUpdateTheAttendanceOfTheParticipants:
            'Hieronder kunt u de presentie van de deelnemers bijwerken.',
        hour: 'Uur',
        hourAbout: '{number} uur',
        hourAgo: '{number} uur geleden',
        hours: 'Uren',
        hoursAbout: '{number} uur',
        hoursAgo: '{number} uren geleden',
        hundredPercentVisibleInProportion: '100% zichtbaar, in verhouding',
        hundredVisibleStretched: '100% zichtbaar, opgerekt',
        ifTheMaximumNumberOfParticipantsIsReachedRegistrationWillClose:
            'Indien maximum aantal deelnemers is bereikt, sluit inschrijving',
        ignore: 'Negeren',
        ignoreChanges: 'Wijzigingen negeren?',
        insufficientRightsText: 'Je hebt niet genoeg rechten voor deze pagina.',
        insufficientRightsTitle: 'Niet genoeg rechten',
        Image: 'Afbeelding',
        'importmanagement.classroomResults': 'Klassikale resultaten',
        'importmanagement.confirm.beware': 'LET OP!',
        'importmanagement.confirm.areYouSure':
            'Weet je zeker dat je het zojuist gekozen bestand wilt importeren?',
        'importmanagement.confirm.overwrite':
            'Reeds bestaande data (met bijv. een andere geldigheid) kan mogelijk overgeschreven worden door deze import.',
        'importmanagement.confirm.lost':
            'Dat betekent dat eventuele voortgang en resultaten verloren kunnen gaan.',
        'importmanagement.confirm.noReturn': 'Dit kan NIET ongedaan gemaakt worden!',
        'importmanagement.confirm.startImport': 'Ik weet het zeker, start import',
        'importmanagement.details': 'Details',
        'importmanagement.disclaimer':
            'Ik neem volledige verantwoordelijkheid voor de data die ik importeer en de daaruit voortvloeiende gevolgen',
        'importmanagement.dossierItems': 'Dossieritems',
        'importmanagement.elearningResults': 'E-learning resultaten',
        'importmanagement.error': 'Fout',
        'importmanagement.exemptions': 'Vrijstellingen (bekwaamheden)',
        'importmanagement.fileUpload': 'Upload importbestand (.csv)',
        'importmanagement.heading.date': 'Datum',
        'importmanagement.heading.result': 'Resultaat',
        'importmanagement.heading.type': 'Import type',
        'importmanagement.newImport': 'Nieuwe import',
        'importmanagement.notImported': 'NIET geïmporteerd',
        'importmanagement.whatDomain': 'In welk domein wil je importeren?',
        'importmanagement.whatToImport': 'Wat wil je importeren?',
        'importmanagement.errorCode': `{error, select,
            ERR0 {bestandsstructuur niet juist}
            ERR1 {niet bestaande medewerkers geconstateerd}
            ERR2 {niet bestaande bekwaamheden geconstateerd}
            ERR3 {niet gekoppelde bekwaamheden geconstateerd}
            ERR4 {academyInfo niet gevonden}
            ERR6 {niet bestaande catalogusitems geconstateerd}
            ERR7 {niet bestaande dossier definities geconstateerd}
            other {{error}}
        }`,
        'importmanagement.status': `{statusId, select,
            0 {Importbestand geüpload}
            1 {Bestand wordt gecontroleerd...}
            2 {Gebruikers bestaan}
            3 {Bekwaamheden bestaan}
            4 {Bekwaamheden toegewezen aan gebruikers}
            5 {Import mislukt}
            6 {Import bezig...}
            7 {Import gelukt}
            8 {Geïmporteerd met fouten}
            other {{statusId}}
        }`,
        'importmanagement.type': `{typeId, select,
            0 {E-learning resultaten}
            1 {Vrijstellingen (bekwaamheden)}
            2 {Klassikaal}
            3 {Klassikale resultaten}
            4 {Dossieritems}
            other {{typeId}}
        }`,
        import: 'Importeren',
        imported: 'import',
        importAssignments: 'Toewijzingen importeren',
        importAssignmentsFromOtherUsers: 'Toewijzingen importeren van een andere gebruiker',
        Inactive: 'Inactief',
        inapplicable: 'Deze bekwaamheid is niet van toepassing',
        inapplicable2: 'Niet van toepassing',
        includeChildDepartments: 'Incl onderliggend',
        includeSubdepartments: 'Inclusief subafdelingen',
        Indent: 'Inspringing vergroten',
        individual: 'Individu',
        individual2: 'Individueel',
        individuals: 'Individuen',
        information: 'Information',
        informationCancelPlanning:
            'Weet u zeker dat u deze planning wilt annuleren? U zult de deelnemers van deze planning moeten uitschrijven of overplaatsen naar een andere planning.',
        informationForYou: 'Meer informatie voor jou',
        inheritAssignments: 'Toewijzingen overnemen',
        inheritAssignmentsFromOtherUsers: 'Toewijzingen overnemen van andere gebruikers',
        initials: 'Initialen',
        inShort: 'In het kort',
        institution: 'Instelling',
        Intake: 'Intake',
        InternalCost: 'Interne kosten',
        introductionVideo: 'Introductievideo SDB Leerplatform',
        invalidDate: 'Ongeldige datum',
        invalidUrl: 'Ongeldige URL',
        isExemptFromPayment: 'Hoeft niet af te rekenen',
        isGoingToExpire: 'Gaat verlopen',
        Italic: 'Cursief',
        Justify: 'Uitvullen',
        keepOpen: 'Open houden',
        lastLoginDate: 'Laatste inlogdatum',
        lastName: 'Achternaam',
        lastNamePrefix: 'Voorvoegsel',
        lastResult: 'Laatste resultaat',
        lastReviewDate: 'Laatste beoordelingsdatum',
        lastSuccessfulMark: 'Laatste succesvolle beoordeling',
        learningplan: 'e-Learning',
        Learningplan: 'e-Learning',
        LearningPoints: 'Leerpunten',
        learningplanTypes: 'e-Learning',
        learningProgram: 'Leerprogramma',
        Left: 'Links',
        lesson: 'les',
        lessons: 'lessen',
        likert: 'Likert-schaal',
        likertInfo:
            'Gebruikers kunnen hun mening op bijv. een stelling geven via een Likert-schaal. Deze bestaat uit 5 opties, van "Helemaal oneens" tot "Helemaal eens". Voeg eenvoudig meerdere vragen toe om een groep te creeren.',
        Link: 'Hyperlink',
        linkMustBeginWith: 'Link moet beginnen met http:// of https://',
        listOfInterestedUsers: 'Geïnteresseerde gebruikers',
        listOfInterestedUsersInCourse: 'Geïnteresseerde gebruikers in cursus',
        location: 'Locatie',
        locationDeleted: 'Locatie verwijderd',
        locations: 'Locaties',
        login: 'Inloggen',
        loginSetting: 'Heet de gebruiker welkom op het loginscherm',
        loginDescription: 'Deze uitlegtekst voor de inlog is per portaal instelbaar',
        mailTemplates: 'E-mailsjablonen',
        MailTemplateType: `{type, select,
            ClassroomEnqueue {Inschrijfbevestiging (Wachtrij)}
            ClassroomDequeue {Uitschrijfbevestiging (Wachtrij)}
            ClassroomEnroll {Inschrijfbevestiging}
            ClassroomUnenroll {Uitschrijfbevestiging}
            ClassroomTransfer {Overschrijfbevestiging}
            ClassroomCancel {Annuleringsbevestiging}
            ClassroomReminder {Herinneringsmail}
            other {{type}}
        }`,
        makeAppointment: 'Klik om een afspraak te maken',
        makeAChoice: 'Maak een keuze',
        manageUsers: 'Gebruikers beheren',
        managerGroups: 'afdelingen',
        managersAreAllowedToAssignCatalogItems: 'Managers mogen catalogusitems toewijzen',
        managersMayNotAssign: 'Managers mogen niet toewijzen',
        manageSkills: 'Bekwaamheden beheren',
        mandatory: 'Verplicht',
        mandatoryFieldsMustBeCompleted: 'Verplichte velden moeten ingevuld worden',
        mandatoryFrom: 'Verplicht vanaf',
        mandatoryNotMandatory: 'Verplicht / niet verplicht',
        mandatoryTrainings: 'Verplichte opleidingen',
        mandatoryTrainingsShort: 'Verpl. opleidingen',
        MandatoryTrainingsSubtitle: 'Begin er op tijd aan!',
        manual: 'Handleiding',
        markAsRead: 'Mark as unread',
        markAsUnread: 'Markeren als ongelezen',
        maxAmount: 'Maximum aantal',
        maxAttachmentFileSize: 'De totale grootte van de bestanden mag niet groter zijn dan 50 MB',
        maxCapacity: 'Maximum aantal deelnemers',
        maxCapacityMustBeHigherThanMinCapacity:
            'Maximum aantal is verplicht en moet hoger zijn dan minimum aantal',
        maximum: 'maximaal',
        maxNumberOfEnrollmentsPerParticipant: 'Max. inschrijvingen per deelnemer',
        MaxNumberOfParticipantsReached: 'Max. aantal deelnemers bereikt',
        Message: 'Bericht',
        'message.header': `{ created, select,
            undefined {}
            other {{created} - }
        }{ sender, select,
            undefined {Systeembericht}
            other {{sender}}
        }{ autoGenerated, select,
            true { (automatisch verzonden)}
            other {}
        }`,
        'message.to': `Aan: {recipients}`,
        'message.subjectElearningNotificationMessage': 'afronding e-learning bericht',
        'message.achievementMessagePassed': `Gebruiker ''{identityDisplayName}'' is geslaagd voor de e-learningmodule ''{catalogItemName}''`,
        'message.achievementMessageFinished': `Gebruiker ''{identityDisplayName}'' heeft de e-learningmodule ''{catalogItemName}'' succesvol afgerond`,
        'message.assessorAppointmentMade': `Er is een afspraak gemaakt voor gebruiker ''{studentDisplayName}'' op ''{dateTime}'' om de volgende activiteit af te laten toetsen: ''{activityDescription}''`,
        'message.assessorAppointmentRemoved': `De afspraak van gebruiker ''{studentDisplayName}'' op ''{dateTime}'' voor activiteit ''{activityDescription}'' is verwijderd`,
        'message.assessorAppointmentMadeSubject': `Toetsafspraak gemaakt`,
        'message.assessorAppointmentRemovedSubject': `Toetsfspraak verwijderd`,
        'message.catalogAuthRequestPermissionSubjectLearningplan':
            '{studentDisplayName} heeft een aanvraag voor e-learning {displayName} gedaan.',
        'message.catalogAuthRequestPermissionSubjectClassroom':
            '{studentDisplayName} heeft een aanvraag voor de klassikale opleiding {displayName} die start op {planningStart} gedaan.',
        'message.catalogAuthRequestPermissionBody': `Ga op het dashboard naar ''Autorisatie aanvragen'' om de aanvraag af te handelen.`,
        'message.catalogAuthRequestBodyLearningPlanAccepted': `Je aanvraag om e-learning ''{displayName}'' te volgen is geaccepteerd`,
        'message.catalogAuthRequestBodyLearningPlanAcceptedRemark': `Je aanvraag om e-learning ''{displayName}'' te volgen is geaccepteerd. Je manager heeft de volgende opmerking toevoegd: {managerRemark}`,
        'message.catalogAuthRequestBodyLearningPlanRejected': `Je aanvraag om e-learning ''{displayName}'' te volgen is geweigerd`,
        'message.catalogAuthRequestBodyLearningPlanRejectedRemark': `Je aanvraag om e-learning ''{displayName}'' te volgen is geweigerd. Je manager heeft de volgende opmerking toevoegd: {managerRemark}`,
        'message.catalogAuthRequestBodyLearningPlanDeleted': `Je aanvraag om e-learning ''{displayName}'' te volgen is verwijderd`,
        'message.catalogAuthRequestBodyLearningPlanDeletedRemark': `Je aanvraag om e-learning ''{displayName}'' te volgen is verwijderd. Je manager heeft de volgende opmerking toevoegd: {managerRemark}`,
        'message.catalogAuthRequestBodyLearningPlanUnknown': `Je aanvraag om e-learning ''{displayName}'' te volgen is status onbekend`,
        'message.catalogAuthRequestBodyLearningPlanUnknownRemark': `Je aanvraag om e-learning ''{displayName}'' te volgen is status onbekend. Je manager heeft de volgende opmerking toevoegd: {managerRemark}`,
        'message.catalogAuthRequestPermissionRevoked': `De aanvrager heeft de autorisatie voor ''{displayName}'' ingetrokken.`,
        'message.catalogAuthRequestBodyClassroomAccepted': `Je aanvraag om klassikale training ''{displayName}'' die start op {planningStart} te volgen is geaccepteerd`,
        'message.catalogAuthRequestBodyClassroomAcceptedRemark': `Je aanvraag om klassikale training ''{displayName}'' die start op {planningStart} te volgen is geaccepteerd. Je manager heeft de volgende opmerking toevoegd: {managerRemark}`,
        'message.catalogAuthRequestBodyClassroomRejected': `Je aanvraag om klassikale training ''{displayName}'' die start op {planningStart} te volgen is geweigerd`,
        'message.catalogAuthRequestBodyClassroomRejectedRemark': `Je aanvraag om klassikale training ''{displayName}'' die start op {planningStart} te volgen is geweigerd. Je manager heeft de volgende opmerking toevoegd: {managerRemark}`,
        'message.catalogAuthRequestBodyClassroomDeleted': `Je aanvraag om klassikale training ''{displayName}'' die start op {planningStart} te volgen is verwijderd`,
        'message.catalogAuthRequestBodyClassroomDeletedRemark': `Je aanvraag om klassikale training ''{displayName}'' die start op {planningStart} te volgen is verwijderd. Je manager heeft de volgende opmerking toevoegd: {managerRemark}`,
        'message.catalogAuthRequestBodyClassroomUnknown': `Je aanvraag om klassikale training ''{displayName}'' die start op {planningStart} te volgen is status onbekend`,
        'message.catalogAuthRequestBodyClassroomUnknownRemark': `Je aanvraag om klassikale training ''{displayName}'' die start op {planningStart} te volgen is status onbekend. Je manager heeft de volgende opmerking toevoegd: {managerRemark}`,
        'message.catalogAuthRequestPermissionAccepted': `Aanvraag voor ''{displayName}'' is geaccepteerd.`,
        'message.catalogAuthRequestPermissionRejected': `Aanvraag voor ''{displayName}'' is geweigerd.`,
        'message.catalogAuthRequestPermissionDeleted': `Aanvraag voor ''{displayName}'' is verwijderd.`,
        'message.catalogAuthRequestPermissionUnknown': `Aanvraag voor ''{displayName}'' is onbekend.`,
        'message.catalogAuthRequestBodyRevokedByUser': `Gebruiker ''{studentDisplayName}'' heeft de autorisatieaanvraag voor ''{displayName}'' ingetrokken.`,
        messageBodyIsRequired: 'Bericht is verplicht',
        messageSent: 'Bericht verzonden',
        'message.new': 'Bericht aan gebruiker',
        'message.newClass': 'Bericht aan cursusgroep',
        'message.newDepartment': 'Bericht aan afdeling',
        'message.filterByMessage': 'Filteren op bericht',
        'message.searchSubject': 'Zoek op onderwerp',
        'message.filterBySender': 'Filteren op afzender',
        'message.originalMessageInfo': 'Op {date} heeft {sender} het volgende geschreven:',
        'message.Received': 'Ontvangen',
        'message.Sent': 'Verzonden',
        'message.Settings': 'Instellingen',
        'message.settings.admin.title': 'Berichtenbox instellingen',
        'message.settings.admin.description':
            'Stel hieronder de standaard berichtenbox instellingen in voor alle medewerkers. De ingestelde standaard wordt voor iedereen gehanteerd, behalve voor medewerkers die hun persoonlijke instellingen hebben aangepast in hun eigen berichtenbox.',
        'message.settings.title': 'Mijn berichtenbox instellingen',
        'message.settings.emailSummary': 'E-mail samenvatting',
        'message.settings.emailSummary.subtitle':
            'Ontvang een overzicht van het aantal ongelezen berichten in de berichtenbox',
        'message.settings.emailNotification': 'E-mail notificatie',
        'message.settings.emailNotification.newMessages':
            'Nieuwe berichten ook per e-mail ontvangen',
        'message.settings.emailNotification.Expiringskill': 'Verlopende bekwaamheid',
        'message.settings.emailNotification.skillExpiration':
            'Notificatie bij verlopende bekwaamheid ook per e-mail ontvangen',
        'message.settings.frequency': 'Frequentie e-mail samenvatting',
        'message.settings.noEmail': 'Nooit / geen samenvatting',
        'message.settings.none': 'Nooit / geen samenvatting',
        'message.settings.daily': 'Dagelijkse samenvatting',
        'message.settings.weekly': 'Wekelijkse samenvatting',
        'message.settings.monthly': 'Maandelijkse samenvatting',
        'message.skills.expirationNotification.subject': `Bekwaamheden update`,
        'message.skills.expirationNotification.body': `Beste deelnemer,\n
            { expired, select,
                0 {}
                other {Op dit moment {expired, select, 1 {is er 1 bekwaamheid} other {zijn er {expired} bekwaamheden}} verlopen.}
            }
            { expiring, select,
                0 {}
                other {In de periode tot {until} {expiring, select, 1 {gaat er nog 1 bekwaamheid} other {gaan er nog {expiring} bekwaamheden}} verlopen.}
            }
            { new, select,
                0 {}
                other {Sinds de laatste update {new, select, 1 {is er 1 bekwaamheid} other {zijn er {new} bekwaamheden}} aan jou toegewezen.}
            }

            Je kunt jouw bekwaamheden bekijken in je portfolio.
        `,
        mayBeRedoneAgain: 'Mag opnieuw',
        media: 'Media',
        min0max100: 'Min. 0 en Max. 100',
        minAmount: 'Minimum aantal',
        minCapacity: 'Minimum aantal deelnemers',
        minCapacityMustBeLowerThanMaxCapacity:
            'Minimum aantal is verplicht en moet lager zijn dan maximum aantal',
        minMaxCapacity: 'Minimum/maximum capaciteit',
        minuteAbout: '{number} minuut',
        minuteAgo: '{number} minuut geleden',
        minutes: 'Minuten',
        minutesAbout: '{number} minuten',
        minutesAgo: '{number} minuten geleden',
        mobilePhone: 'Mobiele telefoon',
        modifyEnrollment: 'Wijzig inschrijving',
        'modules.accessmanagement.activatedRights': 'Geactiveerde rechten',
        'modules.accessmanagement.applicationPart': 'Applicatie onderdeel',
        'modules.accessManagement.descriptions': `{key, select,
            roles_and_rights {Rollen en rechten}
            roles_and_rights_manage_roles {Beheer rollen}
            roles_and_rights_manage_assignments {Beheer toewijzingen}
            classroom {Classroom}
            classroom_manage_courses {Beheer opleidingen}
            classroom_manage_schedules {Beheer planningen}
            classroom_manage_enrollments {Beheer inschrijvingen}
            classroom_manage_presence {Beheer presentie}
            other {{key}}
        }`,
        'modules.accessmanagement.errors': `{error, select,
            duplicate_name {Er is al een rol met deze naam}
            no_name_provided {Er is geen rolnaam opgegeven}
            other {Er is een fout opgetreden}
        }`,
        'modules.accessManagement.rights_count_': `Rechten: {count}`,
        'modules.accessManagement.roleName': 'Rolnaam',
        'modules.admin.classroom.presence.NumberOfResults': 'Totaal aantal resultaten:',
        'modules.adminMenu.labels': `{label, select,
            settings {Instellingen}
            catalog {Catalogus}
            compositionmanagement {E-learning beheer}
            scormmanagement {Scorm management}
            elearningmanagement {E-learningbeheer}
            evaluations {Evaluaties}
            definities {Dossieritems}
            classroom {Klassikaal}
            skills {Bekwaamheden}
            certificates {Certificaten}
            usermanagement {Gebruikersbeheer}
            examinermanagement {Toetserbeheer}
            importmanagement {Importbeheer}
            maillogoverview {E-mail logging}
            other {{label}}
        }`,
        'modules.catalog.course.authorization.noManagersNotPossibleToAuthorize':
            'Er is geen manager gevonden om het autorisatieverzoek bij in te dienen.',
        'modules.catalog.statusType': `{statusType, select,
            all {Alles}
            completed {Voldaan}
            notcompleted {Niet voldaan}
            started {Gestart / Ingeschreven}
            other {{statusType}}
        }`,
        'modules.classroom.exportPresence': 'Download resultatenrapportage',
        'modules.classroom.participants.resultValue': `{value, select,
            passed {Voldaan}
            failed {Niet voldaan}
            other {Niet ingevuld}
        }`,
        'modules.classroom.presenceListFileNamePart': 'presentielijst',
        'modules.classroom.presenceValue': `{value, select,
            present {Aanwezig}
            absent {Afwezig}
            other {Niet ingevuld}
        }`,
        'modules.classroom.queueEnabledAndAuthorizationRequiredWarning': `De opties 'Autorisatie vereist door manager' en 'Wachtrij' kunnen niet gelijktijdig aan staan.`,
        'modules.classroom.queueEnabledAndPlanningIntegrationWarning': `De opties 'Opleidingsuren verwerken in SDB Planning' en 'Wachtrij' kunnen niet gelijktijdig aan staan.`,
        'modules.classroom.registrations': `Ingeschreven {enrollmentCount} — Max. {maxCapacity}`,
        'modules.classroom.resultReportFileNamePart': 'resultaatrapportage',
        'modules.classroom.upcomingplanninglist.details': `details`,
        'modules.classroom.upcomingplanninglist.unsubscribe': `uitschrijven`,
        'modules.classroomNotifications.teachersReceiveUnenrollNotifications':
            'Notificaties voor docenten bij uitschrijvingen van hun klassikale opleidingen',
        'modules.classroomNotifications.unenrollNotificationsList':
            'Onderstaande gebruikers ontvangen notificaties bij iedere uitschrijving van een klassikale opleiding',
        'modules.classroomNotifications.teacherNotifications': 'Notificaties voor docenten',
        'modules.elearningmanagement.tabs': `{tab, select,
            lti {LTI}
            drafts {Arrangementen}
            scorm {SCORM}
            other {{tab}}
        }`,
        'modules.maillog.EmailLogging': 'E-mail logging',
        'modules.maillog.EmailReceiver': 'E-mail ontvanger',
        'modules.maillog.events': `{event, select,
            Deferred {uitgesteld}
            Bounce {aflevering mislukt}
            Delivered {afgeleverd}
            Dropped {niet verwerkt}
            Blocked {aflevering geblokkeerd}
            other {{event}}
        }`,
        'modules.mailtemplates.deleteMailTemplate.title': 'Verwijder mailtemplate',
        'modules.mailtemplates.deleteMailTemplate.message': `Weet je zeker dat je de mailtemplate wilt verwijderen?
            Na verwijdering wordt de standaard mailtemplate weer gebruikt.`,
        'modules.mailtemplates.labels': `{label, select,
            cursist {[cursist]}
            opleiding {[opleiding]}
            planning {[planning]}
            start {[start]}
            eind {[eind]}
            locatie {[locatie]}
            docent {[docent]}
            roosteritems {[roosteritems]}
            roosteritems2 {{roosteritems}}
            other {{label}}
        }`,
        'modules.mailtemplates.labels.roosteritems.usage': 'Deze wordt als volgt gebruikt',
        'modules.mailtemplates.labels.roosteritems.content':
            'roosteritems "Van [start] tot [eind] in [locatie] door [docent]."',
        'modules.mainMenu.labels': `{label, select,
            start {Start}
            catalog {Catalogus}
            departments {Afdelingen}
            skills {Bekwaamheden}
            functions {Functies}
            hlapps {Werk- & leervormen}
            myMinerva {Portfolio}
            search {Zoeken}
            administration {Beheer}
            other {{label}}
        }`,
        'modules.messages.markReadAllButton': `markeer alles als gelezen`,
        'modules.packages.areYouSureYouWantToResetThisPackage':
            'Weet u zeker dat u dit pakket wilt resetten?',
        'modules.packages.deletePackageMessage':
            'Er zijn op dit moment gebruikers gekoppeld aan deze versie van het pakket. Als u deze versie verwijdert, worden deze koppelingen verwijderd.',
        'modules.packages.sendMailToUsers': 'Verstuur een e-mail naar de gebruikers',
        'modules.packages.resetPackage': 'Reset pakket',
        'modules.packages.resetPackageMessage':
            'Er zijn op dit moment gebruikers gekoppeld aan deze versie van het pakket. Als u deze versie reset, is de voortgang bij deze koppelingen verloren.',
        'modules.skills.achievedOn': 'Afgerond op {date}',
        'modules.skills.activity.status': `{status, select,
            exemption {vrijstelling}
            red {verlopen / nog te doen}
            yellow {gaat verlopen}
            green {behaald}
            other {{status}}
        }`,
        'modules.skills.activityGroup.subtitle': `{groupType, select,
            compulsory {Verplicht}
            optional {Optioneel}
            recommended {Geadviseerd}
            other {{groupType}}
        } - {activities} {activities, select,
            1 {activiteit}
            other {activiteiten}
        } {groupType, select,
            optional {waarvan {required} verplicht}
            other {}
        } {notApplicable, select,
            0 {}
            other {({notApplicable} uitgeschakeld)}
        }`,
        'modules.skills.activityGroupType': `{type, select,
            standard {Standaard}
            basicWithRefresher {Basis/Vervolg}
            other {{type}}
        }`,
        'modules.skills.activityLockedDialog.title': 'Nog niet toegankelijk',
        'modules.skills.activityLockedDialog.message': 'Rond eerst de voorgaande activiteiten af.',
        'modules.skills.activityType': `{type, select,
            protocol {Protocol}
            manual {Handleiding}
            classical {Klassikale theorieles}
            elearning {E-learning}
            skillslabtraining {Skillslabtraining}
            trainingonthejob {Training on the job}
            writtentest {Schriftelijke toets}
            skillslabtest {Skillslab toets}
            practical {Praktijktoets}
            peerassessment {Peer assessment}
            selfassessment {Zelfbeoordeling}
            other {{type}}
        }`,
        'modules.skills.activityCurrentStatus': `{ status, select,
            elearning_created { Gestart }
            classical_registered { Ingeschreven }
            classical_unregistered { Uitgeschreven }
            other {}
        }`,
        'modules.skills.activityExpirationStatus': `{ status, select,
            achieved {Behaald}
            expires {Verloopt op {date}}
            never_expires {Verloopt niet}
            exemption {Vrijstelling tot {date}}
            effective_from {Verplicht vanaf {date}}
            expired {Verlopen op {date}}
            other {}
        }`,
        'modules.skills.activityValidity.title': 'Geldigheidsduur',
        'modules.skills.activityValidity.value': `{value, select,
            default {Standaard}
            custom {Aangepast}
            oneoff {Eenmalig}
            other {{value}}
        }`,
        'modules.skills.activityValidity.months': `{ months, select,
            1 {Maand}
            other {Maanden}
        }`,

        'modules.skills.assignmentDialog.title': 'Selecteer afdelingen en functies',
        'modules.skills.assignmentDialog.noSelectionInfo':
            'Selecteer ten minste één afdeling en functie',
        'modules.skills.assignmentDialog.departments': `Afdelingen`,
        'modules.skills.assignmentDialog.roles': `Functies`,
        'modules.skills.assignmentDialog.selected': `geselecteerd`,
        'modules.skills.assignments.headline': `Aantal toegewezen: {assigned}, waarvan {notApplicable} niet van toepassing`,
        'modules.skills.basicWithRefresher': 'Basis - Vervolg',
        'modules.skills.basicWithRefresher.basicValidity': 'Vervolg van toepassing na...',
        'modules.skills.basicWithRefresherHelpMonths':
            'Bepaal na hoeveel maanden het vervolg van toepassing is na het initieel behalen van de basis.',
        'modules.skills.basicWithRefresher.basicOneOff': 'Basis eenmalig',
        'modules.skills.basicWithRefresher.basicOneOffHelp':
            'Schakel in om in te stellen dat de basis slechts eenmalig gedaan moet worden.',
        'modules.skills.basicWithRefresherHelpGracePeriod':
            'Bepaal hoeveel extra maanden een medewerker krijgt om het vervolg af te ronden voordat de basis opnieuw van toepassing is.',

        'modules.skills.basicWithRefresher.summaryTitle': 'Samenvatting',
        'modules.skills.basicWithRefresher.summary': `Na het initieel behalen van de basis wordt het vervolg na {basicValidity}
        { basicValidity, select,
            1 {maand}
            other {maanden}
        } leidend.
        { gracePeriod, select,
            null {De basis hoeft slechts eenmalig behaald te worden en komt dus niet te vervallen, ongeacht of het vervolg tijdig wordt behaald.}
            0 {Indien het vervolg niet tijdig wordt behaald, wordt de basis opnieuw van toepassing na {basicValidity}
            { basicValidity, select,
                1 {maand}
                other {maanden}
            }. De medewerker heeft geen uitstelperiode.}
            other {Indien het vervolg niet tijdig wordt behaald, wordt de basis opnieuw van toepassing voor de bekwaamheidsberekening. De medewerker heeft hiervoor na {basicValidity}
            { basicValidity, select,
                1 {maand}
                other {maanden}
            } nog {gracePeriod} extra
            { gracePeriod, select,
                1 {maand}
                other {maanden}
            } de tijd.}
        }`,

        'modules.skills.editor.assign': 'Toewijzen',
        'modules.skills.editor.assignSubtitle': `{count, select,
            0 {Selecteer tenminste één afdeling/functie combinatie}
            1 {1 geldige afdeling/functie combinatie geselecteerd}
            other {{count} geldige afdeling/functie combinaties geselecteerd}
        }`,
        'modules.skills.editor.general': 'Algemeen',
        'modules.skills.editor.content': 'Inhoud bekwaamheid',
        'modules.skills.editor.validity': 'Geldigheidsduur bekwaamheid',
        'modules.skills.editor.months': 'Maanden',
        'modules.skills.editor.moveActivities': 'Activiteiten verplaatsen',
        'modules.skills.editor.moveActivities.description':
            'Verplaats activiteiten naar een ander blok met behoud van resultaten',
        'modules.skills.editor.moveFrom': 'Verplaatsen van',
        'modules.skills.editor.moveTo': 'Verplaatsen naar',
        'modules.skills.editor.assignRights': 'Rechten',
        'modules.skills.editor.allCatalogItems': 'Alle catalogus items',
        'modules.skills.editor.selectCatalogItems': 'Selecteer catalogus items',
        'modules.skills.editor.allDepartments': 'Alle afdelingen',
        'modules.skills.editor.allRoles': 'Alle functies',
        'modules.skills.editor.allowedToCustomizeBlocks': 'Blokken en/of activiteiten deactiveren',
        'modules.skills.editor.allowedToCustomizeBlocksInfo':
            'Deze bekwaamheid kan bij het toewijzen aan worden gepast door onderdelen te deactiveren. Blokken/activiteiten toevoegen mag altijd.',
        'modules.skills.editor.allowedToExtendTheValidity':
            'Geldigheidsduur van bekwaamheid verlengen',
        'modules.skills.editor.allowedToExtendTheValidityInfo':
            'Deze bekwaamheid heeft een geldigheidsduur zoals hierboven ingegeven. Met deze optie kan die verlengd worden bij het toewijzen.',
        'modules.skills.editor.allowOwnerToModifyExemption': 'Zelf een vrijstelling geven',
        'modules.skills.editor.allowOwnerToModifyExemptionInfo':
            'Medewerkers mogen zichzelf een vrijstelling geven voor deze bekwaamheid.',
        'modules.skills.editor.assignToNonExistingRolesHeadline':
            'Toewijzen aan niet bestaande afdeling / functie combinaties',
        'modules.skills.editor.assignToNonExistingRolesSubtitle':
            'Wijs bekwaamheid toe aan functies ongeacht of deze op dit moment op de geselecteerde afdelingen bestaan',
        'modules.skills.editor.activitiesAre': 'Activiteiten zijn',
        'modules.skills.editor.persistOrder': 'Volgorde aanhouden',
        'modules.skills.editor.removeBlock': 'Blok verwijderen',
        'modules.skills.editor.removeBlockCheck':
            'Weet je zeker dat je dit blok met alle onderliggende activiteiten wilt verwijderen?',
        'modules.skills.editor.selectActivities': 'Selecteer activiteiten',
        'modules.skills.editor.selectDepartments': 'Selecteer afdeling(en)',
        'modules.skills.editor.selectRoles': 'Selecteer functie(s)',
        'modules.skills.editor.noBlockYet': 'Nog geen blok toegevoegd, voeg eerst een blok toe.',
        'modules.skills.editor.noActivityYet':
            'Nog geen activiteit toegevoegd, voeg eerst een activiteit toe.',
        'modules.skills.editor.addActivity': 'Activiteit toevoegen',
        'modules.skills.editor.removeActivity': 'Activiteit verwijderen',
        'modules.skills.editor.manageAssessors': 'Beoordelaars beheren',
        'modules.skills.editor.addAssessors': 'Beoordelaars toevoegen',
        'modules.skills.editor.manageAssessors.description':
            '(ont)koppel beoordelaars aan activiteiten',
        'modules.skills.editor.assessmentForm': 'Beoordelingsformulier',
        'modules.skills.editor.examinerFunctionality': 'Toetsfunctionaliteit',
        'modules.skills.editor.combineExams': 'Toetsmomenten combineren',
        'modules.skills.editor.examDuration': 'Duur van toetsmoment (minuten)',
        'modules.skills.editor.catalogitem': 'Catalogusitem',
        'modules.skills.editor.numberOfAssessors': `{assessors, select,
            1 {1 beoordelaar}
            other {{assessors} beoordelaars}
        }`,
        'modules.skills.editor.gracePeriod': 'Uitstelperiode',
        'modules.skills.errors.errorOnSavingChanges':
            'Er is iets mis gegaan bij het opslaan van de wijzigingen',
        'modules.skills.errors.errorOnSavingSomeOfItems':
            'Er is iets misgegaan bij het opslaan van enkele wijzigingen',
        'modules.skills.expirationInfo': `{date, select,
            null {{approved, select,
                true {Verloopt niet}
                false {Nog niet afgerond}
                other {{approved}}
            }}
            other {{date}}
        }`,
        'modules.skills.groupType': `{groupType, select,
            compulsory {Verplicht}
            optional {Verplicht}
            recommended {Geadviseerd}
            other {{value}}
        }`,
        'modules.skills.managerSettings.allowManageAssignments.headline': 'Bekwaamheden toewijzen',
        'modules.skills.managerSettings.allowManageAssignments.description':
            'Managers mogen bekwaamheden toewijzen',
        'modules.skills.managerSettings.assessmentByManagers.headline': 'Activiteiten beoordelen',
        'modules.skills.managerSettings.assessmentByManagers.description':
            'Selecteer op welk niveau managers mogen beoordelen',
        'modules.skills.managerSettings.assessmentByManagers.options': 'Opties',
        'modules.skills.managerSettings.assessmentByManagers.all': 'Alle medewerkers',
        'modules.skills.managerSettings.assessmentByManagers.whereManager':
            'Alleen medewerkers van eigen afdeling',
        'modules.skills.messages.assignedToAllAvailableFunctions':
            'De bekwaamheid is toegewezen aan alle beschikbare functies',
        'modules.skills.messages.errorCategoryNotEmpty':
            'De categorie bevat nog één of meerdere bekwaamheden',
        'modules.skills.modifySearchFilter':
            'Geen beoordelaars gevonden die aan je zoekfilter voldoen...',
        'modules.skills.noExpirationDate': 'Geen verloopdatum',
        'modules.skills.noResults.noAssignments.title': 'Nog niet toegewezen',
        'modules.skills.noResults.noAssignments.text': 'De bekwaamheid is nog niet toegewezen',
        'modules.skills.notApplicableReason': 'Reden niet van toepassing',
        'modules.skills.requestAssessment': 'Beoordeling aanvragen',
        'modules.skills.requestAssessmentFor': 'Beoordeling aanvragen voor',
        'modules.skills.revokedBy': `{ revokedBy, select,
            null {Verwijderd}
            other {Verwijderd door {revokedBy}}
        }`,
        'modules.skills.optionalBlockName': 'Naam blok',
        'modules.skills.overallProgress.status': `{status, select,
            notApproved {niet bekwaam / verlopen}
            expiring {verloopt binnenkort}
            approved {bekwaam / vrijstelling}
            notApplicable {niet van toepassing}
            other {{status}}
        }`,
        'modules.skills.overallProgress.title': 'Algehele voortgang',
        'modules.skills.persistActivityOrder': 'Activiteitvolgorde aanhouden',
        'modules.skills.persistActivityGroupOrder': 'Blokvolgorde aanhouden',
        'modules.skills.persistActivityGroupOrderInfo':
            'Gebruiker kan blokken alleen in volgorde openen',
        'modules.skills.selectReviewer': `{ current, select,
            null {Selecteer een beoordelaar bij wie je de beoordeling wilt aanvragen. }
            other {{current} is ingesteld als beoordelaar. Kies een andere andere beoordelaar of trek de aanvraag in.}
        }`,
        'modules.skills.skillCard.assignmentInfo': `{assignment, select,
            individual {Individueel}
            other {{assignment}}
        }`,
        'modules.skills.settings.skillsMatrixToggleLabel': 'Matrixweergave',
        'modules.skills.status': `{status, select,
            grey {Niet toegewezen / nvt}
            red {Niet bekwaam / verlopen}
            orange {Verloopt binnenkort}
            green {Bekwaam / vrijstelling}
            other {{Status}}
        }`,
        'modules.trainings.status': `{status, select,
            grey {Niets gedaan / niet toegewezen}
            red {Niet voldaan / verlopen}
            blue {Ingeschreven / gestart}
            orange {Verloopt}
            green {Voldaan}
            other {{Status}}
        }`,
        'modules.uservoice.emailWarning': 'E-mailadres ontbreekt',
        'modules.uservoice.noValidEmail':
            'Jouw profiel bevat helaas geen (geldig) e-mailadres. Voer hieronder eenmalig jouw e-mailadres in en sla deze op.',

        'modules.planningIntegration.always':
            'De training wordt doorgestuurd naar jouw planningsapplicatie.',
        'modules.planningIntegration.cancelAndTransfer': 'Annuleren en verplaatsen is onmogelijk',
        'modules.planningIntegration.forCancelPlanning': 'Voor planning annuleren',
        'modules.planningIntegration.forTransfer': 'Voor verplaatsen',
        'modules.planningIntegration.functionalityCantBeUsedInCombination': `"Deze functionaliteit kan momenteel niet gebruikt worden in
                    combinatie met SDB Planning integratie"`,
        'modules.planningIntegration.planningCantBeCanced': `"Deze planning kan
                    niet geannuleerd worden. Eerst moeten alle gebruikers uitgeschreven worden"`,
        'modules.planningIntegration.toggleText': 'Opleidingsuren verwerken in SDB Planning',
        'modules.planningIntegration.nominalDuty.toggleText':
            'Studiebelasting vewerken in SDB Planning',
        'modules.planningIntegration.nominalDutyActivityCode': 'Code nominale uren',
        'modules.planningIntegration.dropdownLabel': 'Dienstverband',

        month: 'Maand',
        monthAbout: '{number} maand',
        monthsAbout: '{number} maanden',
        monthAgo: '{number} maand geleden',
        months: 'Maanden',
        monthsAgo: '{number} maanden geleden',
        multiChoice: 'Meerkeuze vraag',
        multiChoiceInfo: 'Minimaal 2 antwoordopties waarbij slechts 1 antwoord gekozen kan worden.',
        myDepartments: 'Mijn afdelingen',
        myMessages: 'Mijn berichten',
        myMessagesAndNotifications: 'Mijn berichten en notificaties',
        myMinerva: 'Portfolio',
        mySelf: 'Mijzelf',
        name: 'Naam',
        nameBlock: 'Naam block',
        nameActivity: 'Naam activiteit',
        nameAscending: 'Naam oplopend',
        nameDescending: 'Naam aflopend',
        nameGroup: 'Naam',
        navigateToClassroom: 'Wil je de opleidingspagina openen?',
        navigateToEnroll: 'Navigeren naar inschrijvingspagina?',
        navigateToElearning: 'Wil je de e-learningpagina openen?',
        navigateToElearningPage: 'Klik om de e-learningpagina te openen',
        navigateToFavorites: 'Bekijk de lijst met favorieten.',
        neutral: 'Neutraal',
        never: 'Nooit',
        NewAndConfirmPasswordsDoNotMatch: 'Wachtwoorden komen niet overeen',
        newExternalCatalogItem: 'Externe Content',
        newestFirst: 'Nieuwste eerst',
        newEvaluationForm: 'Nieuw evaluatieformulier',
        newParticipant: 'Nieuwe deelnemer',
        NewPassword: 'Nieuw wachtwoord',
        newPlanning: 'Rooster toevoegen',
        newsItems: 'Nieuws',
        NewsItemsWidgetInfo: 'Mis niks van wat er in jouw organisatie gebeurt',
        newsMessage: 'Nieuwsbericht',
        newTool: 'Nieuwe hulpmiddel',
        newLink: 'Nieuwe link',
        newLocation: 'Nieuwe locatie',
        newMessage: 'Nieuw bericht',
        newVersion:
            'Er is een nieuwe versie van deze applicatie beschikbaar. Ververs om te upgraden naar de laatste versie',
        next: 'volgende',
        nextSessionDateTime: 'De volgende sessie is op {date} om {time}',
        no: 'Nee',
        noAccreditationFoundTitle: 'Geen accreditaties gevonden',
        noAccreditationInfoFoundTitle: 'Geen accreditatie instellingen ',
        noAccreditationInfoFoundText: 'Er zijn geen accreditatie instellingen voor deze cursus',
        noAssignments: 'Geen toewijzingen',
        noAssignmentFoundTitle: 'Geen toegekende opleidingen gevonden',
        noAssignmentFoundText: 'Ken een opleiding toe',
        noAuthorizationTasksFound: 'Geen autorisatietaken gevonden!',
        noCatalogItemsFoundTitle: 'Geen catalogusitems gevonden',
        noCatalogItemsFoundText: 'Pas eventueel de filterinstellingen aan.',
        NoCatalogItemInterestsFoundText:
            'U heeft nog niet aangegeven dat u heeft interesse heeft in een catalogusitem',
        NoCatalogItemInterestsFoundTitle: 'Geen catalogusitem-interesses gevonden',
        noCertificate: 'Geen certificaat',
        noComment: 'Geen opmerking',
        noCompetencyPassportFound: 'Geen bekwaamheidspaspoort gevonden',
        noDashBoardLinksTitle: 'Nog geen dashboard links',
        noDashBoardLinksText:
            'Er zijn nog geen dashboard links. Voeg eventueel een dashboard link toe.',
        noDeadline: 'Geen termijn',
        noDefinitionFoundTitle: 'Geen dossier-items gevonden',
        noDefinitionFoundText: 'Voeg een nieuw dossier-item toe',
        noDepartmentsTitle: 'Geen afdelingen gevonden',
        noDepartmentsText: 'Pas eventueel de filter-instellingen aan.',
        noEmail: 'Geen e-mail',
        noEmployeesFound: 'Geen medewerkers gevonden',
        noEnrollmentsYet: 'Nog geen inschrijvingen.',
        noEvaluationsFound: 'Geen evaluaties gevonden',
        noEvaluationFormReports: 'Er zijn geen evaluatieformulieren rapportages gevonden',
        noExemption: 'Geen vrijstelling',
        noExpertiseText: 'Ervaring of extra kennis opgedaan? Voer het op in je portfolio!',
        noExpertiseTitle: 'Geen dossier-items',
        NoFavorites: 'Geen favorieten',
        noFavoritesButNavigateToAdd:
            'Nog geen favorieten. Ga naar de catalogus en klik op <3 om favorieten toe te voegen.',
        noExaminers:
            'Voor deze activiteit kan (nog) geen beoordelaar worden gekozen. Voor meer informatie neem contact op met de beheerder.',
        notAvailableAnymore: 'niet meer beschikbaar',
        notification: 'Notificatie',
        notifications: 'Notificaties',
        notifyUserAfterCompletion: 'Na voltooiing notificatie naar gebruiker sturen',
        None: 'Geen',
        noLesson: 'Geen les',
        noLink: 'Er is geen gekoppelde link!',
        NoLocation: 'Geen locatie',
        NoLocationOrDoubleLocation: 'Geen locatie of de locatie is dubbel ingepland',
        noLocationFoundTitle: 'Geen locaties gevonden',
        noLocationFoundText: 'Voeg een nieuwe locatie toe.',
        noLocationSelected: 'Geen locatie geselecteerd.',
        noParticipantsCatalog: 'Start een zoekactie in het filterpaneel rechts.',
        noPlannings: 'Geen roosters gevonden',
        noReportDataFound: 'Geen rapportgegevens gevonden',
        noResultsFound: 'Geen resultaten gevonden.',
        noResultsFoundTitle: 'Geen resultaten gevonden',
        noResultsFoundText: 'Pas eventueel de filterinstellingen aan',
        noRolesExistForThisDepartment: 'Er bestaan geen functies voor deze afdeling',
        noSchedulesAddedYet: 'Nog geen sessies toegevoegd.',
        noSchedules: 'U moet minimaal één sessie koppelen',
        noSkillsAndMandatoryAssigned: 'Geen bekwaamheden of verplichte opleidingen gekoppeld',
        noSsoGroupsFound: 'Geen sso groepen gevonden',
        notAchieved: 'Niet behaald',
        NotAuthorized: 'Niet geautoriseerd',
        notAvailable: 'nvt',
        NoTeacherOrDoubleTeacher: 'Geen docent of er is een docent dubbel ingepland',
        noTrainingFoundTitle: 'Geen opleiding gevonden',
        noTrainingFoundText:
            'Er zijn geen opleidingen beschikbaar of gebruik andere zoekinstellingen',
        noTrainingFoundTextAlt: 'Er zijn geen opleidingen gevonden',
        notRequired: 'Niet verplicht',
        noEnrollmentsFound: 'Geen inschrijvingen gevonden',
        noEnrollmentsHeader: 'Nergens voor ingeschreven',
        noEnrollmentsText: 'Geen probleem, je kunt je via de catalogus inschrijven',
        notApproved: 'Niet bekwaam',
        notComplete: 'Onvolledig',
        notCompletedStatus: 'Niet voldaan',
        NotEnoughParticipants: 'Onvoldoende deelnemers',
        notRead: 'Niet gelezen',
        NoTeacher: 'Geen docent',
        noTeachers: 'Geen docent geselecteerd.',
        NotEnoughRightsForAccreditations:
            'Je hebt niet genoeg rechten om deze accreditaties te mogen zien.',
        notEnrolledEnrollFirst: 'Nog niet ingeschreven. Ga naar de catalogus om in te schrijven.',
        notKnown: 'Niet bekend',
        notMandatory: 'Mag overslaan',
        notOnAllPresent: 'bij een bijeenkomst niet aanwezig geweest',
        NotOnAllPresentAndNotOnAllNotPresent: 'presentie niet volledig ingevuld',
        notPassed: 'Niet voldaan',
        notRegistered: 'Niet ingeschreven',
        NotSatisfied: 'Niet voldaan',
        NotStarted: 'Niet gestart',
        noPlanningsFoundTitle: 'Geen planningen gevonden',
        noPlanningsFoundText: 'Probeer om de filters aan te passen.',
        noReservationsFoundTitle: 'Geen reserveringen gevonden',
        noTool: 'Geen hulpmiddel geselecteerd.',
        noToolFoundTitle: 'Geen hulpmiddelen gevonden',
        noToolFoundText: 'Voeg een nieuw hulpmiddel toe',
        notYetDone: 'Nog niet klaar',
        notYetFinalized: 'Nog niet afgerond',
        notYetKnown: 'Nog niet bekend',
        notYetStarted: 'Nog niet gestart',
        noUnrollNotificationsFoundTitle: 'Geen notificaties gevonden',
        noUsersFoundTitle: 'Geen gebruikers gevonden',
        noUsersFoundText: 'Vul het correcte domein in en zoek op gebruikersnaam',
        noTeachersFoundTitle: 'Geen leraren gevonden',
        noTeachersFoundText: 'Er zijn nog geen toegekende leraren',
        number: 'Aantal',
        numberApproved: 'Aantal bekwaam',
        numberdays: 'Aantal dagen',
        numberhours: 'Aantal uren',
        numberminutes: 'Aantal minuten',
        numberOfCompletedEvaluations: 'Aantal ingevulde formulieren',
        numberOfUnCompletedEvaluations: 'Aantal niet-voltooide evaluaties',
        numberOfPlacesAvailable: 'Aantal plekken vrij',
        numberOfPoints: 'Aantal punten',
        numberOfPointsAchieved: 'Behaalde punten',
        numberOfRegisteredParticipants: 'Aantal geregistreerde deelnemers',
        numberOfReservations: 'Aantal reserveringen',
        numberOfSelectedItems: 'aantal items geselecteerd',
        numberOfSkills: '{numberOfSkills} bekwaamheden',
        numberOfSkillsInTotal: '{numberOfSkills} bekwaamheden in totaal',
        numberparticipants: 'Aantal deelnemers',
        numberrecipients: 'Aantal deelnemers',
        ofAssessmentsCarriedOutByYou: 'Beoordeel een collega',
        off: 'Uit',
        ok: 'OK',
        oldestFirst: 'Oudste eerst',
        on: 'Aan',
        onAllPresent: 'bij elke bijeenkomst aanwezig geweest',
        one: 'een',
        oneOfTheScheduleIsNotFilledInWell: 'Een van de sessies is niet goed ingevuld',
        onlyActiveAccounts: 'Alleen actieve accounts',
        onlyAssignableByAdmins: 'Alleen toe te wijzen door beheerders',
        onlyDisabledAccounts: 'Alleen uitgeschakelde accounts',
        onlyWithWarnings: 'Alleen met waarschuwingen',
        onlyQualifiedAssessorsAllowed: 'Je bent niet bekwaam om dit item te toetsen',
        oops: 'Oeps',
        open: 'Openen',
        openApplications: 'Openstaande aanvragen',
        openElearningDialogInFullscreenText: 'Open e-learning dialoog in volledig scherm',
        openInANewWindow: 'Openen in een nieuw scherm',
        openQuestion: 'Open vraag',
        openQuestionInfo:
            'Gebruikers kunnen via een open invulveld een aantal steekwoorden opgeven. Er worden geen antwoordopties bij deze vraag opgeven.',
        openScormInNewWindow: 'Open SCORM pakket(ten) in nieuw scherm',
        openScormInNewWindowDetail:
            'Wanneer dit catalogusitem SCORM pakketten bevat, openen deze in een nieuw scherm. Alleen gebruiken wanneer starten in hetzelfde scherm problemen oplevert, omdat openen in een nieuw scherm problemen met popupblockers op zou kunnen leveren. Deze instelling heeft geen functie wanneer er geen SCORM pakketten in dit catalogusitem zitten.',
        openScormInNewWindowDontClose:
            'Deze content is geopend in een nieuwe scherm. Sluit dit scherm of tab niet af.',
        optional: 'Optioneel',
        options: 'Opties',
        optionText: 'Tekst optie',
        optionQuestion: 'Optie vraag',
        order: 'Volgorde',
        'Ordered List': 'Nummering',
        orEnterAssessmentsByName: 'Beoordeel een collega',
        Outdent: 'Inspringing verkleinen',
        overrideTheMasterDescription:
            'Gebruik een eigen tekst in plaats van de standaard omschrijving',
        overrideTheMasterSummary: 'Gebruik een eigen tekst in plaats van de standaard samenvatting',
        ownName: 'Eigen benaming',
        Package: 'Pakket',
        PackagesActive: 'Pakketten actief',
        PackagesInactive: 'Pakketten inactief',
        PageNotFound: 'De pagina kon niet worden gevonden...',
        pageIsUnavailableTitle: 'Pagina niet beschikbaar',
        pageIsUnavailable: 'Deze pagina is niet beschikbaar of buiten werking gesteld.',
        payAttention: 'Let op',
        parentPortalGroup: 'Bovenliggende afdeling',
        participant: 'Deelnemer',
        participantAdded: 'Deelnemer toegevoegd',
        ParticipantEntersTheQueue: 'Deelnemer komt op wachtrij',
        participantIsNotSelected: 'Deelnemer is niet geselecteerd',
        participantRegistered: 'deelnemer ingeschreven',
        participants: 'Deelnemers',
        participantsRegistered: 'deelnemers ingeschreven',
        participantUnRegistered: 'Deelnemer uitgeschreven',
        passed: 'Voldaan',
        Passed: 'Voldaan',
        PassedAndExpired: 'Voldaan, maar verlopen',
        past: 'Verleden',
        pathToImage: 'Pad naar afbeelding',
        paymentHeading: 'Afrekenen',
        'pe.externalPersonId': 'PE-online Gebruiker ID',
        'pe.courseId': 'PE-online Cursus ID',
        'pe.editionId': 'PE-online Editie ID',
        'pe.moduleId': 'PE-online Module ID',
        'pe.organization': 'Beroepsorganisatie',
        peerassessment: 'Peer assessment',
        peerAssessment: 'Peer assessment',
        percentageApproved: 'Percentage bekwaam',
        'percentageApproved%': '% bekwaam',
        period: 'Periode',
        Periods: 'Termijnen',
        periodsInDays: 'In- & uitschrijftermijn',
        permalink: 'Permanente link',
        permissionRequestHasFailed: 'Toestemmingsverzoek is mislukt',
        personalGroup: 'Persoonlijk',
        phaseOutDate: 'Uitfaseerdatum',
        phaseOutDateContent: 'vanaf {date} niet meer beschikbaar',
        phaseOutDateContentSkills: 'Vanaf {date} niet meer beschikbaar',
        phone: 'Telefoon',
        placeOfBirth: 'Geboorteplaats',
        plannedActivities: 'Geplande activiteiten',
        plannedSessions: 'Geplande sessies',
        planners: 'Planners',
        planning: 'Rooster',
        planningIsInPast: 'Rooster is in het verleden',
        plannings: 'Roosters',
        planningInformation: 'Rooster informatie',
        planningOverlap:
            'Er is overlap met jouw diensten. Inschrijven is niet mogelijk. Controleer jouw planning.',
        planningOverlapGeneral:
            'Er is overlap met jouw diensten. Inschrijven is niet mogelijk. Controleer jouw planning.',
        planningSelectContract: 'Selecteer een actief dienstverband.',
        planningOverlapButton: 'Overlap in diensten',
        pleaseChooseTheDesiredDateAndClickEnrollToRegisterForTheNewSchedule:
            'Kies hieronder de gewenste datum en klik op "Inschrijven" om in te schrijven op de nieuwe planning',
        Process: 'Verwerken',
        points: 'Punten',
        portfolio: 'Dossier',
        PortfolioDefinitions: 'Dossieritems',
        PortfolioInstance: 'Dossieritem',
        PortfolioNew: 'Nieuwe Dossierdefinitie',
        PortfolioTasks: 'Dossier',
        PortfolioTasksAuthorize: 'Dossiertaken - autoriseren',
        PortfolioTasksReview: 'Dossiertaken - beoordelen',
        PortfolioTasksAccept: 'Dossiertaken - accepteren',
        PortfolioAccept: 'Accepteren',
        PortfolioReject: 'Afwijzen',
        PortfolioRemoveMarking: 'Markering verwijderen',
        'portfolio.mandatory.heading.mandatoryTraining': 'Verplichte opleiding',
        'portfolio.mandatory.heading.bestResult': 'Beste resultaat',
        'portfolio.mandatory.heading.certificate': 'Certificaat',
        'portfolio.results.heading.course': 'Opleiding',
        'portfolio.results.heading.lastAction': 'Laatste actie',
        'portfolio.results.heading.bestResult': 'Beste resultaat',
        'portfolio.results.heading.certificate': 'Certificaat',
        practical: 'Praktijktoets',
        practicalTest: 'Praktijktoets',
        preference: 'Voorkeur',
        presence: 'Presentie',
        presenceOfTheDaysBefore: 'Presentie van de dagen hiervoor',
        presenceNotFullyUpdated: 'Presentie niet volledig bijgewerkt',
        present: 'Aanwezig',
        presentOn: 'Aanwezig op',
        presentParticipants: 'Aanwezige deelnemers',
        previousStep: 'Vorige stap',
        primaryBackgroundColor: 'Primaire achtergrondkleur',
        primaryBackgroundColorDark: 'Primaire donkere achtergrondkleur',
        primaryBackgroundColorLight: 'Primaire lichte achtergrondkleur',
        price: 'Prijs',
        Price: 'Prijs',
        PaymentValidity: 'Geldigheidsduur (dagen)',
        DomainSpecificPrice: 'Domein specifieke kosten',
        PortalSpecificPrice: 'portaalgroep specifieke kosten',
        PortalText: 'Portaaltekst',
        profile: 'Profiel',
        ProfileOf: 'Profiel van',
        profileImage: 'Profielfoto',
        profileSaved: 'profiel opgeslagen',
        progress: 'Voortgang',
        protocol: 'Protocol',
        question: 'Vraag',
        questionText: 'Vraagtekst',
        questionType: 'Vraagtype',
        queue: 'Wachtrij',
        queued: 'U zit in de wachtrij',
        Queued: 'In de wachtrij',
        QueuedAlreadyOnAPlanning: 'De persoon staat al in een wachtrij op een planning.',
        queueThenAutomaticRegistration: 'Wachtrij, daarna automatisch inschrijving',
        queueThenSendInvitation: 'Wachtrij daarna uitnodiging sturen',
        QueuedWhenFull: 'Wachtrij indien vol',
        read: 'Gelezen',
        readyForYou: 'Dit staat voor jou klaar',
        readProtocol: 'Heeft u het protocol gelezen?',
        readManual: 'Heeft u de handleiding gelezen?',
        readless: 'Minder',
        readmore: 'Meer',
        reason: 'Reden',
        receivedMessages: 'Ontvangen berichten',
        receivedOn: 'Ontvangen op',
        receiverDomain: 'Ontvanger domein',
        recipients: 'Ontvangers',
        recommended: 'Geadviseerd',
        redo: 'Opnieuw doen',
        Redo: 'Opnieuw doen',
        refresh: 'Verversen',
        refreshThePage: 'Ververs de pagina',
        register: 'Registreren',
        registered: 'Ingeschreven',
        Registered: 'Ingeschreven',
        registerFrom: 'Per {date}',
        registeredOn: 'Ingeschreven per',
        registeredFrom: 'Ingeschreven vanaf',
        registeredParticipants: 'Ingeschreven deelnemers',
        registeredTill: 'Ingeschreven t/m',
        registerMe: 'Schrijf mij in',
        registration: 'Registratie',
        registrationClosed: 'Inschrijving gesloten',
        registrationNumbers: 'Registratienummers',
        registrationNumbersSaved: 'Registratienummers opgeslagen',
        registrationPeriodHasExpired: 'Inschrijftermijn verstreken',
        registrationFailedTheAllowedNumberOfRegistrationsHasBeenReached:
            'Inschrijven is mislukt. Het toegestane aantal inschrijvingen is bereikt',
        registrationTransferPeriodExceededTitle: 'Overschrijftermijn verstreken',
        registrationTransferPeriodExceeded:
            'Je wilt overschrijven naar een andere sessie. Dit is niet mogelijk omdat de uitschrijftermijn van de huidige sessie is verstreken.',
        'registrationNumbers.notAllowed': 'Het is niet mogelijk om registratienummers op te geven.',
        relatedDocuments: 'Gerelateerde documenten',
        relatedDocumentsText:
            'Documenten zijn standaard voor iedereen zichtbaar. Wilt u ze pas zichtbaar maken nadat iemand zich heeft ingeschreven? Zet dan de schakelaar voor het document aan.',
        removedRole: '[verwijderde functie]',
        ReservationRevoked: 'Ingetrokken',
        reservationCode: 'Reserveringscode (optioneel)',
        reservationCodeIsAlreadyUsed: 'De reserveringscode is al gebruikt',
        reservationExpired: 'Verlopen',
        'reservation.status.revoked': 'De reserveringcode is ingetrokken',
        reservation: 'Reservering',
        reinstatedCancelledPlanning: 'Planning weer actief',
        reinstatePlanning: 'Opnieuw activeren',
        deleteCancelledPlanning: 'Planning verwijderd',
        RegionAlreadyExists: 'Er bestaat al een regio / locatie met deze naam!',
        regionLocation: 'Regio / locatie',
        Region_still_in_use:
            'Deze regio is gekoppeld aan een of meerdere tijdsloten en kan daarom niet verwijderd worden',
        rejectAuthSingle: 'U staat op het punt om {number} verzoek af te wijzen',
        rejectAuthPlural: 'U staat op het punt om {number} verzoeken af te wijzen',
        Rejected: 'Afgewezen',
        rejectedPortfolioItems: 'Er is één dossieritem afgewezen',
        rejectedPortfolioItemsPlural: 'Er zijn {number} dossieritems afgewezen',
        rejectedSelection: 'Selectie afgewezen!',
        rejectedPartialSelection: 'Selectie deels afgewezen!',
        remarkUser: 'Opmerking deelnemer:',
        removeAppointment: 'U heeft al een afspraak staan, wilt u deze verwijderen?',
        removeAllUsersFromVirtualQueueHeader:
            'Alle gebruikers verwijderen van deze interesselijst?',
        removeAllUsersFromVirtualQueueMessage:
            'Alle getoonde interesse voor dit catalogusitem komt hiermee te vervallen',
        removedSelection: 'Selectie verwijderd!',
        removeFunctionUserDepartment:
            'Gebruiker {username} heeft onderstaande functies op deze afdeling. Heeft deze gebruiker een bepaalde functie niet meer, vink deze dan uit.',
        removeFunctionUserDepartment2ndLine:
            'Let op, sommige functies zijn verkregen door een automatische import en kunnen daarom niet gewijzigd worden',
        removeFromList: 'Verwijder van lijst',
        removeFromVirtualQueue: 'Verwijderen van de interesselijst',
        removeFromVirtualQueueDialogHeader: 'Interesse laten vervallen?',
        removeFromVirtualQueueDialogMessage:
            'Weet je zeker dat je jezelf van de interesselijst wilt verwijderen?',
        removeFromVirtualQueueUserDialogHeader: '{displayName} van interesselijst verwijderen?',
        removeFromVirtualQueueUserDialogMessage:
            'De interesse van {displayName} voor dit catalogusitem komt hiermee te vervallen',
        removePlanningPermanently: 'Planning definitief verwijderen?',
        reply: 'Beantwoorden',
        reporting: 'Rapportage',
        reports: 'Rapporten',
        reportSelection: 'Rapport selectie',
        'reports.department.mandatoryUsers.currentStatus': 'Huidige status',
        request: 'Aanvraag',
        requestDenied: 'Aanvraag afgewezen',
        requestPending: 'Aanvraag loopt',
        requests: 'Aanvragen',
        required: 'Verplicht',
        RequiredTrainingsWidgetInfo: 'Altijd inzicht in jouw voortgang',
        'required and must be higher than zero': 'Verplicht en moet hoger dan nul zijn',
        requiredAndMustNotBeGreaterThan: 'Verplicht en mag niet groter dan {number} maanden zijn',
        requiredAndMustNotBeGreaterThan1200: 'Verplicht en mag niet groter dan 1200 maanden zijn',
        requiresTeacher: 'Docent verplicht',
        'requiresTeacher.explainer':
            'Een docent moet verplicht worden ingepland op iedere sessie (van rooster)',
        reset: 'Reset',
        resetLearningPlan: 'Opnieuw maken',
        resetProgress: 'Reset voortgang',
        revoked: 'Ingetrokken',
        retry: 'Probeer opnieuw',
        remove: 'verwijder',
        RemoveCatalogInterestConfirm: `Weet u zeker dat u ''{displayName}'' als een interesse wilt verwijderen?`,
        reregister: 'Opnieuw inschrijven',
        reservations: 'Reserveringen',
        reserved: 'Gereserveerd',
        resetCourseTitle: 'Voortgang resetten?',
        resetCourseFor:
            'Het is mogelijk om de voortgang van e-learning "{catalogName}" te resetten omdat deze dreigt te verlopen, al verlopen is of omdat de e-learning niet is behaald door "{displayName}". Alle huidige voortgang van "{displayName}" gaat dan verloren. Het heeft geen invloed op eerder behaalde resultaten.',
        resetCourseWarning: 'Weet je zeker dat je de voortgang voor "{displayName}" wilt resetten?',
        resetFilter: 'reset filter',
        resetCatalogSettingHeader:
            'Resetten van e-learningmodules (hier is per e-learning weer vanaf te wijken)',
        resetCatalogSettingYes: 'Aan: cursisten kunnen zelf e-learning modules resetten',
        resetCatalogSettingNo: 'Uit: cursisten kunnen niet zelf e-learning modules resetten',
        resource: 'Middel',
        resources: 'Middelen',
        resubmit: 'Opnieuw indienen',
        result: 'Resultaat',
        resultFinal: 'Eindresultaat',
        results: 'Resultaten',
        resultUnknown: 'Resultaat onbekend',
        Review: 'Ter controle',
        reviewer: 'Beoordelaar',
        revoke: 'Intrekken',
        revokeReservation: 'Reservering intrekken',
        revokeConfirmation: 'Weet je zeker dat je deze reservering wilt intrekken?',
        right: 'Rechts',
        Right: 'Rechts',
        Rights: 'Rechten',
        RightsDescription: 'Overige instellingen',
        Role: 'Rol',
        roles: 'Rollen',
        Satisfied: 'Voldaan',
        save: 'Opslaan',
        saveAndContinue: 'Opslaan en doorgaan',
        saved: 'Opgeslagen',
        schedule: 'Sessie',
        scheduleDetail: 'Sessie details',
        scheduleDuration: 'Sessieduur',
        scheduleFull: 'Inschrijven niet mogelijk. Er zijn geen beschikbare plaatsen meer.',
        schedules: 'Sessies',
        scheduleMissesDuration: 'Sessie mist sessieduur',
        Schedule_not_found: 'Schema niet gevonden!',
        schedulesOverlap: 'De sessies overlappen',
        scheduleStartTimeEndTimeConflict: 'Sessie starttijd en eindtijd conflict',
        scheduleTimeTooLong: 'Sessieduur is te lang',
        scorm: 'SCORM',
        sendClassroomReminderMail: 'Stuur herrinneringse-mail',
        'sendClassroomReminderMail.explainer':
            'Stuur deelnemers voorafgaand aan de opleiding een e-mail ter herinnering',
        sendClassroomReminderMailNumberOfDays: 'Aantal dagen voor de cursus',
        'settings.lookAndFeel': 'Huisstijl',
        'settings.lookAndFeel.uploadLogo': 'Upload logo',
        'settings.lookAndFeel.uploadHeroHeader': 'Upload headerfoto',
        'settings.lookAndFeel.heroheader': 'Toon headerfoto op dashboard',
        'settings.lookAndFeel.heroheader.description':
            'Toon een eigen foto als visuele introductie bovenaan de dashboardpagina',
        'settings.featurePreview.title': 'Nieuwe (beta) functionaliteiten',
        'settings.featurePreview.setting': `{value, select,
            off {Uit}
            admin {Beheerders}
            everyone {Iedereen}
            other {{value}}
        }`,
        setApprovalDate: 'Stel datum basis in',
        skillDeleted: 'Bekwaamheid verwijderd',
        skillDetails: 'Details bekwaamheid',
        skillslabtest: 'Skillslab toets',
        skillslabtraining: 'Skillslabtraining',
        score: 'Score',
        'sdb/uriECD': 'Naar SDB ECD',
        'sdb/uriHr': 'Naar SDB HR',
        'sdb/uriPayroll': 'Naar SDB Salaris',
        'sdb/uriPlanning': 'Naar SDB Planning',
        'sdb-add-examiner-appointment-dialog.button': `{ button, select,
            previous_step {Vorige stap}
            schedule_new_appointment {Nieuw toetsmoment inplannen}
            schedule_appointment {Toetsmoment inplannen}
            other {}
        }`,
        'sdb-add-examiner-appointment-dialog.errors': `{ error, select,
            no_time_slots_available {Het is op dit moment niet mogelijk een toetsmoment in te plannen omdat er geen tijdsloten beschikbaar zijn.}
            time_slot_already_reserved {Deze afspraak conflicteert met een andere gemaakte afspraak. Ga terug en selecteer een ander tijdstip of dag.}
            examiner_not_qualified {De toetser is op dit moment niet bekwaam en mag deze activiteit niet aftoetsen.}
            other {Er is een onbekende fout opgetreden. [{error}]}
        }`,
        'sdb-add-examiner-appointment-dialog.headline': `{ step, select,
            1 {Toetsmoment combineren of nieuw toetsmoment inplannen}
            2 {Datum en locatie kiezen}
            3 {Toetsmoment op { date_and_location }}
            4 {Toetsmoment inplannen}
            other {}
        }`,
        'sdb-add-examiner-appointment-dialog.description': `{ step, select,
            1 {Er is tijd beschikbaar bij een eerder gepland toetsmoment. Kies hieronder een toetsmoment om de afspraak te combineren of plan een nieuw toetsmoment in.}
            2 {Kies een datum en locatie voor het inplannen van een toetsmoment.}
            3 {Kies het gewenste tijdslot.}
            4 {Wil je het onderstaande toetsmoment inplannen?}
            other {}
        }`,
        'sdb-continue-elearning-widget.widgetInfo': `Ga direct verder met deze e-learning`,
        'sdb-time-slot-details.activities-to-assess': `{activities, select,
            1 {Te beoordelen activiteit}
            other {Te beoordelen activiteiten}
        }`,
        search: 'Zoeken',
        searchAClassroomTraining: 'Zoek een klassikale opleiding',
        searchAssignments: 'Zoeken toewijzingen',
        searchByDate: 'Zoek op datum',
        searchByLocation: 'Zoeken op locatie',
        searchByName: 'Zoek op naam',
        searchByNameDepartmentOrJobTitle: 'Zoek op naam, afdeling of functienaam',
        searchByReceiverName: 'Zoek op ontvanger naam',
        searchBySenderName: 'Zoek op naam afzender',
        searchByTeacher: 'Zoek op docent',
        searchDepartment: 'Zoek afdeling',
        searchElearning: 'E-learning zoeken',
        searchForAMessageOrNotification: 'Zoek een bericht / notificatie',
        searchForAUserWithSkills: 'Zoek naar een gebruiker met bekwaamheden',
        searchFunctions: 'Zoek functies',
        searchLocation: 'Zoek locatie',
        searchOn: 'Zoek op',
        searchOnCatalogName: 'Zoek op catalogusnaam',
        searchOnDepartmentName: 'Zoek op afdelingsnaam',
        searchOnRoleName: 'Zoek op functienaam',
        searchRecipients: 'Zoek ontvangers',
        searchRole: 'Role zoeken',
        searchPackage: 'Pakket zoeken',
        searchSkill: 'Bekwaamheid zoeken',
        searchTool: 'Zoek hulpmiddel',
        secondAbout: '{number} second',
        secondaryBackgroundColor: 'Secundaire achtergrondkleur',
        secondaryBackgroundColorDark: 'Secundaire donkere achtergrondkleur',
        secondaryBackgroundColorLight: 'Secundaire lichte achtergrondkleur',
        secondAgo: '{number} seconde geleden',
        secondsAbout: '{number} seconden',
        secondsAgo: '{number} seconden geleden',
        selectACatalogItemToAssignAcertificateTo: 'Selecteer catalogusitems om aan toe te wijzen',
        SelectACertificate: 'Selecteer een certificaat',
        selectAll: 'Selecteer allemaal',
        selectAll2: 'Alles selecteren',
        selectAnActivity: 'Selecteer een activiteit',
        selectAPeriod: 'Selecteer een periode',
        selectAPlanning: 'Selecteer een planning',
        SelectASkillToChangeAssignments: 'Selecteer een bekwaamheid om toewijzingen aan te passen',
        selectATrainingToAdd: 'Selecteer een training om aan toe te wijzen',
        selectAssignmentOption: 'Selecteer toewijzingsoptie:',
        selectCategory: 'Selecteer categorie',
        selectCompetenceAndFunctions: 'Selecteer bekwaamheid en functies',
        selectDepartment: 'Selecteer afdeling',
        selectDomain: 'Selecteer domein',
        selectDownloadType: 'Selecteer download type',
        selectEmployee: 'Selecteer een medewerker',
        selectExportToMakeAnotherUserAssessorForTheSameActivitiesAsThisUser: `Kies voor ''EXPORTEREN'' om een andere gebruiker beoordelaar te maken bij dezelfde activiteiten als deze gebruiker.`,
        SelectExternalLearningPlan: 'Selecteer externe leerplan(nen)',
        selectFile: 'Bestand selecteren',
        selectFunctions: 'Selecteer functies',
        SelectPhoto: 'Foto selecteren',
        SelectManagerCatalogAuthorization: 'Selecteer een manager om de aanvraag bij in te dienen',
        selectReviewer: 'Selecteer de collega die u gaat beoordelen',
        selectScormItem: 'Selecteer scorm item',
        selectStatus: 'Selecteer status',
        selectTargetType: 'Selecteer type',
        selectType: 'Selecteer type',
        selectReportType: 'Selecteer type rapport',
        selfassessment: 'Zelfbeoordeling',
        send: 'Verzenden',
        sendAMessage: 'Stuur een bericht',
        sendMessage: 'Stuur bericht',
        senderDomain: 'Afzender domein',
        sendUserConfirmationMail: 'Stuur een bevestiging per e-mail',
        sentMessages: 'Verzonden berichten',
        session: 'Sessie',
        sessionName: 'Sessienaam',
        sessions: 'sessies',
        settings: 'Instellingen',
        skill: 'Bekwaamheid',
        skillTitle: 'Titel bekwaamheid',
        skills: 'Bekwaamheden',
        skillsReport: 'Bekwaamheden rapport',
        skillsReportWidgetInfo: 'Monitor de voortgang van bekwaamheden',
        skillsAlmostExpired: 'bijna verlopen',
        skillsExpired: 'verlopen',
        skillsPassport: 'Bekwaamheidspaspoort',
        'SkillsReport.WhatToExport': 'Welke gegevens wil je exporteren?',
        'SkillsReport.SummaryExport': 'Samenvatting van bekwaamheden (huidige selectie)',
        'SkillsReport.TotalExport': 'Totaaloverzicht van bekwaamheden op afdeling/functie niveau',
        'SkillsReport.Present': 'Actueel',
        'SkillsReport.DisplayProvenSkills': 'Toon aangetoonde bekwaamheden op...',
        'SkillsReport.History': 'Historie',
        'SkillsReport.HistoryConflictNoDate': 'Selecteer een datum',
        'SkillsReport.HistoryConflictNoType': 'Selecteer het soort data',
        'SkillsReport.HistoryConflictNoUser': 'Selecteer een gebruiker',
        'SkillsReport.HistoryConflictMessage': 'Selecteer een datum en de gewenste historie',
        'SkillsReport.PresentOverview': 'Actueel overzicht ',
        'SkillsReport.HistoryOverview': 'Historie overzicht',
        'SkillsReport.SelectUser': 'Selecteer een gebruiker',
        'SkillsReport.ShowHistory': 'Toon historie van...',
        'SkillsReport.SpecificUser': 'Specifieke gebruiker',
        'SkillsReport.ProvenSkills': 'Aangetoonde bekwaamheden',
        showAll: 'Toon alles',
        showAlsoExpiredAndFutureCatalogItems: 'Toon ook verlopen / toekomstige items',
        showAssessment: 'Toon assessment',
        showDepartments: 'Toon afdelingen',
        showDisabledAccounts: 'Toon uitgeschakelde accounts',
        showEmptyValues: 'Toon lege waarden',
        showExpiredCatalogItems: 'Toon verlopen items',
        showExpiredSchedules: 'Toon verlopen roosters',
        showForm: 'Toon formulier',
        showFutureCatalogItems: 'Toon toekomstige items',
        signIn: 'Inloggen',
        signInToUse: 'Log in om van SDB Leerplatform gebruik te kunnen maken',
        showOnly: 'Toon alleen',
        showOverview: 'Overzicht weergeven',
        signOut: 'Uitloggen',
        size: 'Grootte',
        someoneElse: 'Iemand anders',
        sortBy: 'Sorteer op',
        sortByDate: 'Sorteer op datum',
        sortedByDate: 'Gesorteerd op datum',
        spaceFillingProportionalStandard: 'Ruimtevullend, in verhouding (standaard)',
        ssosearch: 'Gebruiker zoeken',
        ssoUserInformation: 'Gebruikersaccount',
        ssoUserOrGroup: 'Gebruiker / groep',
        start: 'Starten',
        startDate: 'Begindatum',
        startDates: 'Planning',
        started: 'Gestart',
        Started: 'Gestart',
        startedOn: 'Begonnen op',
        StartTime: 'Starttijd',
        statusAll: 'Alles',
        statusAccept: 'Ter acceptatie',
        startedStatus: 'Gestart',
        statusReview: 'Ter controle',
        statusReject: 'Afgewezen',
        Status: 'Status',
        statusCompleted: 'Geaccepteerd/Afgehandeld',
        stillToDo: 'nog te doen',
        strikethrough: 'Doorhalen',
        stronglyAgree: 'Sterk mee eens',
        stronglyDisagree: 'Sterk mee oneens',
        studyLoad: 'Studiebelasting',
        style: 'Stijl',
        subDepartment: 'Subafdeling',
        subDepartments: 'Subafdeling(en)',
        subject: 'Onderwerp',
        submit: 'Indienen',
        submitAssessment: 'Beoordeling indienen',
        submitForm: 'Formulier indienen',
        submitRequest: 'Aanvraag indienen',
        submitCatalogAuthorizationRequest:
            'Je gaat een aanvraag indienen bij je manager om deze training te kunnen volgen. Dit zijn de gegevens:',
        subtitle: 'Subtitel',
        SuccesRegistrationIsPossible: 'De persoon kan op dit rooster worden ingeschreven.',
        Summary: 'Samenvatting',
        tabDetails: 'Details',
        tabBijlagen: 'Bijlagen',
        tabComments: 'Opmerkingen',
        'targetType.all': 'Alles',
        'targetType.learningplan': 'E-learning',
        'targetType.composition': 'E-learning',
        'targetType.classroom': 'Klassikaal',
        'targetType.expertise': 'Dossier-items',
        'targetType.microlearning': 'Micro Learning',
        'targetType.scorm': 'Scorm',
        'targetType.lti': 'LTI',

        'targetType.microlearning.video': 'Video',
        'targetType.microlearning.movie': 'Video',
        'targetType.microlearning.audio': 'Audio',
        'targetType.microlearning.animation': 'Animatie',
        'targetType.microlearning.infographic': 'Infographic',
        'targetType.microlearning.exercise': 'Oefenen',
        'targetType.microlearning.microcourse': 'Microcursus',
        'targetType.microlearning.text': 'Tekst',
        'targetType.microlearning.test': 'Toets',
        'targetType.microlearning.pretest': 'Test jezelf',
        'targetType.microlearning.complete': 'E-learning',
        'targetType.microlearning.elearning': 'E-learning',
        tcgPasswordStrength: {
            Label: 'Sterkte',
            None: 'geen',
            VeryWeak: 'erg zwak',
            Weak: 'zwak',
            Medium: 'gemiddeld',
            Strong: 'sterk',
            VeryStrong: 'zeer sterk',
        },
        Taught: 'Lesgegeven',
        teacher: 'Docent',
        teacherOverviewDescription: 'Overzicht docenten',
        teachers: 'Docenten',
        teacherIsMandatory: 'Docent is verplicht',
        Temporary: 'Tijdelijk',
        temporaryParticipants: 'Voorlopig ingeschreven deelnemers',
        temporaryParticipantsTab: 'Voorlopige inschrijvingen',
        template: 'Sjabloon',
        tertiaryBackgroundColor: 'Tertiaire achtergrondkleur',
        testResult: 'Resultaat',
        text: 'Tekst',
        textField: 'Tekstveld',
        theBlockMustAtleastContainAquestion: 'De blok moet tenminste één vraag bevatten',
        theActivityIsNoLongerAvailable: 'De activiteit is niet meer beschikbaar',
        theAllowedNumberOfRegistrationsHasBeenReached:
            'Het toegestane aantal inschrijvingen voor deze gebruiker is bereikt',
        theAllowedNumberOfRegistrationsForMyselfHasBeenReached:
            'U heeft uw maximaal aantal inschrijvingen voor deze opleiding bereikt',
        theAllowedNumberOfRegistrationsForThisUserHasBeenReached:
            'Het toegestane aantal inschrijvingen voor deze gebruiker is bereikt',
        theColleagueWhoWillAssessYou: 'De collega die jou gaat beoordelen',
        theFirstBeginsOn: 'de eerste begint op ',
        theFollowingParticipantsAreStillRegisteredOnTheSchedule:
            'De volgende deelnemers zijn nog ingeschreven op de planning:',
        TheLearningPlansThatWillGetActivated: 'De leerplannen die geactiveerd gaan worden',
        theListOfLinkedSkills: 'Gekoppelde bekwaamheden',
        themeColors: 'Themakleuren',
        themeColorPrimary: 'Primaire kleur',
        themeColorSecondary: 'Secundaire kleur',
        themeColorTertiary: 'Tertiare kleur',
        themeColorButton: 'Knop kleur',
        themeColorProgress: 'Voortgangsbalk kleur',
        themeColorProgress100: 'Voortgangsbalk 100% kleur',
        themeDescription:
            'Stel eenvoudig 2 themakleuren in. De tekstkleur en de ondersteunende kleuren worden automatisch berekend. Niet helemaal naar wens? Bepaal dan zelf de lichte en donkere variant van de basiskleuren!',
        theMinimumNumberOfMandatoryActivitiesIs:
            'Het minimum aantal verplichte activiteiten is {number}',
        theNextOneIsOn: 'de volgende is op',
        theNextActivityStarts: 'Volgende activiteit:',
        theNextLessonStarts: 'Volgende activiteit:',
        thereAreEvaluationRequests: 'Evalueer jouw trainingen',
        thereAreListsThatNeedToBeUpdated: 'Verwerk openstaande presentielijsten',
        thereAreNoAssessmentsFound: 'Er zijn geen beoordelingen gevonden',
        thereAreNoAssessmentsFoundTitle: 'Geen beoordelingen',
        ThereArenoAssignmentsFound: 'Er zijn geen beoordelingen gevonden',
        thereAreNoDepartmentsFound: 'Er zijn geen afdelingen gevonden',
        thereAreNoDocuments: 'Er zijn geen documenten',
        thereAreNoFormsFound: 'Er zijn geen formulieren gevonden',
        thereAreNoFormsFoundTitle: 'Geen formulieren',
        thereAreNoFunctionsLinkedToThisSkill:
            'Er zijn geen functies meer gekoppeld aan deze bekwaamheid',
        thereAreNoFuturePlannedLessons: 'Er zijn geen toekomstige geplande lessen',
        thereAreNoListsToUpdate: 'Er zijn geen lijsten om bij te werken',
        thereAreNoMessageFound: 'Er zijn geen berichten gevonden',
        thereAreNoMessageTitle: 'Geen berichten gevonden',
        thereAreNoOpenAssessmentRequests: 'Er zijn geen openstaande beoordelingsaanvragen',
        thereAreNoPastPlannedLessons: 'Er zijn geen verlopen lessen gevonden',
        thereAreNoParticipantsConnectedToThisSchedule:
            'Er zijn geen deelnemers gekoppeld aan deze planning',
        thereAreNoParticipantsFound: 'Er zijn geen deelnemers gevonden',
        thereAreNoRequiredTrainings: 'Er zijn geen verplichte opleidingen',
        thereAreNoRequiredTrainingsFound: 'Er zijn geen verplichte opleidingen gevonden',
        thereAreNoRequiredTrainingsAssigned: 'Geen verplichte opleidingen gekoppeld',
        thereAreNoSkillsCategoriesFoundTitle: 'Geen bekwaamheden categorieën gevonden',
        thereAreNoSkillsCategoriesFoundText:
            'Voeg een bekwaamheid-categorie toe of reset the filters',
        thereAreNoSkillsFound: 'Er zijn geen bekwaamheden gevonden',
        thereAreNoSkillsFoundTitle: 'Geen bekwaamheden gevonden',
        thereAreNoSkillsLinked: 'Er zijn geen bekwaamheden gekoppeld',
        thereAreNoSkillsFoundText: 'Pas eventueel de filterinstellingen aan',
        thereAreOpenAssessmentRequests: 'Er zijn openstaande beoordelingsaanvragen',
        thereAreThecomingDays: 'Er zijn de komende',
        thereIsAccreditationFound: 'Er is geen accreditatie gevonden',
        thereIsAnEvaluationRequests: 'Evalueer jouw training',
        thereIsNoIndividualConnected: 'Er is geen individu gekoppeld',
        thereIsNoLocationSelected: 'Er is geen locatie geselecteerd',
        thereIsNoTeacherSelected: 'Er is geen docent geselecteerd',
        theSelectedAssessor: 'De geselecteerde beoordelaar',
        theorems: 'Stellingen',
        thisElearningCourseIs: 'Deze E-learning cursus is',
        thisExaminerIsNotSkilled: 'Deze beoordelaar is niet bekwaam',
        thisMinervaPortalDoesNotExist: 'Dit SDB Leerplatform bestaat niet',
        thisScheduleIsFullTheNewRegistrationsWillBeQueued:
            'Dit rooster is vol. De nieuwe inschrijvingen komen op de wachtrij',
        thisScheduleConflictsWithAnotherScheduleForWhichThisParticipantIsRegistered:
            'Dit rooster conflicteert met een ander rooster waarvoor deze deelnemer ingeschreven is',
        thisScheduleConflictsWithAnotherScheduleForWhichYouAreRegistered:
            'Dit rooster conflicteert met een ander rooster waarvoor u ingeschreven bent',
        thisScheduleCannotBeEditedBecauseItIsInTheFuture:
            'Dit rooster kan niet bewerkt worden omdat het in de toekomst is',
        time: 'Tijd',
        Title: 'Titel',
        to: 'Aan',
        today: 'Vandaag',
        todolist: 'Todolist',
        tool: 'Hulpmiddel',
        toolDeleted: 'Hulpmiddel verwijderd',
        tools: 'Hulpmiddelen',
        Tooltip: 'Tooltip',
        toPlannings: 'Toon planning',
        total: 'Totaal',
        totalAfterANumber: 'totaal',
        totalNumberOfEvaluations: 'Totaal aantal evaluaties',
        TotalNumberOfHours: 'Totaal aantal uur',
        training: 'Scholing',
        trainingDeleted: 'Scholing verwijderd',
        trainingInformation: 'Opleidingsinformatie',
        trainingonthejob: 'Training on the job',
        trainingsStarted: 'gestart',
        trainingType: 'Type opleiding',
        transferEnrollment: 'Overschrijven',
        transferInsufficientRights: 'Je hebt niet genoeg rechten om over te schrijven',
        true: 'ja',
        toj: 'Training on the job',
        trainings: 'Opleidingen',
        translate: 'Vertalen',
        types: 'Typen',
        Uncouple: 'Ontkoppelen',
        UncoupleEmployee: 'Medewerker ontkoppelen',
        Underline: 'Onderstrepen',
        underlyingDepartments: 'Onderliggende afdelingen',
        Undo: 'Ongedaan maken',
        unenroll: 'uitschrijven',
        unenrollment: 'Uitschrijfbevestiging',
        unenrollmentDeadline: 'Uitschrijftermijn',
        unenrollmentDeadlineInDays: 'Uitschrijftermijn in dagen',
        'Unordered List': 'Opsommingsteken',
        units: {
            size: ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        },
        uniqueUsers: 'Unieke gebruikers',
        unknown: 'Onbekend',
        Unknown: 'Onbekend',
        unKnownOn: 'Onbekend op',
        unlockAccount: 'Ontgrendel account',
        unregister: 'Uitschrijven',
        unRegisterInsufficientRights: 'Je hebt niet genoeg rechten om uit te schrijven.',
        unregisterOrTansferOptions: `{ option, select,
            myUnregister {Je kunt je niet uitschrijven omdat jouw presentie / resultaat al is verwerkt.}
            myTransfer {Je kunt je niet overschrijven omdat jouw presentie / resultaat al is verwerkt.}
            someoneElseUnregister {Deze medewerker kan niet worden uitgeschreven omdat de presentie / het resultaat al is verwerkt.}
            someoneElseTransfer {Deze medewerker kan niet worden overgeschreven omdat de presentie / het resultaat al is verwerkt.}
            other {{option}}
        }`,
        unRegisterPeriodExceeded: 'Het is niet meer mogelijk je uit te schrijven.',
        unRegisterPeriodExceededTitle: 'Uitschrijftermijn verstreken',
        unsupportedBrowserHeader: 'Verouderde browser',
        unsupportedBrowserMessage:
            'U gebruikt een browser die wij niet meer ondersteunen. Update uw browser voor een betere ervaring!',
        until: 'tot',
        untilTime: 'Tot en met',
        up: 'Omhoog',
        upcomingTrainings: 'Aankomende opleidingen',
        UpdateValidityForExistingAssignments:
            'Pas geldigheid ook aan voor eerdere gemaakte toewijzingen',
        upload: 'Upload',
        uploadAutomatically:
            'Bijlagen in de wachtrij worden automatisch geupload bij het opslaan van dit dossieritem.',
        uploadCertificateBackgroundImage1: 'Achtergrondafbeelding',
        uploadCertificateBackgroundImage2:
            'De afbeelding moet een afmeting van 1400 bij 1000 of 1000 bij 1400 pixels hebben.',
        uploading: {
            status: {
                connecting: 'Verbinden...',
                stalled: 'Vastgelopen.',
                processing: 'Bestand verwerken...',
                held: 'Wachten',
            },
        },
        unableToRemoveAccreditation: 'Het verwijderen van de accreditatie is niet gelukt',
        unregistered: 'Uitgeschreven',
        Unregistered: 'Uitgeschreven',
        unregisteredParticipants: 'Uitgeschreven deelnemers',
        updateAttendance: 'Presentie bijwerken',
        url: 'URL',
        URL: 'URL',
        usageReport: 'Gebruiksrapportage',
        'usageReport.subtitle': 'E-learnings & klassikale opleidingen',
        'usageReport.generate': 'Genereer rapportage',
        'usageReport.usageUniqueCoursesUsers':
            'Aantal unieke gebruikers dat zich heeft ingeschreven',
        'usageReport.usageUniqueUsers':
            'Aantal unieke gebruikers dat deze e-learning gestart heeft',
        'usageReport.activeUsersPeriod': 'Actieve gebruikers in geselecteerde periode',
        'usageReport.elearningDetails.user': 'Gebruiker',
        'usageReport.elearningDetails.searchUser': 'Gebruiker zoeken',
        'usageReport.elearningDetails.status': 'Status',
        'usageReport.info.usersStartedBetween': 'Gebruikers gestart tussen {selectedDates}',
        useCustomValue: 'Eigen waarde gebruiken',
        useDefault: 'Standaardwaarde',
        useExaminerFunctionality: 'Toetsfunctionaliteit gebruiken',
        UseOnlyDefaultTemplates: 'Gebruik standaardsjabloon voor alle communicatie',
        user: 'Gebruiker',
        'user.course.result.validUntil': 'Geldig tot',
        uservoiceIcon: 'Geef je verbetersuggestie door',
        userInfo: 'Gebruikers informatie',
        userGroupDeleted: 'Gebruiker/groep verwijderd',
        userProfile: 'Gebruikersprofiel',
        userManagement: 'Gebruikersbeheer',
        usersName: 'Gebruikersnaam',
        users: 'Gebruikers',
        vacantPlace: 'plek vrij',
        vacantPlaces: 'plekken vrij',
        valid: 'Geldig',
        validity: 'Geldigheid',
        validityPeriodInMonths: 'Geldigheidsduur in maanden',
        validUntil: 'Geldig tot',
        Version: 'Versie',
        version: 'versie',
        refresher: 'Vervolg',
        viewComment: 'Commentaar inzien',
        viewPlanning: 'Rooster bekijken',
        viewPreconditions: 'Bekijk randvoorwaarden',
        viewSkill: 'Bekijk bekwaamheid',
        viewTheClassActivitiesForWhichYouAreRegistered:
            'Bekijk de klassikale activiteiten waarvoor je bent ingeschreven',
        viewYourScheduledLessons: 'Bekijk jouw ingeroosterde lessen',
        virtualQueue: 'Interesselijst',
        week: 'Week',
        weekAbout: '{number} week',
        weekAgo: '{number} week geleden',
        weekOverview: 'Weekoverzicht',
        weekOverviewReport: 'Weekoverzicht rapport',
        weeks: 'Weken',
        weeksAbout: '{number} weken',
        weeksAgo: '{number} weken geleden',
        welcomeToTCGMinerva: 'Welkom bij de leeromgeving',
        whatAreYouSearchingFor: 'Waar ben je naar op zoek?',
        when: 'Wanneer',
        WhoDoYouWantToRegister: 'Inschrijven',
        width: 'Breedte',
        withdrawTheApplication: 'Aanvraag intrekken',
        WithCertificate: 'Met certificaat',
        WithoutCertificate: 'Zonder certificaat',
        workLearningMethods: 'Werk- & Leervormen',
        workPhone: 'Telefoon werk',
        writtentest: 'Schriftelijke toets',
        year: 'Jaar',
        years: 'Jaar',
        yearAbout: '{number} jaar',
        yearsAbout: '{number} jaren',
        yearAgo: '{number} jaar geleden',
        yearsAgo: '{number} jaren geleden',
        yes: 'Ja',
        youAreAlreadyRegistered: 'U bent al ingeschreven',
        youAreNotAuthorizedToViewThisInformation:
            'U bent niet bevoegd om deze informatie te bekijken.',
        youCurrentlyHaveNoLesson: 'Je hebt momenteel geen les',
        YouDoNotHaveAddedFavorites: 'Je hebt geen favorieten toegevoegd.',
        youHaveMadeChangesDoYouWantToContinueWithoutSavingIt:
            'U heeft wijzigen gemaakt. Wilt u doorgaan zonder deze op te slaan?',
        youHaveNoLessonInTheNextSelectedDays: 'U heeft geen les in de komende geselecteerde dagen',
        youMustAddRecipients: 'U moet ontvangers toevoegen',
        YourDepartments: 'Jouw afdelingen',
        YourFavoriteCourses: 'Uw favoriete cursussen',
        YouAreSignedInAsAndDoesNotHaveRightsOn:
            'Je bent ingelogd als {name} en hebt met dit account geen rechten om {portal} te betreden',
        youWillBeAssessedBy: 'Je wordt beoordeeld door',
        yourProgress: 'Jouw voortgang',
        viewYourProgress: 'Bekijk jouw voortgang',
        zipCode: 'Postcode',
        showChildDepartmentsWhenNotManager:
            'Toon ook onderliggende afdelingen aan managers van een bovenliggende afdeling',
        'ssoManagement.mainTitle': 'Gebruiker zoeken',
        'ssoManagement.pageInfo': 'SSO Beheer PageInfo',
        'ssoManagement.listHeading': 'SSO Lijst',
        'ssoManagement.addSsoUser': 'Nieuwe SSO gebruiker',
        'ssoManagement.updateSsoUser': 'SSO gebruiker bewerken',
        'ssoManagement.enableDisableLabel': 'Activeren/Deactiveren',
        'ssoManagement.lockedOut': 'Geblokkeerd',
        'ssoManagement.disabledSince': 'Gedeactiveerd per',

        'ssoManagement.logOnName': 'Gebruikersnaam',
        'ssoManagement.logOnAlias': 'Alias',
        'ssoManagement.displayName': 'Weergavenaam',
        'ssoManagement.description': 'Omschrijving',
        'ssoManagement.emailAddress': 'Email Adres',

        'ssoManagement.password': 'Wachtwoord',
        'ssoManagement.resetPassword': 'Reset Wachtwoord',

        'ssoManagement.filter.enabled': 'Geactiveerd',
        'ssoManagement.filter.disabled': 'Gedeactiveerd',
        'ssoManagement.filter.lockedOut': 'Geblokkeerd',

        'ssoManagement.tab.users': 'Gebruikers',
        'ssoManagement.tab.groups': 'Groepen',

        'ssoManagement.actions.changePassword': 'Wachtwoord wijzigen',
        'ssoManagement.actions.generatePassword': 'Wachtwoord Genereren',
        'ssoManagement.actions.sendResetLink': 'Reset link verzenden',
        'ssoManagement.actions.unlock': 'Deblokkeren',
        'ssoManagement.actions.enableDisable': `{ disabled, select,
            true {Activeren}
            false {Deactiveren}
            undefined {Deactiveren}
            other {{disabled}}
        }`,

        'ssoManagement.errors.mustBeUnique': `De gebruikers naam en alias moeten verschillend zijn!`,
        'ssoManagement.errors.alreadyInUse': `Deze gebruikersnaam is al in gebruik!`,

        'ssoGroupManagement.groupDetails': 'Groep details',
        'ssoGroupManagement.mainTitle': 'SSO Groep Beheer',
        'ssoGroupManagement.pageInfo': 'SSO Groep Beheer PageInfo',
        'ssoGroupManagement.listHeading': 'SSO Groep Lijst',
        'ssoGroupManagement.addSsoGroup': 'SSO Groep Aanmaken',
        'ssoGroupManagement.updateSsoGroup': 'SSO Groep Bewerken',
        'ssoGroupManagement.updateSsoGroupMembers': 'SSO Group Members Bewerken',
        'ssoGroupManagement.memberOf': 'Lid van',

        tags: 'Tags',
        labels: 'Labels',
        addLabel: 'Label toevoegen',
        noLabels: 'Geen Labels',

        Supplier: 'Eigenaar',

        supplierTags: 'Leverancier Tags',
        addTag: 'Tag toevoegen',
        noTags: 'Geen Tags',
        noWPL: 'Je bent nog niet aan een werk- of leervorm gekoppeld',

        'scormManagement.mainTitle': 'SCORM Beheer',
        'scormManagement.pageInfo': 'SCORM Beheer PageInfo',
        'scormManagement.listHeading': 'SCORM Lijst',
        'scormManagement.uploadScormPackage': 'Upload Scorm 1.2 (.zip) Pakket',

        'scormManagement.filter.zipped': 'Zipped',
        'scormManagement.filter.unzipped': 'Unzipped',

        'scormManagement.actions.upload': 'Uploaden',
        'scormManagement.actions.delete': 'Verwijderen',

        'tcgLearningPlan.resetDialogTitle': 'E-learning opnieuw maken?',
        'tcgLearningPlan.resetDialogBody':
            'Weet je zeker dat je deze e-learning opnieuw wilt maken? Al jouw huidige voortgang gaat dan verloren. Het heeft geen invloed op eerder behaalde resultaten.',
        'tcgLearningPlan.somethingWentWrong': 'Er is helaas iets misgegaan',
        'tcgLearningPlan.retryProcessProgress': 'Probeer opnieuw om de voortgang te verwerken.',
        'tcgLearningPlan.resetIfNotWorking':
            'Lukt het niet? Reset dan de opleiding en begin opnieuw.',
        'tcgLearningPlan.handInDialogTitle': 'Inleveren?',
        'tcgLearningPlan.handInDialogText':
            'Weet u zeker dat u {title} wilt inleveren? Na inleveren kunt u geen wijzigingen meer aanbrengen!',
        'tcgLearningPlan.handIn': 'Inleveren',
        'tcgLearningPlan.skip': 'Overslaan',
        'tcgLearningPlan.completed': 'Afgerond',
        'tcgLearningPlan.open': 'Openen',
        'tcgLearningPlan.notAvailableYet': 'Nog niet beschikbaar',
    },
    en: {
        aboutToExpire: 'About to expire',
        absentOn: 'Absent on',
        accept: 'Accept',
        Accept: 'Accept',
        acceptAuthSingle: 'You are about to accept {number} request',
        acceptAuthPlural: 'You are about to accept {number} requests',
        acceptance: 'acceptance',
        Accepted: 'Accepted',
        acceptedSelection: 'Selection accepted!',
        acceptedPartialSelection: 'Selection partially accepted!',
        accreditation: 'Accreditation',
        'accreditation.deleteDialog.body':
            'Are you sure you want to try the accreditation attempt again? This process will be carried out overnight.',
        'accreditation.deleteDialog.title': 'Confirm retry attempt',
        'accreditation.deleteDialog.confirmButton': 'Try Again',
        'accreditation.status': `{status, select,
            accepted {Accepted}
            failed {Failed}
            badResponse {Bad response}
            error {Error}
            pending {Pending}
            notValid {Not valid}
            other {No status}
        }`,
        'accreditation.process': 'Process',
        'accreditation.processed': 'Processed',
        'accreditation.endDate': 'Completed op',
        'accreditation.info': 'Accreditation settings',
        'accreditation.processSuccess': 'Accreditations haven been processed',
        'accreditation.processTitle': 'Process accreditations',
        'accreditation.processText':
            'All the results of this course will be processed at once. This could take some time.',
        'accreditation.processDoYouWantToContinue': 'Do you want to continue processing?',
        accreditationDeleted: 'Accreditation deleted',
        accreditations: 'Accreditations',
        achieved: 'Achieved',
        AchievedResults: 'Achieved results',
        achievedOn: 'Achieved on',
        Achievement: 'Achievement',
        AchievementIsAboutToExpire: 'Your result expires soon!',
        AchievementIsExpired: 'Your result expired!',
        AchievementIsFailed: 'You have failed',
        AchievementIsMandatory: 'Your result is mandatory!',
        AchievementIsPassed: 'Your result completed / passed',
        achievementRead: 'I have read the manual/protocol',
        achievementStatus: `{status, select,
            aborted {Aborted}
            absent {Absent}
            canceled {Cancelled}
            completed {Completed}
            deleted {Deleted}
            expired {Expired}
            failed {Failed}
            faulted {Error}
            not_registered {Not registered}
            not_started {Not started}
            passed {Completed}
            started {Started}
            registered {Registered}
            unregistered {Unregistered}
            other {Unknown}
        }`,
        achievementStatusForUserCourseResults: `{status, select,
            aborted {Aborted}
            absent {Absent}
            canceled {Cancelled}
            completed {Completed}
            deleted {Deleted}
            expired {Completed (expired)}
            failed {Failed}
            faulted {Error}
            not_registered {Not registered}
            not_started {Not started}
            passed {Completed}
            started {Started}
            registered {Registered}
            unregistered {Unregistered}
            other {Unknown}
        }`,
        activate: 'Activate',
        ActivateELearning: '(De)activate e-learning',
        active: 'Active',
        Active: 'Active',
        activities: 'Activities',
        activity: 'Activity',
        activityName: 'Activity name',
        add: 'add',
        Add: 'Add',
        addAccreditation: 'Add accreditation',
        AddAFavoriteFirst: 'Reset the filter and choose your first favorite item!',
        addAnActivity: 'Add an activity',
        addAnswer: 'Add answer',
        addAssessment: 'Add assessment',
        addAssessors: 'Add users who you want to want to be able to assess other users',
        addAssets: 'Add assets',
        addAttachments: 'Add attachment(s)',
        addBlock: 'Add block',
        addCategory: 'Add category',
        addComment: 'Add comment',
        addCourse: 'Add classical course',
        addEmployee: 'Add employee',
        addExemption: 'Add exemption',
        addExternalCatalogItem: 'Add external content',
        addFiles: {
            one: 'Select file...',
            many: 'Select files...',
        },
        Additional: 'Additional',
        addMailTemplate: 'Add mail template',
        addForm: 'Add form',
        addGroup: 'Add group',
        addIndividuals: 'Add individuals',
        addNew: 'Add new',
        AddNewsItem: 'Add news item',
        addNewPrice: 'Add new price',
        addOption: 'Add option',
        addPlanners: 'Add users who you want to give planner permission to departments',
        addPlanning: 'Add planning',
        AddPortalGroup: 'Add department',
        addQuestion: 'Add question',
        address: 'Address',
        addressGroup: 'Address',
        addReservations: 'Add reservations',
        addReviewers: 'Add users who you want to give read only permission to departments',
        addSchedule: 'Add session',
        addTeacher: 'Add teacher',
        addSubcategory: 'Add subcategorie',
        AddTextAndPressEnter: 'Add a text and press ENTER',
        addTextField: 'Add text field',
        addTheorem: 'Add theorem',
        addToVirtualQueue: `I''m interested in this training`,
        addToVirtualQueueDialogHeader: 'Add to virtual queue?',
        addToVirtualQueueDialogMessage:
            'This will add you to the virtual queue for this training. Based on the amount of people showing interest in this training future sessions might be scheduled.',
        addTraining: 'Add training',
        addUser: 'Add user',
        addUsers: 'Add users',
        administration: 'Administration',
        administrators: 'Administrators',
        administratorsAdd: 'Add administrators',
        advised: 'Advised',
        agree: 'Agree',
        all: 'All',
        allAssignmentsAreDeleted: 'All assignments are deleted',
        allMandatoryFieldsMustBeFilled: 'All mandatory fields must be filled',
        AlmostExpired: 'Almost Expired',
        allDepartments: 'All departments',
        Allignment: 'Alignment',
        allowedToFollow: 'Allowed to follow',
        allowMandatoryAssignments: 'Mandatory assignments',
        allowMandatoryAssignmentsDescription:
            'Enable or disable mandatory assigning of catalogitems application-wide',
        allowNonExistingCombinations: 'Allow non existing combinations',
        allowOnlyYourOwnNames: 'Allow only your own names',
        allowQueue: 'Queue',
        AllowEnroll: 'Allow enroll',
        AllowUnenroll: 'Allow unenroll',
        allowVirtualQueue: 'Watchlist',
        'allowVirtualQueue.explainer': 'Users can express their interest if no courses are planned',
        allQuestionsAreMandatory: 'All questions are mandatory',
        allTypes: 'All',
        always: 'Always',
        answerText: 'Answer text',
        applicationParts: 'Application parts',
        apply: 'Apply',
        appointment: 'appointment',
        appointmentAt: 'Appointment at',
        allPresent: 'All present',
        approved: 'Approved',
        approvedDate: 'Approved date',
        aProblemHasOccuredContactTheAdministrator:
            'A problem has occured. Contact the administrator',
        archive: 'Archive',
        AreYouSureYouWantToDeactivateThisPackage:
            'Are you sure you want to deactivate this package?',
        AreYouSureYouWantToDeleteThisPackage: 'Are you sure you want to delete this package?',
        areYouSureYouWantToDelete_what_: `Are you sure you want to delete {what, select,
            role {this role}
            other {''{what}''}
        }?`,
        areYouSureYouWantToDeleteAllAssignmentsForThisAssessor:
            'Are you sure you want to delete all assignments for this assessor?',
        areYouSureYouWantToDeleteThisAssessment: 'Are you sure you want to delete this assessment?',
        areYouSureYouWantToDeleteThisBlock: 'Are you sure you want to delete this block',
        areYouSureYouWantToDeleteThisCategory: 'Are you sure you want to delete this category',
        areYouSureYouWantToDeleteThisComment: 'Are you sure you want to delete this comment?',
        areYouSureYouWantToDeleteThisConnection: 'Are you sure you want to delete this connection',
        areYouSureYouWantToDeleteThisForm: 'Are you sure you want to delete this form?',
        areYouSureYouWantToDeleteThisGroup: 'Are you sure you want to delete this group',
        areYouSureYouWantToDeleteThisIndividualSkill:
            'Are you sure you want to delete this individual skill',
        areYouSureYouWantToDeleteThisMessage: 'Are you sure you want to delete this message',
        areYouSureYouWantToDeleteThisQuestion: 'Are you sure you want to delete this question',
        areYouSureYouWantToDeleteThisSection: 'Are you sure you want to delete this section',
        areYouSureYouWantToDeleteThisSkill: 'Are you sure you want to delete this skill',
        areYouSureYouWantToDeleteThisTextField: 'Are you sure you want to delete this text field',
        areYouSureYouWantToDisconnectThisEmployee:
            'Are you sure you want to disconnect this emeployee?',
        areYouSureYouWantToUnsubscribe: 'Are you sure you want to unsubscribe ?',
        are_you_sure_you_want_to_delete_this_accreditation:
            'Are you sure you want to delete this accreditation?',
        are_you_sure_you_want_to_delete_this_location:
            'Are you sure you want to delete this location?',
        are_you_sure_you_want_to_delete_this_schedule:
            'Are you sure you want to delete this session?',
        are_you_sure_you_want_to_delete_this_tool: 'Are you sure you want to delete this tool?',
        are_you_sure_you_want_to_remove_this_user_from_list:
            'Are you sure you want to remove this user from the list?',
        areYouSureYouWantToUnregister: 'Are you sure you want to unregister?',
        areYouSureYouWantToUnregisterThisStudent:
            'Are you sure you want to unregister this student?',
        askPermission: 'Ask permission',
        assessedOn: 'Assessment date',
        assessEmployees: 'Assess employees',
        assessment: 'Assessment',
        assessmentName: 'Assessment form name',
        assessmentDescription: 'Assessment description',
        assessmentForm: 'Assessment form',
        assessmentsForms: 'Assessment forms',
        assessmentResult: 'Assessment result',
        assessments: 'Assessments',
        assessmentIncomplete: 'Assessment is incomplete',
        assessmentMustContainAtLeastOneSection: 'Assessment must contain at least one section',
        assessmentSectionsMustContainAtLeastOneQuestion:
            'Assessment sections must contain at least one question',
        assessmentQuestionMustHaveYesNoOptions: 'Question must have yes/no as options',
        assessor: 'Assessor',
        assessors: 'Assessors',
        assessorsManagement: 'Assessors management',
        assessorsView: 'Assessors view',
        examinerWithName: 'Examiner: {name}',
        examinerAppointments: 'Examiner appointments',
        examinerAppointmentsOverview: 'List of examiner appointments',
        examinerFirstAvailableTimeSlot: 'First available timeslot',
        examinermanagement: 'Examiner management',
        examinerNoLocation: 'No location found!',
        assign: 'Assign',
        'assignMatrix.title': `{type, select,
            department {Entire department}
            group {User / group}
            user {User}
            other {{type}}
        }`,
        'assignMatrix.department.explainer': 'Regardless of function',
        assignCatalogItems: 'Assign catalog items',
        assignCatalogItemsBulk: 'Which catalog items do you want to assign?',
        assignCatalogItemsBulkChooseDomain: 'Choose a domain',
        assignCatalogItemsBulkChooseFunctions: 'And to which functions?',
        assignCatalogItemsBulkMandatory: 'Make mandatory?',
        assignCatalogItemsBulkToDomainOrDepartment: 'Assign to domain or department / function?',
        assignCatalogItemsBulkSelectAllFunctions: 'Select all functions',
        assignCatalogItemsBulkWithDepartments: 'To which departments do you want to assign?',
        AssignCertificateToSelectedCatalogItems: 'Assign certificate to selected catalog items',
        assignedTrainings: 'Assigned trainings',
        assignEmployee: 'Assign employee',
        assignEmployees: 'Assign employees',
        assignedIndividual: 'Assigned individual',
        assignedToFunction: 'Assigned to function',
        assignIndividual: 'Assign individual',
        assignIndividuals: 'Assign individuals',
        assignManagers: 'Assign managers',
        assignments: 'Assignments',
        assignmentsIndividual: 'Individual assignments',
        assignedTo: 'Assigned to',
        assignToDepartmentFunction: 'Assign to department / function',
        asSoonAsAPlaceBecomesAvailableTheNextParticipantWillBeRegisteredAutomatically:
            'When maximum participants is reached a user can enroll on a queue and will be enrolled automatically when a seat becomes available.',
        asSoonAsSpaceBecomesAvailableTheEntireQueueWillReceiveAnInvitation:
            'As soon as space becomes available, the entire queue will receive an invitation',
        at: 'at',
        atLeastOneFunctionMustBeSelected: 'At least one function must be selected.',
        atLeastOneBlockMustAdded: 'At least one block must be added.',
        atLeastOneActivityMustAdded: `At least one item must be added to the block. In the case of Basic with Refresher, at least one activity must be added for both Basic and Refresher`,
        attachedCertificate: 'Attached certificate',
        attachments: 'Attachment(s)',
        attachedUsers: 'Attached employees',
        attendanceList: 'Attendance list',
        attendees: 'Attendees',
        askAuthorizationToManager:
            'Permission required to start this elearning, would you like to ask permission?',
        authorizationNeededByManager: 'Authorization needed by manager',
        authorizationRequest: `{ number, select,
            1 {Review a request}
            other {Review {number} requests}
        }`,
        AuthorizationRequests: 'Authorization requests',
        AvailableLabels: 'Available labels',
        AvailableVersions: 'Available versions',
        beginDate: 'Start date',
        beginTime: 'Start time',
        back: 'back',
        Back: 'Back',
        basic: 'Basic',
        belowYouCanSeeThePlanningOfYourLessons: 'Planning (if applicable) and user remark:',
        belowYouSeeTheListWhereYouNeedToUpdateThePresence:
            'Below you see the list where you need to update the presence',
        belowYouSeeThePlanningOfTheLessonsForWhichYouAreRegistered:
            'Below you see the planning of the lessons for which you are registered',
        betaHeadline: 'Want to try out the new look & feel?',
        bhpStatusEnrolled: 'Enrolled',
        bhpStatusAchieved: 'Achieved on',
        bhpValidity: 'Validity',
        block: 'Block',
        blockName: 'Block name',
        Bold: 'Vet',
        both: 'Both',
        bulkAssignmentsSaved: 'Success, assignments processed',
        buy: 'Buy',
        by: 'By',
        calendar: 'Calendar',
        calculateSupportColors: 'Automatically calculate support colors',
        cancel: 'Cancel',
        cancelled: 'Cancelled',
        Cancelled: 'Cancelled',
        cancelPendingRequest: 'Would you like to revoke your pending request?',
        cancelSchedule: 'Cancel schedule',
        canTest: 'Can test',
        capacity: 'Capacity',
        capacityReached: 'Schedule is full',
        catalog: 'Catalog',
        'catalog.fab.course': 'Add course',
        'catalog.fab.elearning': '(De)activate elearning',
        'catalog.participants.heading.lastAction': 'Last action',
        'catalog.participants.filter.action': 'Action',
        'catalog.participants.filter.name': 'Name employee',
        'catalog.participants.filter.dateFrom': 'Date from',
        'catalog.participants.filter.dateUntil': 'Date until',
        'catalog.participants.heading.result': 'Result',
        'catalog.participants.heading.bestResult': 'Best result',
        'catalog.participants.heading.expirationDate': 'Expiration date',
        'catalog.participants.heading.currentStatus': 'Current status',
        'catalog.participants.heading.date': 'Date',
        catalogAuthorizationRequests: 'Catalogus authorization requests',
        catalogAssignmentWarning:
            'All selected catalog items will be assigned based on the choices made.',
        catalogAssignmentWarning2: 'This can only be undone manually.',
        CatalogInterests: 'Watchlist',
        CatalogInterestsDetails: 'Catalogitems on your watchlist',
        catalogItem: 'Catalog item',
        catalogItemCompleted: ' - last completed on: ',
        catalogItemAboutToExpire: 'Catalog item expires soon!',
        catalogItemExpired: 'Catalog item expired!',
        catalogItemMandatory: 'Catalog item is mandatory!',
        catalogItemPassed: 'Catalog item completed / passed',
        'catalogItem.status': `{status, select,
            disabled {[DISABLED]}
            deleted {[DELETED]}
            other {}
        }`,
        'catalogItem.replaces': 'This course replaces the following other courses',
        'catalogItem.replacedBy': 'This course is replaced by the following course',
        'catalogEdit.general': 'Catalogitem general',
        categories: 'Categories',
        categoriesNotFound: 'No available categories found',
        category: 'Category',
        categoryAdd: 'Add category',
        categoryDelete: 'Categorie delete',
        categoryDeleteWarning: 'Are you sure you want to delete this category?',
        categoryDeleted: 'Category deleted',
        categoryEdit: 'Edit category',
        categoryName: 'Category name',
        Center: 'Center',
        'cert-all': 'All',
        'cert-yes': 'All with certificate',
        'cert-no': 'All without certificate',
        certificate: 'Certificate',
        certificates: 'Certificates',
        certificateApply1: `The certificate ''{certificaat}'' `,
        certificateApply2: 'will be applied to {number} catalog item(s).',
        certificateAssign: 'Assign certificates',
        certificateDefinition: 'Certificate definition',
        certificateDefinitions: 'Certificates',
        certificateDefinitions2: 'Certificate definitions',
        certificateDeleteConfirm: 'Are you sure you want to remove this certificate definition?',
        certificateDeleteWarning: 'This definition is used {number} times in catalog items!',
        certificateHide: 'Hide items with certificate:',
        certificateLabelDeleteHeader: 'Delete selected label?',
        certificateLabelDelete: 'Do you want to delete this label?',
        certificateLabelNew: 'New empty label',
        certificateLabelUsername: 'Label: username',
        certificateLabelBirthdate: 'Label: birthdate',
        certificateLabelCoursename: 'Label: course name',
        certificateLabelResultdate: 'Label: result date',
        certificateLabelPoints: 'Label: score',
        certificateShow: 'Show items with certificate:',
        changeCategory: 'Change category',
        changeComment: 'Change comment',
        changed: 'changed',
        changeOrder: 'Change order',
        changePassword: 'Change password',
        changeProfile: 'Edit profile',
        changeYourPassword: 'Change your password',
        changeRegistration: 'Change registration',
        changeSsoAccount: 'Edit account',
        checkAppointments: 'Existing appointments are not deleted!',
        checkPortfolioItems: 'You have been asked to check one dossier item',
        checkPortfolioItemsPlural: 'You have been asked to check {number} dossier items',
        chosenDate: 'Chosen date',
        chosenTraining: 'Chosen training',
        chooseAnEvaluationForm: 'Choose an evaluation form',
        chooseAnExaminer: 'Choose an examiner',
        chooseATimeslot: 'Choose a timeslot',
        chooseASkillThatYouWantToLinkToTheFunctions:
            'Choose a skill that you want to link to this role.',
        chooseASkillThatYouWantToLinkToThisDepartment:
            'Choose a skill that you want to link to this department.',
        chooseCertificate: 'Choose certificate',
        chooseDateAndRegion: 'Choose date and region',
        chooseDeleteToPreventThisUserFromReviewingAnySkill: `Choose ''DELETE'' to prevent this user from reviewing any skill.`,
        chooseFunctions: 'choose functions',
        chooseImportToMakeThisUsersAnAssessorForTheSameActivitiesAsAnotherUser: `Choose ''IMPORT'' to make this user an assessor for the same activities as another user.`,
        choosePortfolioDefinition: 'Choose a dossier item definition',
        ChooseWhichRolesYouWantToUncouple: 'Choose which roles you want to decouple',
        city: 'City',
        class: 'Class',
        classDeleted: 'Class deleted',
        classes: 'Classes',
        classical: 'Classical',
        Classical: 'Classical',
        classicalTheoryLesson: 'Classical theory lesson',
        classroom: 'Classical',
        Classroom: 'Classical',
        'classroom.conflictMessage.maxCapacityLowerThanMinCapacity':
            'Max capacity may not be lower than min capacity',
        'classroom.teacheroverviewfilter.all': 'All',
        'classroom.teacheroverviewfilter.active': 'Active',
        'classroom.teacheroverviewfilter.cancelled': 'Cancelled',
        ClassroomActivities: 'Classroom activities',
        ClassroomActivity: 'Classroom activity',
        ClassroomCancel: 'Cancel classical Lesson',
        ClassroomDequeue: 'Remove from queue',
        ClassroomEnroll: 'Enroll classical lesson',
        ClassroomEnqueue: 'Enrolll on queue',
        ClassroomExaminerActivity: 'Classroom and examiner activity',
        ClassroomUnenroll: 'Unenroll from classical lesson',
        classroomTraining: 'Classroom training',
        classroomTypes: 'Classroom',
        clear: 'Clear',
        ClearImage: 'Clear image',
        ClearList: 'Clear list',
        clickOnRegisterToRegisterYourself: `Click on ''ENROLL'' to register yourself.`,
        close: 'Close',
        closeDown: 'Sluiten',
        closeRegistration: 'Close registration',
        code: 'code',
        Code: 'Code',
        combineSkillExams: 'Combine exams',
        colleague: 'Colleague',
        color: 'Color',
        comment: 'Comment',
        commentDate: 'Comment date',
        comments: 'Comments',
        communication: 'Communication',
        compatibility: 'Compatibility',
        complete: 'Complete',
        completed: 'Completed',
        Completed: 'Completed',
        completedStatus: 'Completed',
        completionDate: 'Completion date',
        'components.acaDepartmentSearch.label': 'Search for (sub)department',
        composition: 'e-Learning',
        compulsory: 'Compulsory',
        compulsoryNumber: 'Compulsory number',
        confirm: 'Confirm',
        confirmDelete: `Are you sure you want to delete ''{name}''?`,
        ConfirmNewPassword: 'Confirm new password',
        ConfirmUnenroll: 'Are you sure you want to unenroll?',
        ConflictingEnrollmentFound: 'Conflicting enrollment found',
        ConflictingSchedule: 'Unable to create schedule due to conflict with existing schedule',
        connect: 'Connect',
        connected: 'Connected',
        connectFunctions: 'Connect roles',
        connectSkill: 'Connect skill',
        contactGroup: 'Contact',
        contactInformation: 'Contact information',
        contentEndDate: 'This course can no longer be started from: {date}!',
        contentExpired: 'This course is expired and can no longer be started!',
        contents: 'Contents',
        continue: 'Continue',
        course: 'Classical course',
        courseHasBeenReplaced: 'This course has been replaced with',
        CourseProvider: 'Course provider',
        courseResetTitle: 'User can reset progress:',
        courseResetAllow: 'Always',
        courseResetDeny: 'Never',
        courseResetDefault: 'Depending on global setting',
        courses: 'Classical courses',
        courseSelected: 'course selected',
        Costs: 'Costs',
        created: 'Started',
        creationDate: 'Creation date',
        createNewSkill: 'Create new skill',
        createNewAssessment: 'Create new assessment',
        createNewForm: 'Create new form',
        current: 'Current',
        currentRegistrations: 'Current registrations',
        CurrentPassword: 'Current password',
        currentStartDates: 'Current start dates',
        currentStatus: 'Current status',
        dashboard: 'Dashboard',
        'dashboard.widgets.adminnotification.title': 'Weekplanning',
        'dashboard.widgets.adminnotification.subtitle': 'Monitor session issues...',
        'dashboard.widgets.notification.title': 'My messages',
        'dashboard.widgets.notification.subtitle': 'View unread messages...',
        dashboardLinks: 'Dashboard links',
        dashboardTitle: 'Dashboard welcome text',
        dashboardTile: 'This text will appear in the top section of the dashboard',
        'dashboard.widget.hl.traject': 'Mijn leertraject(en)',
        'dashboard.widget.hl.community': 'Community',
        date: 'Date',
        dateAndInfo: 'Date & info',
        DateAndTime: 'Date & time',
        dateAscending: 'Date ascending',
        dateDescending: 'Date descending',
        dateIsNotKnown: 'Date is not known',
        dateOfAssessment: 'Date of assessment',
        dateOfBirth: 'Date of birth',
        dateOfExpiry: 'Date of expiry',
        dateShownInterest: 'Shown interest on',
        dateStatusFrom: 'Status date from:',
        dateStatusTill: 'Status date till:',
        dateToExpire: 'It will expire in {number} months',
        day: 'Day',
        dayAbout: '{number} day',
        dayAgo: '{number} day ago',
        dayClassroomTraining: 'day classroom training',
        dayParts: 'lessons',
        days: 'Days',
        daysAbout: '{number} day',
        daysAgo: '{number} days ago',
        Deactivate: 'Deactivate',
        DeactivatePackage: 'Deactivate package',
        definitionAvailable: 'Available',
        definitionAllowMultiple: 'Multiple instances possible',
        definitionReviewByTeacher: 'Review by teacher',
        definitionReviewByManager: 'Review by manager',
        definitionAcceptByTeacher: 'Accept by teacher',
        definitionAcceptByManager: 'Accept by manager',
        delete: 'Delete',
        delete_what_: `Delete {what, select,
            role {role}
            other {item}
        }`,
        deleteAnswer: 'Delete answer',
        deleteAllAssignments: 'Delete all assignments',
        deleteAllAssignmentsForThisExaminer: 'Delete all assignments for this examiner',
        deleteApprovalDate: 'Reset base approval date',
        deleteAuthSingle: 'You are about remove {number} request',
        deleteAuthPlural: 'You are about remove {number} requests',
        deleteAssessment: 'Delete assessment',
        deleteAssessor: 'Delete assessor',
        deleteAssignments: 'Delete assignments',
        deleteCategory: 'Delete category',
        deleted: 'Deleted',
        deleteExistingAssignments: 'Delete existing assignments',
        deleteForm: 'Delete form',
        deleteConfirmationForAssignmentForCatalogItem: `Are you sure you want to delete the assignment for ''{catalogItemName}''?`,
        deleteConfirmationForAllCatalogAssignments: `Are you sure you want to remove all assignments from this catalog item? This means that this catalog item is no longer assigned to any department, function, group or user. No user can find this catalog item in the catalog (excluding administrator)`,
        deleteConfirmationForAssignmentForGroups: `Are you sure you want to delete the assignment for department/class ''{groupName}''?`,
        deleteConfirmationForAssignmentForUsers: `Are you sure you want to delete the assignment for user/group ''{displayName}''?`,
        deleteConfirmationForAdmin: `Are you sure you want to remove the user/group ''{displayName}'' from administrators?`,
        deleteConfirmationForAssessors: `Are you sure you want to remove the user/group ''{displayName}'' from assessors?`,
        deleteConfirmationForAssessorActivity: `Are you sure you want to remove the activity ''{displayName}'' from the assessor?`,
        deleteConfirmationForDepartmentReviewers: `Are you sure you want to remove the user/group ''{displayName}'' from department reviewers?`,
        deleteConfirmationForPlanners: `Are you sure you want to remove the user/group ''{displayName}'' from planners?`,
        deleteConfirmationForResult: `Are you sure you want to delete the result for ''{groupName}''?`,
        deleteBasicApprovalDate: `Are you sure you want to reset the basic approval date for ''{groupName}''?`,
        deleteGroup: 'Delete group',
        DeleteNewsItem: `Are you sure you want to remove news item ''{newsItemTitle}''?`,
        deleteConfirmationForLink: `Are you sure you want to delete this link ''{link}'' ?`,
        deleteOption: 'Delete option',
        DeletePackage: 'Delete package',
        DeletePortalGroup: `Are you sure you want to remove department ''{portalGroupName}''?`,
        deletePortfolio: 'Are you sure you want to delete this dossier item?',
        deletePortfolioDefinition: 'Are you sure you want to delete this dossier definition?',
        deletedPortfolioDefinition: 'Dossier definition deleted',
        deleteQuestion: 'Delete question',
        deleteSchedule: 'Delete session',
        deleteSection: 'Delete section',
        deleteSkill: 'Delete skill',
        deleteSkillAssignment: 'Delete skill assignment',
        deleteTextField: 'Delete text field',
        deleteMessage: 'Delete message',
        deleteUser: 'Delete user',
        department: 'Department',
        departmentDeleted: 'Department deleted',
        departmentNoActiveMembers: 'The selected department has no (active) users',
        departmentFunction: 'Department / function',
        departments: 'Departments',
        'departmentSaveMessage.added': 'Department saved successfully',
        'departmentSaveMessage.deleted': 'Department deleted successfully',
        'departmentSaveMessage.conflict': 'A department with the same name exist already',
        'departmentSaveMessage.generalError': 'A problem has occured. Contact the administrator',
        departmentsSelectAll: 'Select all departments',
        departmentReviewers: 'Department reviewers',
        dequeue: 'Dequeue',
        description: 'Description',
        DirectLink: 'Direct link',
        disable: 'Disable',
        disableAccount: 'Disable account',
        disabledAccount: 'Disabled account',
        disableUserAccountDialog: 'Disable account',
        disableUserAccountPerDate: 'Disable account per',
        disagree: 'Disagree',
        discard: 'Discard',
        discardChanges:
            'You have made changes and have not yet saved them. If you leave this screen now, the changes will be discarded. Do you want to discard changes?',
        discardChangesShort: 'Unsaved changes will be lost.',
        disconnectEmployee: 'Disconnect employee?',
        disconnectIndividual: 'Disconnect individual',
        displayFromDate: 'Display from',
        displayName: 'Full name',
        displayUntilDate: 'Display until',
        displaySettings: 'Display settings',
        documents: 'Documents',
        documentDeleted: 'Document deleted',
        documentAlwaysVisible: 'Document always visible',
        documentVisibleAfterRegistration: 'Document visible after enrollment',
        domain: 'Domain',
        done: 'Done',
        DoNotShowThisVideoAnymore: 'Do not show this video anymore',
        DoubleTeacher: 'Double teacher',
        DoubleLocation: 'Double location',
        down: 'Down',
        download: 'Download',
        downloadCertificate: 'Download certificate',
        downloadCertificates: 'Download certificates',
        downloadCertificatesNoAttendance: 'No participants have attended',
        downloadAttendanceList: 'Download attendance list',
        downloadAttendanceListDescription:
            'Select the additional information you would like to add to the report',
        dragAndDrop: 'Drag and drop',
        duplicate: 'Duplicate',
        duplicateAssessor: 'Duplicate assessor',
        duplicateBlock: 'Duplicate block',
        duplicatePlanning: 'Duplicate planning',
        duplicateQuestion: 'Duplicate question',
        duplicateTextField: 'Duplicate text field',
        duration: 'Duration',
        'duration.start': 'Duration start date',
        'duration.end': 'Duration end date',
        edit: 'Edit',
        editAccreditation: 'Edit accreditation',
        editApprovalDate: 'Edit base approval date',
        editAssessment: 'Edit assessment',
        editBlockTitle: 'Edit block title',
        editCatalog: 'Edit catalog',
        editCategory: 'Edit category',
        editCourse: 'Edit course',
        editEvaluationForm: 'Edit evaluation form',
        editForm: 'Edit form',
        editLink: 'Edit link',
        editLocation: 'Edit location',
        EditNewsItem: 'Edit news item',
        editOption: 'Edit option',
        editPlanning: 'Edit planning',
        editQuestion: 'Edit question',
        editSectionTitle: 'Edit section title',
        editSkill: 'Edit skill',
        editTool: 'Edit tool',
        elearningmanagement: 'E-learning management',
        elearning: 'E-learning',
        elearningReset: 'E learning reset',
        enrollDepartmentUsers: 'Enroll department members',
        Error404: 'Error 404',
        email: 'E-mail',
        emailaddress: 'E-mail address',
        emailNotification: 'Email Notification',
        emailPrivate: 'Private email',
        emailWork: 'Work email',
        employee: 'Employee',
        employeeAndRoleAreMandatory: 'Employee and role are mandatory',
        employeeNumber: 'Employee number (username)',
        employees: 'Employees',
        enableAccount: 'Enable Account',
        end: 'End',
        endDate: 'End date',
        endTime: 'End time',
        enqueue: 'Enqueue',
        enroll: 'Enroll',
        enrollAnotherEmployeeAfterProcessing: 'Enroll another employee after processing',
        enrollEmployees: 'Enroll department employees',
        enrollment: 'Enrollment',
        enrollmentFailed: 'Enrollment not possible',
        enrollmentSucceeded: 'Enrollment succeeded',
        Enrollments: 'Enrollments',
        enrollmentDeadline: 'Enrollment Deadline',
        errorEnrolling: 'Something went wrong with the enrollment, enrollment has been undone!',
        enrollmentDeadlineInDays: 'Enrollment deadline in days',
        enrollReservationError: `{ status, select,
            NotExists {Reservationcode does not exist}
            Expired {Reservationcode is expired}
            AlreadyUsed {Reservationcode has already been used}
            AlreadyEnrolled {This person is already enrolled}
            NoValidSchedule {This planning is invalid or in the past}
            other {{status}}
        }`,
        enterReviews: 'Enter reviews',
        entire: 'Entire',
        errorDownloadingFile: 'Error downloading file! Message:',
        errorMessageText: 'Warning message (to be used in case of outage)',
        errorMessageTextHeadline: 'Warning message',
        errorMessageTextSubtitle: 'Warning message in upper part of every page',
        errorNavigateToElearning: 'This e-learning cannot be started',
        evaluateTheCoursesThatYouHaveAccomplished:
            'Evaluate the courses that you have accomplished',
        evaluation: 'Evaluation',
        evaluationFormName: 'Evaluation form name',
        evaluations: 'Evaluations',
        evaluationForm: 'Evaluation Form',
        evaluationForms: 'Evaluation Forms',
        everyoneVisible: 'Everyone visible',
        examUpdated: 'Test results updated',
        examiner: 'Examiner',
        Examiner_not_found_in_activity: 'Examiner was not registered for this activity',
        examinerReview: 'Examiner review',
        examinerReviews: 'Examiner reviews',
        examinerReviewDescription: 'You have one pending examiner review',
        examinerReviewDescriptionPlural: 'You have {number} pending examiner reviews',
        examiners: 'Examiners',
        examinersActivityExamDuration: '',
        examinersCannotBeUsed:
            'Assessors are appointed to this skill. Therefore it is not possible to activate the examiner functionality. Remove the assessors first.',
        examinersUserCanSelectTimeSlot: 'User can choose timeslot',
        examinersUserCanSelectExaminer: 'User can choose examiner',
        examinersUserCanCombineExams: 'User can combine exams',
        examinersStandardDurationTimeSlot: 'Default duration timeslot in minutes',
        examinersStandardDurationExam: 'Default exam time',
        examinersRegionLocation: 'Region / location',
        examinersRegionsLocations: 'Regions / locations',
        examinersAddRegionLocation: 'Add region / location',
        examinersDeleteRegionLocation: 'Delete region / location "{location}"?',
        examinersAddAvailability: 'Add availability',
        examinersEditAvailability: 'Edit availability',
        examinersAddSchedule: 'Add a schedule',
        examinersScheduleDetails: 'Activities on {date} - {timeslot}',
        examinersNoReservations: 'No reservations',
        examinersHasReservations: 'Has reservations',
        examinersDeleteCurrentSchedule: 'Delete current schedule?',
        examinersPlannedExams: 'Planned exams',
        examinersAvailability: 'Availability',
        examinerScheduleMandatoryStartEndDate: 'Enter a start and end date',
        examinerScheduleSameStartEndTime: 'Start time and end time cannot be the same',
        examinerScheduleStartEndDateNotTheSame: 'Start and end date have to be the same',
        examinerScheduleEndDateBeforeStartDate: 'End date cannot be before start date',
        examinerScheduleNoRegion: 'Region is mandatory',
        examinerAvailableSpace:
            'You have appointments with extra room. Would you like to combine this appointment?',
        examinerValidResult:
            'You still have result that is valid until {date}. Continue planning a new appointment?',
        examinerCombineTest: 'combine appointment?',
        examinerCombineTestYes: 'Yes, combine appointment',
        examinerCombineTestNo: 'No, plan a new appointment',
        examinerDurationOfTheExam: 'Duration of the exam (minutes)',
        examinerNewAppointment: 'New appointment',
        examinerCombineToWhichAppointment: 'To which appointment would you like to add this one?',
        'examiner.appointment.activity': 'Combine activity: ',
        'examiner.appointment.combine': 'With the appointment below?',
        'examiner.appointment.examiner': 'Examiner: ',
        'examiner.appointment.dateTime': 'Date and time: ',
        'examiner.appointment.regionLocation': 'Region / location: ',
        examinerNoAppointmentsTitle: 'Planned appointments',
        examinerNoAppointmentsDescription: 'No planned appointments found',
        examinerDeleteAppointment: 'Delete appointment?',
        exceededRegistrationPeriod: 'It is no longer possible to enroll.',
        exceededUnRegistrationPeriod:
            'You want to unregister or transfer a participant but the un-register/transer period has been exceeded. "You can no longer un-register or transfer a participant. For more information contact the trainers."',
        exceededUnRegistrationPeriodSelf:
            'You want to unregister or to transfer your registration but the un-register/transer period has been exceeded. "You can no longer un-register. For more information contact the trainers."',
        exemption: 'Exemption',
        exemptionEdit: 'Grant exemption',
        exemptionsHaveBeenGranted:
            'Exemptions have been granted. Please note, it may take a few minutes before this is visible in the competence passport',
        expertiseDeleted: 'Dossier deleted',
        expertiseTodoTasks: 'You have the following tasks:',
        Expertises_accept: 'Accept dossier items',
        Expertises_authorize: 'Authorie dossier items',
        Expertises_review: 'Evaluate dossier items',
        Expertises_updated_status: 'Dossier tasks updated',
        export: 'Export',
        exportOptions: 'Export options',
        exportAssignments: 'Export assignments',
        exportAssignmentsToOtherUsers: 'Export assignments to other users',
        exportPDF: 'PDF export',
        exemptionUntil_date_: 'Exemption until {date}',
        exemptionValidTill: 'Exemption until',
        expertise: 'Dossier item',
        expirationPeriod: 'Expiration period',
        ExpirationPeriod: 'Expiration period',
        existingAssignments: 'Existing assignments',
        expirationDate: 'Expiration date',
        expires: 'Expires',
        expiresOn: 'Expires on',
        expiresOn_date_: 'Expires on {date}',
        expired: 'Expired',
        expiredOn_date_: 'Expired on {date}',
        explanationOrquestion: 'Explanation / question',
        extendExpirationDate: 'Extend expiration date',
        ExternalRegistrationNumber: '{name} number',
        ExtraOptions: 'Extra options',
        ExternalCost: 'External cost',
        failed: 'Failed',
        Failed: 'Failed',
        FailedAlreadyRegistered: 'Already registered',
        FailedAlreadyRegisteredOnThisPlanning: 'The person is already registered on this planning.',
        FailedCapacityReachedPlanningIsFull:
            'It is not possible to register the person on this planning, because the planning is full.',
        FailedConflictingEnrollmentOnOtherCourse: 'Already registered on a different course.',
        FailedMaxNumRegistrationsReachedOnCourse:
            'It is not possible to register the person on this planning, because the max registration is reached on this course.',
        false: 'no',
        favoriteTrainings: 'Favorites',
        FavoriteWidgetInfo: 'View your favorite catalog items',
        filterByDate: 'Filter by date',
        filterOnCatalogName: 'Filter on catalog name',
        filterOnCategory: 'Filter category',
        filterOnCategories: 'Filter on categories',
        filterOnCategoryOrSkill: 'Filter on category or skill',
        filterOnCluster: 'Filter cluster',
        filterOnDepartmentName: 'Filter on department name',
        filterOnFunction: 'Filter on function',
        filterOnName: 'Filter on name',
        filterOnDescription: 'Filter on description',
        filterOnSkill: 'Filter skill',
        filter: 'Filter',
        filters: 'Filters',
        findUnderlyingDepartment: 'Find underlying department',
        finished: 'finished',
        firstName: 'First name',
        fivePointLikertScale: '5-Point Likert Scale',
        Float: 'Float',
        font: 'Font',
        forbidden: 'Access denied',
        forbiddenDepartment: 'You are not allowed to view this department.',
        forms: 'Forms',
        formName: 'Form name',
        formDescription: 'Form description',
        formIncomplete: 'Form is incomplete',
        formMustContainAtLeastOneSection: 'Form must contain at least one section',
        formSectionsMustContainAtLeastOneQuestion:
            'Form sections must contain at least one question',
        from: 'from',
        fromMax: 'from max.',
        fromNow: 'From now',
        fromTime: 'From',
        Full: 'Full',
        fullProfile: 'Full profile',
        function: 'Function',
        functions: 'Functions',
        functionsManagement: 'Functions management',
        fromLeft: 'From left',
        fromTop: 'From top',
        general: 'General',
        getContactIfQuestion: 'If you have any questions, please contact the administrator',
        giveManagersRightsToSubDepartments: 'Also give managers rights to sub-departments',
        getPwa: 'Install app',
        gotoCatalogitem: 'Open Catalogitem',
        gotoChildLevel: 'Goto underlying departments',
        gotoReport: 'Open report',
        groupEnrollmentTitle:
            'Select the planning to enroll the employees of the selected department.',
        groupEnrollmentEmptyPlanning: 'No participants registered',
        group: 'Group',
        groupName: 'Group name',
        groups: 'Groups',
        HasTaggedRegistration: 'Has tagged registration',
        Headings: 'Headings',
        headingColor: 'Heading color',
        Help: 'Help',
        hideNoActionNotAssigned: 'Hide no action / not assigned',
        hideNotAssigned: 'Hide not assigned / does not apply',
        hlapps: 'Interactive components',
        'hlapps.back': 'Back to Interactive Components',
        'hlapps.back.mobile': 'Interactive Components',
        'hlapps.name': `{id, select,
            1 {Community}
            2 {Learning- & Development Scan}
            12 {Box}
            13 {Strengths & Development points}
            17 {My Learning Path}
            other {Werk- & Leervorm}
        }`,
        'hlapps.subtitle': `{id, select,
            1 {Meet each other online}
            2 {Make development visible}
            12 {Consult sources}
            13 {Go for personal growth}
            17 {View your course overview}
            other {''{id}''}
        }`,
        'hlapps.rib': 'Startup & Reflection',
        'hlapps.rib.sub': 'Share practical situations',
        'hlapps.obs': 'Online Video Session',
        'hlapps.obs.sub': 'Virtual dialogue',
        'hlapps.userman': 'User management',
        'hlapps.userman.sub': 'Who will participate?',
        'hlapps.productman': 'Product management',
        'hlapps.productman.sub': 'What will we use?',
        helpAndFeedback: 'Help and feedback',
        hereYouFindAnOverviewOfAllAvailableElearningTrainingsExpertiseItemsAndClassActivities:
            'On this page, you will find an overview of all available e-learning trainings, expertise items and class activities',
        hereYouFindAnOverviewOfAllAvailableDepartments:
            'On this page, you will find an overview of all available departments',
        hereYouFindAnOverviewOfAllAvailableFunctions: 'All available functions',
        hereYouFindAnOverviewOfAllAvailableTrainings:
            'On this page, you will find an overview of all available formal courses, e-learning and class activities.',
        hereYouFindAnOverviewOfTheDepartmentsWhereYouAreAManager:
            'On this page, you will find an overview of the departments where you are a manager',
        hereYouFindAnOverviewOfTheDepartmentsWhereYouWork:
            'On this page, you will find an overview of the departments where you work',
        hereBelowYouCanUpdateTheAttendanceOfTheParticipants:
            'Below this text, you can update the attendance of the participants.',
        hour: 'Hour',
        hourAbout: '{number} hour',
        hourAgo: '{number} hour ago',
        hours: 'Hours',
        hoursAbout: '{number} hours',
        hoursAgo: '{number} hours ago',
        hundredPercentVisibleInProportion: '100% visible, in proportion',
        hundredVisibleStretched: '100% visible, stretched',
        ifTheMaximumNumberOfParticipantsIsReachedRegistrationWillClose:
            'If the maximum number of participants is reached, registration will close',
        ignore: 'Ignore',
        ignoreChanges: 'Ignore changes?',
        insufficientRightsText: 'You have insufficient rights to view this page.',
        insufficientRightsTitle: 'Insufficient rights',
        Image: 'Image',
        'importmanagement.classroomResults': 'Classroom results',
        'importmanagement.confirm.beware': 'ATTENTION!',
        'importmanagement.confirm.areYouSure': 'Are you sure you want to import the uploaded file?',
        'importmanagement.confirm.overwrite':
            'Existing data (e.g. with different expiration date) may be overwritten with this import.',
        'importmanagement.confirm.lost': 'This may result in the loss of results.',
        'importmanagement.confirm.noReturn': 'This cannot be undone!',
        'importmanagement.confirm.startImport': 'I am sure, start import',
        'importmanagement.details': 'Details',
        'importmanagement.disclaimer':
            'I take full responsibility for the imported data and all consequences of that import.',
        'importmanagement.dossierItems': 'Dossieritems',
        'importmanagement.elearningResults': 'E-learning results',
        'importmanagement.error': 'Error',
        'importmanagement.exemptions': 'Exemptions (skills)',
        'importmanagement.fileUpload': 'Upload importfile (.csv)',
        'importmanagement.heading.date': 'Date',
        'importmanagement.heading.result': 'Result',
        'importmanagement.heading.type': 'Import type',
        'importmanagement.newImport': 'New import',
        'importmanagement.notImported': 'NOT imported',
        'importmanagement.whatDomain': 'In what domain do you want to import?',
        'importmanagement.whatToImport': 'What do you want to import?',

        'importmanagement.errorCode': `{error, select,
            ERR0 {Document structure is incorrect}
            ERR1 {non-existing users detected}
            ERR2 {non-existing skills detected}
            ERR3 {non-linked skills detected}
            ERR4 {academyInfo not found}
            ERR6 {non-existing catalog items detected}
            ERR7 {non-existing dossier definitions detected}
            other {{error}}
        }`,

        'importmanagement.status': `{statusId, select,
            0 {Files uploaded}
            1 {Test in progess...}
            2 {Users exist}
            3 {Skills exist}
            4 {Skills assigned to users}
            5 {Import failed}
            6 {Import in progress...}
            7 {Import succeeded}
            8 {Imported with errors}
            other {{statusId}}
        }`,

        'importmanagement.type': `{typeId, select,
            0 {Elearning results}
            1 {Exemptions (skills)}
            2 {Classroom}
            3 {Classroom results}
            4 {Dossier items}
            other {{typeId}}
        }`,

        import: 'Import',
        imported: 'import',
        importAssignments: 'Import assignments',
        importAssignmentsFromOtherUsers: 'Import assignments from other users',
        Inactive: 'Inactive',
        inapplicable: 'This skill does not apply',
        inapplicable2: 'Does not apply',
        includeChildDepartments: 'Incl sub depts.',
        includeSubdepartments: 'Include subdepartments',
        Indent: 'Indent',
        individual: 'Individual',
        individual2: 'Individual',
        inheritAssignments: 'Inherit assignments',
        inheritAssignmentsFromOtherUsers: 'Inherit assignments from other users',
        individuals: 'Individuals',
        information: 'Information',
        informationCancelPlanning:
            'Are you sure you want to cancel this schedule? You will need to unsubscribe or transfer participants from this schedule to another schedule.',
        informationForYou: 'More information for you',
        initials: 'Initials',
        inShort: 'In short',
        institution: 'Institution',
        Intake: 'Intake',
        InternalCost: 'Internal cost',
        introductionVideo: 'Introduction video SDB Leerplatform',
        invalidDate: 'Invalid date',
        invalidUrl: 'Invalid URL',
        isExemptFromPayment: 'Is exempt from payment',
        isGoingToExpire: 'Is going to expire',
        Italic: 'Italic',
        Justify: 'Justify',
        keepOpen: 'Keep open',
        copied: 'Copied',
        copy: 'Copy',
        copyAssignments: 'Copy assignments',
        copyAssignmentsToOtherUsers: 'Copy assignments to other users',
        copyNoun: 'Copy',
        country: 'Country',
        lastLoginDate: 'Last login date',
        lastName: 'Last name',
        lastNamePrefix: 'Prefix',
        lastResult: 'Last result',
        lastReviewDate: 'Last review date',
        lastSuccessfulMark: 'Last successful mark',
        learningplan: 'e-Learning',
        Learningplan: 'e-Learning',
        LearningPoints: 'Learning points',
        learningProgram: 'Learning program',
        learningplanTypes: 'eLearning',
        Left: 'Left',
        lesson: 'lesson',
        lessons: 'lessons',
        likert: 'Likert-scale',
        likertInfo:
            'Users can give their opinion on, for example, a statement via a Likert scale. This consists of 5 options, from "Disagree completely" to "Agree completely". Simply add multiple questions to create a group.',
        Link: 'Link',
        linkMustBeginWith: 'Link must begin with http:// or https://',
        listOfInterestedUsers: 'Interested users',
        listOfInterestedUsersInCourse: 'Interesed users in course',
        location: 'Location',
        locationDeleted: 'Location deleted',
        locations: 'Locations',
        login: 'Login',
        loginSetting: 'Welcome the user to the login screen',
        loginDescription: 'This text can be set for each portal',
        mailTemplates: 'Mail Templates',
        MailTemplateType: `{type, select,
            ClassroomEnqueue {Enrollment confirmation (Queue)}
            ClassroomDequeue {Unenrollment confirmation (Queue)}
            ClassroomEnroll {Enrollment confirmation}
            ClassroomUnenroll {Unenrollment confirmation}
            ClassroomTransfer {Transfer confirmation}
            ClassroomCancel {Cancel confirmation}
            ClassroomReminder {Reminder email}
            other {{type}}
        }`,
        makeAppointment: 'Click to make an appointment',
        makeAChoice: 'Make a choice',
        manageUsers: 'Manage users',
        managerGroups: 'groups',
        managersAreAllowedToAssignCatalogItems: 'Managers are allowed to assign catalog items',
        managersMayNotAssign: 'Managers may not assign',
        manageSkills: 'Manage skills',
        mandatory: 'Mandatory',
        mandatoryFieldsMustBeCompleted: 'Mandatory fields must be completed',
        mandatoryFrom: 'Mandatory from',
        mandatoryNotMandatory: 'Mandatory / not mandatory',
        mandatoryTrainings: 'Mandatory trainings',
        mandatoryTrainingsShort: 'Mand. trainings',
        MandatoryTrainingsSubtitle: `Don''t forget to start!`,
        manual: 'Manual',
        markAsRead: 'Mark as read',
        markAsUnread: 'Mark as unread',
        maxAmount: 'Maximum number',
        maxAttachmentFileSize: 'The total size for the files may not exceed 50 MB',
        maxCapacity: 'Maximum number of participants',
        maxCapacityMustBeHigherThanMinCapacity:
            'Maximum number is mandatory and must be higher than minimum number',
        maximum: 'maximum',
        maxNumberOfEnrollmentsPerParticipant: 'Max. enrollments per participant',
        MaxNumberOfParticipantsReached: 'Max. number of participants reached',
        Message: 'Message',
        'message.header': `{ created, select,
            undefined {}
            other {{created} - }
        }{ sender, select,
            undefined {System message}
            other {{sender}}
        }{ autoGenerated, select,
            true { (sent automatically)}
            other {}
        }`,
        'message.to': `To: {recipients}`,
        'message.subjectElearningNotificationMessage': 'e-learning completed message',
        'message.achievementMessagePassed': `User ''{identityDisplayName}'' has passed the e-learning module ''{catalogItemName}''`,
        'message.achievementMessageFinished': `User ''{identityDisplayName}'' has successfully completed the e-learning module ''{catalogItemName}''`,
        'message.assessorAppointmentMade': `An appointment was made for user ''{studentDisplayName}'' on ''{dateTime}'' to assess the following activity: ''{activityDescription}''`,
        'message.assessorAppointmentRemoved': `The appointment for user ''{studentDisplayName}'' on ''{dateTime}'' for activity ''{activityDescription}'' has been removed`,
        'message.assessorAppointmentMadeSubject': `Assessment appointment created`,
        'message.assessorAppointmentRemovedSubject': 'Assessment appointment removed',
        'message.catalogAuthRequestPermissionSubjectLearningplan': `{studentDisplayName} has requested permission for elearning module ''{displayName}''.`,
        'message.catalogAuthRequestPermissionSubjectClassroom': `{studentDisplayName} has requested permission for the classical training module ''{displayName}'' which starts at {planningStart}.`,
        'message.catalogAuthRequestPermissionBody': `Go to the dashboard and click ''Authorization requests'' to process the request.`,
        'message.catalogAuthRequestBodyLearningPlanAccepted': `Your request for elearning ''{displayName}'' is accepted`,
        'message.catalogAuthRequestBodyLearningPlanAcceptedRemark': `Your request for elearning ''{displayName}'' is accepted. Your manager added the following remark: {managerRemark}`,
        'message.catalogAuthRequestBodyLearningPlanRejected': `Your request for elearning ''{displayName}'' is rejected.`,
        'message.catalogAuthRequestBodyLearningPlanRejectedRemark': `Your request for elearning ''{displayName}'' is rejected. Your manager added the following remark: {managerRemark}`,
        'message.catalogAuthRequestBodyLearningPlanDeleted': `Your request for elearning ''{displayName}'' is deleted.`,
        'message.catalogAuthRequestBodyLearningPlanDeletedRemark': `Your request for elearning ''{displayName}'' is deleted. Your manager added the following remark: {managerRemark}`,
        'message.catalogAuthRequestBodyLearningPlanUnknown': `Your request for elearning ''{displayName}'' is status unknown.`,
        'message.catalogAuthRequestBodyLearningPlanUnknownRemark': `Your request for elearning ''{displayName}'' is status unknown. Your manager added the following remark: {managerRemark}`,
        'message.catalogAuthRequestPermissionRevoked': `The user revoked the authorization request for ''{displayName}''.`,
        'message.catalogAuthRequestBodyClassroomAccepted': `Your request for classical training ''{displayName}'' which starts at {planningStart} is accepted.`,
        'message.catalogAuthRequestBodyClassroomAcceptedRemark': `Your request for classical training ''{displayName}'' which starts at {planningStart} is accepted. Your manager added the following remark: {managerRemark}`,
        'message.catalogAuthRequestBodyClassroomRejected': `Your request for classical training ''{displayName}'' which starts at {planningStart} is rejected`,
        'message.catalogAuthRequestBodyClassroomRejectedRemark': `Your request for classical training ''{displayName}'' which starts at {planningStart} is rejected. Your manager added the following remark: {managerRemark}`,
        'message.catalogAuthRequestBodyClassroomDeleted': `Your request for classical training ''{displayName}'' which starts at {planningStart} is deleted.`,
        'message.catalogAuthRequestBodyClassroomDeletedRemark': `Your request for classical training ''{displayName}'' which starts at {planningStart} is deleted. Your manager added the following remark: {managerRemark}`,
        'message.catalogAuthRequestBodyClassroomUnknown': `Your request for classical training ''{displayName}'' which starts at {planningStart} is status unknown.`,
        'message.catalogAuthRequestBodyClassroomUnknownRemark': `Your request for classical training ''{displayName}'' which starts at {planningStart} is status unknown. Your manager added the following remark: {managerRemark}`,
        'message.catalogAuthRequestPermissionAccepted': `Your request for ''{displayName}'' is accepted.`,
        'message.catalogAuthRequestPermissionRejected': `Your request for ''{displayName}'' is rejected.`,
        'message.catalogAuthRequestPermissionDeleted': `Your request for ''{displayName}'' is deleted.`,
        'message.catalogAuthRequestPermissionUnknown': `Your request for ''{displayName}'' is status unknown.`,
        'message.catalogAuthRequestBodyRevokedByUser': `User ''{studentDisplayName}'' revoked the authorization request for ''{displayName}''`,
        messageBodyIsRequired: 'Message body is required',
        messageSent: 'Message sent',
        'message.filterByMessage': 'Filter by message',
        'message.searchSubject': 'Search on subject',
        'message.filterBySender': 'Filter by sender',
        'message.originalMessageInfo': 'On {date} {sender} wrote:',
        'message.new': 'Message to user',
        'message.newClass': 'Message to coursegroup',
        'message.newDepartment': 'Message to department',
        'message.Received': 'Received',
        'message.Sent': 'Sent',
        'message.Settings': 'Settings',
        'message.settings.admin.title': 'Message box settings',
        'message.settings.admin.description':
            'Set the default message box settings for all users. These settings will apply to all users, except for users who changed their personal settings in their own message box settings.',
        'message.settings.title': 'My messagebox settings',
        'message.settings.emailSummary': 'E-mail summary',
        'message.settings.emailSummary.subtitle':
            'Receive an e-mail summary with the number of unread messages in the messagebox',
        'message.settings.emailNotification': 'E-mail notification',
        'message.settings.emailNotification.newMessages': 'Receive new messages by e-mail',
        'message.settings.emailNotification.Expiringskill': 'Expiring skill',
        'message.settings.emailNotification.skillExpiration':
            'Receive notification when skills expire by email',
        'message.settings.frequency': 'Email summary frequency',
        'message.settings.noEmail': 'Never / no summary',
        'message.settings.none': 'Never / no summary',
        'message.settings.daily': 'Daily summary',
        'message.settings.weekly': 'Weekly summary',
        'message.settings.monthly': 'Monthly summary',
        'message.skills.expirationNotification.subject': `Skills update`,
        'message.skills.expirationNotification.body': `Dear participant,\n
            { expired, select,
                0 {}
                other {At the moment {expired, select, 1 {1 skill has} other {{expired} skills have}} expired.}
            }
            { expiring, select,
                0 {}
                other {In the period up to {until}, {expiring} more {expiring, select, 1 {skill} other {skills}} will expire.}
            }
            { new, select,
                0 {}
                other {Since the last update, {new} {new, select, 1 {skill has} other {skills have}} been assigned to you.}
            }

            You can view your skills in your portfolio.
        `,
        mayBeRedoneAgain: 'May be redone again',
        media: 'Media',
        min0max100: 'Min. 0 and Max. 100',
        minAmount: 'Minimum number',
        minCapacity: 'Minimum number of participants',
        minCapacityMustBeLowerThanMaxCapacity:
            'Minimum number is mandatory and must be lower than maximum number',
        minMaxCapacity: 'Minimum/maximum participants',
        minuteAbout: '{number} minute',
        minuteAgo: '{number} minute ago',
        minutes: 'Minutes',
        minutesAbout: '{number} minutes',
        minutesAgo: '{number} minutes ago',
        mobilePhone: 'Mobile Phone',
        modifyEnrollment: 'Modify enrollment',
        'modules.accessmanagement.activatedRights': 'Activated rights',
        'modules.accessmanagement.applicationPart': 'Application part',
        'modules.accessManagement.descriptions': `{key, select,
            roles_and_rights {Roles and rights}
            roles_and_rights_manage_roles {Manage roles}
            roles_and_rights_manage_assignments {Manage assignments}
            classroom {Classroom}
            classroom_manage_courses {Manage courses}
            classroom_manage_schedules {Manage schedules}
            classroom_manage_enrollments {Manage enrollments}
            classroom_manage_presence {Manage presence}
            other {{key}}
        }`,
        'modules.accessmanagement.errors': `{error, select,
            duplicate_name {A role with this name already exists}
            no_name_provided {No rolename provided}
            other {An error has occured}
        }`,
        'modules.accessManagement.rights_count_': `Rights: {count}`,
        'modules.accessManagement.roleName': 'Rolename',
        'modules.admin.classroom.presence.NumberOfResults': 'Total number of results:',
        'modules.adminMenu.labels': `{label, select,
            settings {Settings}
            catalog {Catalog}
            compositionmanagement {E-learning management}
            scormmanagement {Scorm management}
            elearningmanagement {E-learning management}
            evaluations {Evaluations}
            definities {Portfolio items}
            classroom {Classical}
            skills {Skills}
            certificates {Certificates}
            usermanagement {Usermanagement}
            examinermanagement {Examiner management}
            importmanagement {Import management}
            maillogoverview {E-mail logging}
            other {{label}}
        }`,
        'modules.catalog.course.authorization.noManagersNotPossibleToAuthorize':
            'No manager was found to submit the authorization request to.',
        'modules.catalog.statusType': `{statusType, select,
            all {All}
            completed {Completed}
            notcompleted {Not completed}
            started {Started / Registered}
            other {{statusType}}
        }`,
        'modules.classroom.exportPresence': 'Download presence report',
        'modules.classroom.participants.resultValue': `{value, select,
            passed {Passed}
            failed {Failed}
            other {Not filled in}
        }`,
        'modules.classroom.presenceListFileNamePart': 'attendance list',
        'modules.classroom.presenceValue': `{value, select,
            present {Present}
            absent {Absent}
            other {Not filled in}
        }`,
        'modules.classroom.queueEnabledAndAuthorizationRequiredWarning': `The 'Authorization needed by manager' and 'Queue' options cannot be enabled at the same time.`,
        'modules.classroom.queueEnabledAndPlanningIntegrationWarning': `The 'Send training hours to SDB Planning' and 'Queue' options cannot be enabled at the same time.`,
        'modules.classroom.registrations': `Registered {enrollmentCount} — Max. {maxCapacity}`,
        'modules.classroom.resultReportFileNamePart': 'result report',
        'modules.classroom.upcomingplanninglist.details': `details`,
        'modules.classroom.upcomingplanninglist.unsubscribe': `unsubscribe`,
        'modules.classroomNotifications.teachersReceiveUnenrollNotifications':
            'Teachers receive notifications when users unenroll',
        'modules.classroomNotifications.unenrollNotificationsList':
            'The following users receive notifications when someone unenrolls from a classroom course',
        'modules.classroomNotifications.teacherNotifications': 'Notifications for teachers',
        'modules.elearningmanagement.tabs': `{tab, select,
            lti {LTI}
            drafts {Compositions}
            scorm {SCORM}
            other {{tab}}
        }`,
        'modules.maillog.EmailLogging': 'E-mail logging',
        'modules.maillog.EmailReceiver': 'E-mail receiver',
        'modules.maillog.events': `{event, select,
            Deferred {deferred}
            Bounce {delivery failed}
            Delivered {delivered}
            Dropped {not processed}
            Blocked {delivery blocked}
            other {{event}}
        }`,
        'modules.mailtemplates.deleteMailTemplate.title': 'Delete mailtemplate',
        'modules.mailtemplates.deleteMailTemplate.message': `Are you sure you want to delete this mailtemplate?
            After deletion, the default mailtemplate will be used again.`,
        'modules.mailtemplates.labels': `{label, select,
            cursist {[cursist]}
            opleiding {[opleiding]}
            planning {[planning]}
            start {[start]}
            eind {[eind]}
            locatie {[locatie]}
            docent {[docent]}
            roosteritems {[roosteritems]}
            roosteritems2 {{roosteritems}}
            other {{label}}
        }`,
        'modules.mailtemplates.labels.roosteritems.usage': 'This is used as',
        'modules.mailtemplates.labels.roosteritems.content':
            'roosteritems "From [start] till [eind] in [locatie] by [docent]."',
        'modules.mainMenu.labels': `{label, select,
            start {Start}
            catalog {Catalog}
            departments {Departments}
            skills {Skills}
            functions {Functions}
            hlapps {Interactive components}
            myMinerva {Portfolio}
            search {Search}
            administration {Administration}
            other {{label}}
        }`,
        'modules.messages.markReadAllButton': `mark all as read`,
        'modules.packages.areYouSureYouWantToResetThisPackage':
            'Are you sure you want to reset this package?',
        'modules.packages.deletePackageMessage':
            'This package version has active workflows running. When removing this package version these workflows will be removed and the corresponding users will be sent a notification by e-mail.',
        'modules.packages.sendMailToUsers': 'Send an e-mail to the users',
        'modules.packages.resetPackage': 'Reset package',
        'modules.packages.resetPackageMessage':
            'This package version has active workflows running. When resetting this package version these workflows will reset and the corresponding users will be sent a notification by e-mail.',
        'modules.skills.achievedOn': 'Achieved on {date}',
        'modules.skills.activity.status': `{status, select,
            exemption {exemption}
            red {expired / not yet finalized}
            yellow {expires soon}
            green {completed}
            other {{status}}
        }`,
        'modules.skills.activityGroup.subtitle': `{groupType, select,
            compulsory {Compulsory}
            optional {Optional}
            recommended {Recommended}
            other {{groupType}}
        } - {activities} {activities, select,
            1 {activity}
            other {activities}
        } {groupType, select,
            optional {of which {required} required}
            other {}
        } {notApplicable, select,
            0 {}
            other {({notApplicable} disabled)}
        }`,
        'modules.skills.activityGroupType': `{type, select,
            standard {Standard}
            basicWithRefresher {Basic with refresher}
            other {{type}}
        }`,
        'modules.skills.activityLockedDialog.title': 'Not accessible yet',
        'modules.skills.activityLockedDialog.message':
            'This activity is not yet accessible. Complete the previous activities first.',
        'modules.skills.activityType': `{type, select,
            protocol {Protocol}
            manual {Manual}
            classical {Classical theory lesson}
            elearning {E-learning}
            skillslabtraining {Skillslab training}
            trainingonthejob {Training on the job}
            writtentest {Written test}
            skillslabtest {Skillslab test}
            practical {Practical test}
            peerassessment {Peer assessment}
            selfassessment {Self assessment}
            other {{type}}
        }`,
        'modules.skills.activityCurrentStatus': `{ status, select,
            elearning_created { Started }
            classical_registered { Registered }
            classical_unregistered { Unregistered }
            other {}
        }`,
        'modules.skills.activityExpirationStatus': `{ status, select,
            achieved {Achieved}
            expires {Expires at {date}}
            never_expires {Never expires}
            exemption {Exemption untill {date}}
            effective_from {Mandatory from {date}}
            expired {Expired at {date}}
            other {}
        }`,
        'modules.skills.activityValidity.title': 'Validity period',
        'modules.skills.activityValidity.value': `{value, select,
            default {Default}
            custom {Custom}
            oneoff {Oneoff}
            other {{value}}
        }`,
        'modules.skills.activityValidity.months': `{ months, select,
            1 {Maand}
            other {Maanden}
        }`,
        'modules.skills.assignmentDialog.title': 'Select departments and roles',
        'modules.skills.assignmentDialog.noSelectionInfo':
            'Select at least one department and roles',
        'modules.skills.assignmentDialog.departments': `Departments`,
        'modules.skills.assignmentDialog.roles': `Roles`,
        'modules.skills.assignmentDialog.selected': `selected`,
        'modules.skills.assignments.headline': `Number assigned: {assigned}, of which {notApplicable} not applicable`,
        'modules.skills.basicWithRefresher': 'Basic - Refresher',
        'modules.skills.basicWithRefresher.basicValidity': 'Refresher activation date',
        'modules.skills.basicWithRefresherHelpMonths':
            'Determine when the refresher course will become active after initially completing the basic course.',
        'modules.skills.basicWithRefresher.basicOneOff': 'Basic course is one-off',
        'modules.skills.basicWithRefresher.basicOneOffHelp':
            'Turn on to determine that the basic course must be completed only once.',
        'modules.skills.basicWithRefresherHelpGracePeriod':
            'Determine how many extra months an employee gets to finalize the refresher activities before the base will become applicable again.',
        'modules.skills.basicWithRefresher.summaryTitle': 'Summary',
        'modules.skills.basicWithRefresher.summary': `After initially achieving the basic course, the refresher course becomes leading after {basicValidity}
        { basicValidity, select,
            1 {month}
            other {months}
        }.
        { gracePeriod, select,
            null {The basic course only has to be achieved once and therefore does not expire, regardless of whether the refresher course is achieved on time.}
            0 {If the refresher block is not achieved in time, the basic block will become applicable after {basicValidity}
            { basicValidity, select,
                1 {month}
                other {months}
            }. The employee does not have a grace period.}
            other {If the refresher block is not achieved in time, the basic block will become applicable again. The employee has {gracePeriod}
            { gracePeriod, select,
                1 {month}
                other {months}
            } extra months after the initial {basicValidity}
            { basicValidity, select,
                1 {month}
                other {months}
            }.}
        }`,

        'modules.skills.editor.assign': 'Assign',
        'modules.skills.editor.assignSubtitle': `{count, select,
            0 {Select at least one department/role combination}
            1 {1 valid department/role combination selected}
            other {{count} valid department/role combinations selected}
        }`,
        'modules.skills.editor.general': 'General',
        'modules.skills.editor.content': 'Skill content',
        'modules.skills.editor.validity': 'Skill validity',
        'modules.skills.editor.months': 'Months',
        'modules.skills.editor.moveActivities': 'Move activities',
        'modules.skills.editor.moveActivities.description':
            'Move activities to another block while keeping results',
        'modules.skills.editor.moveFrom': 'Move from',
        'modules.skills.editor.moveTo': 'Move to',
        'modules.skills.editor.assignRights': 'Rights',
        'modules.skills.editor.allCatalogItems': 'All catalog items',
        'modules.skills.editor.selectCatalogItems': 'Select catalog items',
        'modules.skills.editor.allDepartments': 'All departments',
        'modules.skills.editor.allRoles': 'All roles',
        'modules.skills.editor.allowedToCustomizeBlocks': 'Deactivate blocks and/or activities',
        'modules.skills.editor.allowedToCustomizeBlocksInfo':
            'This skill can be adjusted by deactivating components when assigning. You can always add blocks/activities.',
        'modules.skills.editor.allowedToExtendTheValidity': 'Extend the validity',
        'modules.skills.editor.allowedToExtendTheValidityInfo':
            'This skill has a validity period as specified above. With this option it can be extended when assigning.',
        'modules.skills.editor.allowOwnerToModifyExemption': 'Give yourself an exemption',
        'modules.skills.editor.allowOwnerToModifyExemptionInfo':
            'The employee may grant himself an exemption for this skill.',
        'modules.skills.editor.assignToNonExistingRolesHeadline':
            'Assign to non existing department / role combinations',
        'modules.skills.editor.assignToNonExistingRolesSubtitle':
            'Assign skill to roles whether or not they currently exist in the selected departments',
        'modules.skills.editor.activitiesAre': 'Activities are',
        'modules.skills.editor.persistOrder': 'Persist order',
        'modules.skills.editor.removeBlock': 'Remove block',
        'modules.skills.editor.selectActivities': 'Select activities',
        'modules.skills.editor.removeBlockCheck':
            'Are you sure you want to remove this block with all linked activities?',
        'modules.skills.editor.selectDepartments': 'Select department(s)',
        'modules.skills.editor.selectRoles': 'Select role(s)',
        'modules.skills.editor.noBlockYet':
            'No block has been created yet. Please create a block first.',
        'modules.skills.editor.noActivityYet':
            'No activity has been created yet. Please create an activity first.',
        'modules.skills.editor.addActivity': 'Add activity',
        'modules.skills.editor.removeActivity': 'Remove activity',
        'modules.skills.editor.manageAssessors': 'Manage assessors',
        'modules.skills.editor.addAssessors': 'Add assessor',
        'modules.skills.editor.manageAssessors.description': '(un)link assessors to activities',
        'modules.skills.editor.assessmentForm': 'Assessment form',
        'modules.skills.editor.examinerFunctionality': 'Examiner functionality',
        'modules.skills.editor.combineExams': 'Combine exams',
        'modules.skills.editor.examDuration': 'Exam duration (minutes)',
        'modules.skills.editor.catalogitem': 'Catalog item',
        'modules.skills.editor.numberOfAssessors': `{assessors, select,
            1 {1 assessor}
            other {{assessors} assessors}
        }`,
        'modules.skills.editor.gracePeriod': 'Grace Period',
        'modules.skills.errors.errorOnSavingChanges':
            'Something went wrong while saving the changes',
        'modules.skills.errors.errorOnSavingSomeOfItems':
            'Something went wrong while saving some of the changes',
        'modules.skills.expirationInfo': `{date, select,
            null {{approved, select,
                true {Never expires}
                false {Not yet finalized}
                other {{approved}}
            }}
            other {{date}}
        }`,
        'modules.skills.groupType': `{groupType, select,
            compulsory {Required}
            optional {Required}
            recommended {Advised}
            other {{value}}
        }`,
        'modules.skills.managerSettings.allowManageAssignments.headline': 'Assign skills',
        'modules.skills.managerSettings.allowManageAssignments.description':
            'Managers are allowed to assign skills',
        'modules.skills.managerSettings.assessmentByManagers.headline': 'Assess activities',
        'modules.skills.managerSettings.assessmentByManagers.description':
            'Select on what level managers can assess activities',
        'modules.skills.managerSettings.assessmentByManagers.options': 'Options',
        'modules.skills.managerSettings.assessmentByManagers.all': 'All employees',
        'modules.skills.managerSettings.assessmentByManagers.whereManager':
            'Only employees of own department',
        'modules.skills.messages.assignedToAllAvailableFunctions':
            'The skill is assigned to all available roles',
        'modules.skills.messages.errorCategoryNotEmpty':
            'The category still contains one or more skills',
        'modules.skills.modifySearchFilter': 'No assessors found matching your query...',
        'modules.skills.noExpirationDate': 'Not expiration date',
        'modules.skills.noResults.noAssignments.title': 'Not assigned yet',
        'modules.skills.noResults.noAssignments.text': 'The skill is not assigned yet',
        'modules.skills.notApplicableReason': 'Not applicable reason',
        'modules.skills.requestAssessment': 'Request assessment',
        'modules.skills.requestAssessmentFor': 'Request assessment for',
        'modules.skills.revokedBy': `{ revokedBy, select,
            null {Revoked}
            other {Revoked by {revokedBy}}
        }`,
        'modules.skills.overallProgress.status': `{status, select,
            notApproved {not approved / expired}
            expiring {expires soon}
            approved {approved / exemption}
            notApplicable {not applicable}
            other {{status}}
        }`,
        'modules.skills.optionalBlockName': 'Block name',
        'modules.skills.overallProgress.title': 'Overall progress',
        'modules.skills.persistActivityOrder': 'Persist activity order',
        'modules.skills.persistActivityGroupOrder': 'Persist block order',
        'modules.skills.persistActivityGroupOrderInfo': 'Users can only open blocks in order',
        'modules.skills.selectReviewer': `{ current, select,
            null {Select an assessor from whom you'd like to request an assessment. }
            other {{current} is set as assessor. Choose another assessor or withdraw the application.}
        }`,
        'modules.skills.skillCard.assignmentInfo': `{assignment, select,
            individual {individual}
            other {{assignment}}
        }`,
        'modules.skills.settings.skillsMatrixToggleLabel': 'Matrix view',
        'modules.skills.status': `{status, select,
            grey {Not assigned / not applicable}
            red {Not approved / expired}
            blue {In progress}
            orange {Expires soon}
            green {Approved / exemption}
            other {{Status}}
        }`,
        'modules.trainings.status': `{status, select,
            grey {No action / not assigned}
            red {Not completed / expired}
            blue {Enrolled / started}
            orange {Will expire}
            green {Completed}
            other {{Status}}
        }`,
        'modules.uservoice.emailWarning': 'Missing email address',
        'modules.uservoice.noValidEmail':
            'A valid email address is missing in your profile. Please provide an email adres below to update your profile.',

        'modules.planningIntegration.always':
            'The training will be forwarded to your planning application.',
        'modules.planningIntegration.cancelAndTransfer':
            'Canceling and transfering is not possible',
        'modules.planningIntegration.forCancelPlanning': 'For cancel planning',
        'modules.planningIntegration.forTransfer': 'For transfer',
        'modules.planningIntegration.functionalityCantBeUsedInCombination': ` "This functionality currently can't be used in combination with the integration
                    with SDB Planning"`,
        'modules.planningIntegration.planningCantBeCanced': `"This planning can't be canceled, first unenroll all users"`,
        'modules.planningIntegration.toggleText': 'Send training hours to SDB Planning',
        'modules.planningIntegration.nominalDuty.toggleText': 'Send study load to SDB Planning',
        'modules.planningIntegration.nominalDutyActivityCode': 'Nominal hour code',
        'modules.planningIntegration.dropdownLabel': 'Planning contract',

        month: 'Month',
        monthAbout: '{number} month',
        monthsAbout: '{number} months',
        monthAgo: '{number} month ago',
        monthsAgo: '{number} months ago',
        months: 'Months',
        multiChoice: 'Multiple choice',
        multiChoiceInfo: 'At least 2 answer options where only 1 answer can be chosen.',
        myDepartments: 'My departments',
        myMessages: 'My messages',
        myMessagesAndNotifications: 'My messages and notifications',
        myMinerva: 'Portfolio',
        mySelf: 'Myself',
        name: 'Name',
        nameAscending: 'Name ascending',
        nameDescending: 'Name descending',
        nameGroup: 'Name',
        navigateToClassroom: 'Do you want to open the classroom page?',
        navigateToEnroll: 'Navigate to enroll page?',
        navigateToElearning: 'Do you want to open the e-learning page?',
        navigateToElearningPage: 'Click to open the e-learning page',
        navigateToFavorites: 'See your list with favorites.',
        neutral: 'Neutral',
        never: 'Never',
        NewAndConfirmPasswordsDoNotMatch: 'Passwords do not match',
        newExternalCatalogItem: 'External Content',
        newestFirst: 'Newest first',
        newEvaluationForm: 'New evaluation form',
        newLink: 'New link',
        newLocation: 'New location',
        newMessage: 'New message',
        newParticipant: 'New participant',
        NewPassword: 'New password',
        newPlanning: 'Add planning',
        newsItems: 'News items',
        NewsItemsWidgetInfo: `Don''t miss anything that happens in your organisation`,
        newsMessage: 'News message',
        newTool: 'New tool',
        newVersion:
            'A new version of this application is available. Refresh to update to the latest version',
        next: 'next',
        nextSessionDateTime: 'Next session is on {date} at {time}',
        no: 'No',
        noAccreditationFoundTitle: 'No accreditations found',
        noAccreditationInfoFoundTitle: 'No accreditation settings',
        noAccreditationInfoFoundText:
            'There are no accreditation settings configured for this course',
        noAssignments: 'No assignments',
        noAssignmentFoundTitle: 'No assigned trainings found',
        noAssignmentFoundText: 'Assign a training',
        noAuthorizationTasksFound: 'No authorization tasks found!',
        noCatalogItemsFoundTitle: 'No catalog items found',
        noCatalogItemsFoundText: 'Try changing the filter settings.',
        NoCatalogItemInterestsFoundText: 'You have not showed any interest in a catalog item yet',
        NoCatalogItemInterestsFoundTitle: 'No catalog item interests found',
        noCertificate: 'No certificate',
        noComment: 'No comment',
        noCompetencyPassportFound: 'No competency passport found',
        noDashBoardLinksTitle: 'No dashboard links yet',
        noDashBoardLinksText: 'There are no dashboard links yet. Add possibly a dashboard link.',
        noDeadline: 'No Deadline',
        noDefinitionFoundTitle: 'No definitions found',
        noDefinitionFoundText: 'Add a definition tool',
        noDepartmentsTitle: 'No departments found',
        noDepartmentsText: 'Try adjusting the filter settings.',
        noEmail: 'No e-mail',
        noEmployeesFound: 'No employees found',
        noEnrollmentsYet: 'No enrollments yet.',
        noEvaluationsFound: 'No evaluations found',
        noEvaluationFormReports: 'No evaluation reports were found',
        noExemption: 'No exemption',
        noExpertiseText: 'Gained experience or knowledge? Add it to your portfolio!',
        noExpertiseTitle: 'No dossier items',
        NoFavorites: 'No favorites',
        noFavoritesButNavigateToAdd:
            'No favorites yet. Go to the catalog and click on <3 to add favorites.',
        noExaminers: `There isn't yet an assessor that can be selected for this activity. For more information get in touch with the administrator.`,
        notAvailableAnymore: 'not available anymore',
        notification: 'Notification',
        notifications: 'Notifications',
        notifyUserAfterCompletion: 'After completion send notification to user',
        None: 'None',
        noLesson: 'No lesson',
        noLink: 'No link was supplied!',
        NoLocation: 'No location',
        NoLocationOrDoubleLocation: 'No location or the locatie is double planned',
        noLocationFoundTitle: 'No locations found',
        noLocationFoundText: 'Enter the name of a location in the search field.',
        noLocationSelected: 'No location selected.',
        noParticipantsCatalog: 'Start a search action from the filter panel on the right.',
        noPlannings: 'No schedules were found',
        noReportDataFound: 'No report data found',
        noResultsFound: 'No results found',
        noResultsFoundTitle: 'No results found',
        noResultsFoundText: 'Try changing the filter settings',
        noRolesExistForThisDepartment: 'No roles exist for this department',
        noSchedulesAddedYet: 'No sessions added yet.',
        noSchedules: 'You should add at least one session to the planning',
        noSkillsAndMandatoryAssigned: 'No skills or mandatory trainings assigned',
        noSsoGroupsFound: 'No sso groups found',
        notAchieved: 'Not achieved',
        NotAuthorized: 'Not authorized',
        notAvailable: 'na',
        NoTeacherOrDoubleTeacher: 'No teacher or a teacher is double planned',
        noTrainingFoundTitle: 'No training found',
        noTrainingFoundText: 'Add a training or reset filters',
        noTrainingFoundTextAlt: 'No trainings found',
        notRequired: 'Not required',
        noEnrollmentsFound: 'No enrollments found',
        noEnrollmentsHeader: 'No enrollments',
        noEnrollmentsText: 'No problem, you can enroll through the catalog',
        notApproved: 'Not approved',
        notComplete: 'Not complete',
        notCompletedStatus: 'Not completed',
        NotEnoughParticipants: 'Not enough participants',
        notRead: 'Not read',
        NoTeacher: 'No teacher',
        noTeachers: 'No teacher selected.',
        NotEnoughRightsForAccreditations:
            'You do not have enough rights to see these accreditations.',
        notEnrolledEnrollFirst: 'Not yet enrolled. Go to the catalog to enroll.',
        notKnown: 'Not known',
        notMandatory: 'May skip',
        notOnAllPresent: 'not attended a meeting',
        NotOnAllPresentAndNotOnAllNotPresent: 'presence not fully filled in',
        notPassed: 'Failed',
        notRegistered: 'Not registered',
        NotSatisfied: 'Not satisfied',
        NotStarted: 'Not started',
        noPlanningsFoundTitle: 'No plannings found',
        noPlanningsFoundText: 'Try to adjust the filters.',
        noReservationsFoundTitle: 'No reservations found',
        noTool: 'No tool selected.',
        noToolFoundTitle: 'No tools found',
        noToolFoundText: 'Add a new tool',
        notYetDone: 'Not yet done',
        notYetFinalized: 'Not yet finalized',
        notYetKnown: 'Not yet known',
        notYetStarted: 'Not yet started',
        noUnrollNotificationsFoundTitle: 'No notifications found',
        noUsersFoundTitle: 'No users found',
        noUsersFoundText: 'Select the correct domain and enter the username',
        noTeachersFoundTitle: 'No teachers found',
        noTeachersFoundText: 'There are no teachers assigned',
        noWPL: `You haven''t been attached to WPL`,
        number: 'Number',
        numberApproved: 'Number approved',
        numberdays: 'Number of days',
        numberhours: 'Number of hours',
        numberminutes: 'Number of minutes',
        numberOfCompletedEvaluations: 'Number of completed forms',
        numberOfUnCompletedEvaluations: 'Number of uncompleted evaluations',
        numberOfPlacesAvailable: 'Number of places available',
        numberOfPoints: 'Number of points',
        numberOfPointsAchieved: 'Achieved points',
        numberOfRegisteredParticipants: 'Number of registered participants',
        numberOfReservations: 'Number of reservations',
        numberOfSelectedItems: 'number of items selected',
        numberOfSkills: '{numberOfSkills} skills',
        numberOfSkillsInTotal: '{numberOfSkills} skills in total',
        numberparticipants: 'Number of participants',
        numberrecipients: 'Number of recipients',
        ofAssessmentsCarriedOutByYou: 'of assessments carried out by you',
        off: 'Off',
        ok: 'OK',
        oldestFirst: 'Oldest first',
        oneOfTheScheduleIsNotFilledInWell: 'One of the sessions contains errors',
        on: 'On',
        onAllPresent: 'attended every meeting',
        one: 'one',
        onlyActiveAccounts: 'Only active accounts',
        onlyAssignableByAdmins: 'Only assignable by administrators',
        onlyDisabledAccounts: 'Only disabled accounts',
        onlyWithWarnings: 'Only with warnings',
        onlyQualifiedAssessorsAllowed: 'You are not qualified to examinate this item',
        oops: 'Oops',
        open: 'Open',
        openApplications: 'Open applications',
        openElearningDialogInFullscreenText: 'Open e-learning dialog fullscreen',
        openInANewWindow: 'Open in a new window',
        openQuestion: 'Open question',
        openQuestionInfo:
            'Users can enter a number of keywords via an open input field. No answer options are provided for this question.',
        openScormInNewWindow: 'Open SCORM package(s) in new window',
        openScormInNewWindowDetail: `When this catalog items contains SCORM packages, open them in a new window. Only use this when the opening in the same window doesn''t work, because opening in a new window might trigger popup blockers. This options does not do anything when there are no SCORM packages in the catalog item.`,
        openScormInNewWindowDontClose: `Content was opened in a new window. Don''t close this window/tab`,
        optional: 'Optional',
        options: 'Options',
        optionText: 'Option text',
        optionQuestion: 'Option question',
        order: 'Order',
        'Ordered List': 'Ordered List',
        orEnterAssessmentsByName: 'assess a colleague',
        Outdent: 'Outdent',
        overrideTheMasterDescription: 'Use custom text instead of the default description',
        overrideTheMasterSummary: 'Use custom text instead of the default summary',
        ownName: 'Own name',
        Package: 'Package',
        PackagesActive: 'Packages active',
        PackagesInactive: 'Packages inactive',
        PageNotFound: 'The page could not be found...',
        pageIsUnavailableTitle: 'Page unavailable',
        pageIsUnavailable: 'This page is either unavailable or disabled.',
        payAttention: 'Pay attention',
        parentPortalGroup: 'Upper department',
        participant: 'Participant',
        participantAdded: 'Participant added',
        ParticipantEntersTheQueue: 'Participant  the queue',
        participantIsNotSelected: 'Participant is not selected',
        participantRegistered: 'participant registered',
        participants: 'Participants',
        participantsRegistered: 'participants registered',
        participantUnRegistered: 'Participant unregistered',
        passed: 'Passed',
        Passed: 'Passed',
        PassedAndExpired: `Passed but it''s expired`,
        past: 'Past',
        pathToImage: 'Path to image',
        paymentHeading: 'Payment',
        'pe.externalPersonId': 'PE-online Person ID',
        'pe.courseId': 'PE-online Course ID',
        'pe.editionId': 'PE-online Edition ID',
        'pe.moduleId': 'PE-online Module ID',
        'pe.organization': 'Professional body',
        peerassessment: 'Peer assessment',
        peerAssessment: 'Peer assessment',
        percentageApproved: 'Percentage approved',
        'percentageApproved%': '% approved',
        period: 'Period',
        Periods: 'Periods',
        periodsInDays: 'Enrollment & Unenrollment deadline',
        permalink: 'Permalink',
        permissionRequestHasFailed: 'Permission request has failed',
        personalGroup: 'Personal',
        phaseOutDate: 'Phase out date',
        phaseOutDateContent: 'not available from {date}',
        phaseOutDateContentSkills: 'Not available from {date}',
        phone: 'Phone',
        placeOfBirth: 'Place of birth',
        plannedActivities: 'Planned activities',
        plannedSessions: 'Planned sessions',
        planners: 'Planners',
        planning: 'Planning',
        planningIsInPast: 'Planning is in the past',
        plannings: 'Plannings',
        planningInformation: 'Planning information',
        planningOverlap: 'There is overlap in your planning, please check your planning',
        planningOverlapButton: 'Overlap in planning',
        planningOverlapGeneral: 'There is a overlap in planning, please check planning',
        planningSelectContract: 'Select a valid contract!',
        pleaseChooseTheDesiredDateAndClickEnrollToRegisterForTheNewSchedule:
            'Please choose the desired date and click "Enroll" to register for the new planning',
        points: 'Points',
        portfolio: 'Dossier',
        PortfolioDefinitions: 'Portfolio items',
        PortfolioInstance: 'Portfolio item',
        PortfolioNew: 'New portfolio definition',
        PortfolioTasks: 'Portfolio tasks',
        PortfolioTasksAuthorize: 'Portfolio tasks - authorize',
        PortfolioTasksReview: 'Portfolio tasks - review',
        PortfolioTasksAccept: 'Portfolio tasks - accept',
        PortfolioAccept: 'Accept',
        PortfolioReject: 'Reject',
        PortfolioRemoveMarking: 'Remove marking',
        'portfolio.mandatory.heading.mandatoryTraining': 'Mandatory training',
        'portfolio.mandatory.heading.bestResult': 'Best result',
        'portfolio.mandatory.heading.certificate': 'Certificate',
        'portfolio.results.heading.course': 'Course',
        'portfolio.results.heading.lastAction': 'Last action',
        'portfolio.results.heading.bestResult': 'Best result',
        'portfolio.results.heading.certificate': 'Certificate',
        preference: 'Preference',
        presence: 'Presence',
        practical: 'Practical',
        practicalTest: 'Practical test',
        presenceOfTheDaysBefore: 'Presence of the days before',
        presenceNotFullyUpdated: 'Presence not fully updated',
        present: 'Present',
        presentOn: 'Present on',
        presentParticipants: 'Present participants',
        previousStep: 'Previous step',
        primaryBackgroundColor: 'Primary background color',
        primaryBackgroundColorDark: 'Primary dark background color',
        primaryBackgroundColorLight: 'Primary light background color',
        price: 'Price',
        Price: 'Price',
        Process: 'Process',
        PaymentValidity: 'Validity period (days)',
        DomainSpecificPrice: 'Domain Specific Prices',
        PortalSpecificPrice: 'Portalgroup Specific Prices',
        PortalText: 'Portal text',
        profile: 'Profile',
        ProfileOf: 'Profile of',
        profileImage: 'Profile image',
        profileSaved: 'Profile saved',
        progress: 'Progress',
        protocol: 'Protocol',
        question: 'Question',
        questionText: 'Question text',
        questionType: 'Question type',
        queue: 'Queue',
        queued: 'You are queued',
        Queued: 'Queued',
        QueuedAlreadyOnAPlanning: 'The person is already registered on queue of a planning.',
        queueThenAutomaticRegistration: 'Queue, then automatic registration',
        queueThenSendInvitation: 'Queue then send invitation',
        QueuedWhenFull: 'Queued when full',
        read: 'Read',
        readyForYou: 'Ready for you',
        readProtocol: 'Have you read the protocol?',
        readManual: 'Have you read the manual?',
        readless: 'Less',
        readmore: 'More',
        reason: 'Reason',
        receivedMessages: 'Received messages',
        receivedOn: 'Received on',
        receiverDomain: 'Receiver domain',
        recipients: 'Recipients',
        recommended: 'Recommended',
        redo: 'Redo',
        Redo: 'Redo',
        refresh: 'Refresh',
        refreshThePage: 'Refresh the page',
        register: 'Register',
        registered: 'Registered',
        Registered: 'Registered',
        registerFrom: 'On {date}',
        registeredOn: 'Registered on',
        registeredFrom: 'Registered from',
        registeredParticipants: 'Registered participants',
        registeredTill: 'Registered till',
        registerMe: 'Register me',
        registration: 'Registration',
        registrationClosed: 'Registration closed',
        registrationFailedTheAllowedNumberOfRegistrationsHasBeenReached:
            'Registration failed. The allowed number of registrations has been reached',
        registrationNumbers: 'Registration numbers',
        registrationNumbersSaved: 'Registration numbers saved',
        registrationPeriodHasExpired: 'Registration period has expired',
        registrationTransferPeriodExceededTitle: 'Transfer period expired',
        registrationTransferPeriodExceeded:
            'You want to transfer to another session but the transfer period of this session has expired.',
        'registrationNumbers.notAllowed': 'It is not possible to enter registration numbers.',
        relatedDocuments: 'Related documents',
        relatedDocumentsText:
            'By default these files are visible to anyone. If you want them to only be visible after someone enrolled into this course set the switch to enabled.',
        removedRole: '[removed role]',
        ReservationRevoked: 'Revoked',
        reservationCode: 'Reservation code (optional)',
        reservation: 'Reservation',
        reinstatedCancelledPlanning: 'Planning reinstated',
        reinstatePlanning: 'Reinstate planning',
        deleteCancelledPlanning: 'Planning deleted',
        RegionAlreadyExists: 'A region with this name already exists!',
        regionLocation: 'Region / location',
        Region_still_in_use: 'Region is used by a schedule, it cannot be deleted',
        rejectAuthSingle: 'You are about to reject {number} request',
        rejectAuthPlural: 'You are about to reject {number} requests',
        Rejected: 'Rejected',
        rejectedPortfolioItems: 'You have one rejected portfolio item',
        rejectedPortfolioItemsPlural: 'You have {number} rejected portfolio items',
        rejectedSelection: 'Selection rejected!',
        rejectedPartialSelection: 'Selection partially rejected!',
        remarkUser: 'User remark:',
        removeAppointment:
            'You already have an appointment, do you want to cancel this appointment?',
        removeFunctionUserDepartment:
            'User {username} has the functions below in this department. If this user no longer has a certain function, then uncheck it.',
        removeFunctionUserDepartment2ndLine:
            'Please note, some functions are obtained by an automatic import and therefore cannot be changed.',
        removeAllUsersFromVirtualQueueHeader: 'Remove all users from watchlist?',
        removeAllUsersFromVirtualQueueMessage:
            'This action removes all shown interest for this catalogitem',
        removedSelection: 'Selection removed!',
        removeFromList: 'Remove from list',
        removeFromVirtualQueue: 'Remove from watchlist',
        removeFromVirtualQueueDialogHeader: 'Remove yourself from watchlist?',
        removeFromVirtualQueueDialogMessage:
            'You will be removed from the watchlist of this catalogitem',
        removeFromVirtualQueueUserDialogHeader: 'Remove {displayName} from watchlist?',
        removeFromVirtualQueueUserDialogMessage:
            'This action will remove {displayName} from the watchlist of this catalogitem',
        removePlanningPermanently: 'Remove planning permanently?',
        reply: 'Reply',
        reporting: 'Reporting',
        reports: 'Reports',
        reportSelection: 'Report selection',
        'reports.department.mandatoryUsers.currentStatus': 'Current status',
        request: 'Request',
        requestDenied: 'Request denied',
        requestPending: 'Request pending',
        requests: 'Requests',
        required: 'Required',
        RequiredTrainingsWidgetInfo: 'Progress insight',
        'required and must be higher than zero': 'Required and must be higher than zero',
        requiredAndMustNotBeGreaterThan: 'Required and may not exceed {number} months',
        requiredAndMustNotBeGreaterThan1200: 'Required and may not exceed 1200 months',
        requiresTeacher: 'Requires teacher',
        'requiresTeacher.explainer':
            'A teacher must be scheduled for every session (of a planning)',
        reset: 'Reset',
        resetLearningPlan: 'Do again',
        resetProgress: 'Reset progress',
        revoked: 'Revoked',
        retry: 'Retry',
        remove: 'remove',
        RemoveCatalogInterestConfirm: `Are you sure you want to remove ''{displayName}'' as an interest?`,
        reregister: 'Register again',
        reservationCodeIsAlreadyUsed: 'The reservation code is already',
        reservationExpired: 'Expired',
        reservations: 'Reservations',
        reserved: 'Reserved',
        resetCourseTitle: 'Reset progress?',
        resetCourseFor:
            'It is possible to reset the progress of e-learning "{catalogName}" because it is about to expire, has already expired or because the e-learning has not been achieved by "{displayName}". All current progress of "{displayName}" will then be lost. It does not affect previous results.',
        resetCourseWarning: 'Are you sure you want to reset the progress of "{displayName}"?',
        resetFilter: 'reset filter',
        resetCatalogSettingHeader:
            'Reset e-Learning modules (this option can be overruled within the settings of a course)',
        resetCatalogSettingYes:
            'Allowed: participants are allowed to reset their progress of a course',
        resetCatalogSettingNo:
            'Not allowed: participants are not allowed to reset their progress of a course',
        resource: 'Resource',
        resources: 'Resources',
        resubmit: 'Resubmit',
        result: 'Result',
        resultFinal: 'Final result',
        results: 'Results',
        resultUnknown: 'Result unknown',
        Review: 'Review',
        reviewer: 'Reviewer',
        revoke: 'Revoke',
        revokeReservation: 'Revoke reservation',
        revokeConfirmation: 'Are you sure you want to revoke this reservation?',
        right: 'Right',
        Right: 'Right',
        Rights: 'Rights',
        RightsDescription: 'Other settings',
        Role: 'Role',
        roles: 'Roles',
        Satisfied: 'Satisfied',
        save: 'Save',
        saveAndContinue: 'Save and continue',
        saved: 'Saved',
        schedule: 'Session',
        scheduleDetail: 'Session details',
        scheduleDuration: 'Session duration',
        scheduleFull: 'Enrollment not possible, there are no open spots for this session',
        schedules: 'Sessions',
        scheduleMissesDuration: 'Session misses time duration',
        Schedule_not_found: 'Schedule not found!',
        schedulesOverlap: 'The sessions overlap',
        scheduleStartTimeEndTimeConflict: 'schedule start and end time conflict',
        scheduleTimeTooLong: 'Session duration too long',
        'sdb/uriECD': 'Go to SDB ECD',
        'sdb/uriHr': 'Go to SDB HR',
        'sdb/uriPayroll': 'Go to SDB Payroll',
        'sdb/uriPlanning': 'Go to SDB Planning',
        'sdb-add-examiner-appointment-dialog.button': `{ button, select,
            previous_step {Previous step}
            schedule_new_appointment {Schedule new appointment}
            schedule_appointment {Schedule appointment}
            other {}
        }`,
        'sdb-add-examiner-appointment-dialog.errors': `{ error, select,
            no_time_slots_available {It is currently not possible to schedule an appointment because there are no time slots available.}
            time_slot_already_reserved {This appointment conflicts with another one. Go back and try a different time slot or day.}
            examiner_not_qualified {The examiner is currently not qualified and is therefore not allowed to assess this activity.}
            other {An unknown error has occured. [{error}]}
        }`,
        'sdb-add-examiner-appointment-dialog.headline': `{ step, select,
            1 {Combine appointment or create new appointment}
            2 {Choose date and location}
            3 {Appointment on { date_and_location }}
            4 {Plan appointment}
            other {}
        }`,
        'sdb-add-examiner-appointment-dialog.description': `{ step, select,
            1 {There is time available in a previously scheduled appointment. Choose an appointment below to combine the appointment or schedule a new appointment.}
            2 {Choose a date and location to schedule an appointment.}
            3 {Choose the desired time slot.}
            4 {Would you like to schedule the appointment below?}
            other {}
        }`,
        'sdb-continue-elearning-widget.widgetInfo': `Continue directly with this e-learning`,
        'sdb-time-slot-details.activities-to-assess': `{activities, select,
            1 {Activity to assess}
            other {Activities to assess}
        }`,
        selfassessment: 'Self assessment',
        sendClassroomReminderMail: 'Send a reminder mail',
        'sendClassroomReminderMail.explainer':
            'Send an email to particpants before the course starts to remind them',
        sendClassroomReminderMailNumberOfDays: 'Days in advance',
        'settings.lookAndFeel': 'Look & Feel',
        'settings.lookAndFeel.uploadLogo': 'Upload logo',
        'settings.lookAndFeel.uploadHeroHeader': 'Upload header photo',
        'settings.lookAndFeel.heroheader': 'Show header photo on dashboard',
        'settings.lookAndFeel.heroheader.description':
            'Show a picture as visual introduction in the topsection of the dashboard',
        'settings.featurePreview.title': 'New (beta) features',
        'settings.featurePreview.setting': `{value, select,
            off {Off}
            admin {Administrators}
            everyone {Everyone}
            other {{value}}
        }`,
        setApprovalDate: 'Set basis date',
        skillDeleted: 'Skill deleted',
        skillDetails: 'Skill details',
        skillslabtraining: 'Skills lab training',
        score: 'Score',
        search: 'Search',
        searchAClassroomTraining: 'Search for a classroom training',
        searchAssignments: 'Search assignments',
        searchByDate: 'Search by date',
        searchByLocation: 'Search  by location',
        searchByName: 'Search by name',
        searchByNameDepartmentOrJobTitle: 'Search by name, department or job title',
        filterBySender: 'Filter by sender',
        searchBySenderName: 'Search by sender name',
        searchByTeacher: 'Search by teacher',
        searchDepartment: 'Search department',
        searchElearning: 'Search e-learning',
        searchForAMessageOrNotification: 'Search for a message / notification',
        searchForAUserWithSkills: 'Search for a user with skills',
        searchFunctions: 'Search functions',
        searchLocation: 'Search location',
        searchOn: 'Search on',
        searchOnCatalogName: 'Search on catalog name',
        searchOnDepartmentName: 'Search by department name',
        searchOnRoleName: 'Search by role name',
        searchRecipients: 'Search recipients',
        searchRole: 'Search role',
        searchPackage: 'Search package',
        searchSkill: 'Search skill',
        searchTool: 'Search tool',
        secondAbout: '{number} second',
        secondaryBackgroundColor: 'Secondary background color',
        secondaryBackgroundColorDark: 'Secondary dark background color',
        secondaryBackgroundColorLight: 'Secondary light background color',
        secondAgo: '{number} second ago',
        secondsAbout: '{number} seconds',
        secondsAgo: '{number} seconds ago',
        selectACatalogItemToAssignAcertificateTo: 'Select a catalog item to assign to',
        selectAll: 'Select all',
        selectAll2: 'Select all',
        selectAnActivity: 'Select an activity',
        SelectACertificate: 'Select a certificate',
        selectAPeriod: 'Select a period',
        selectAPlanning: 'Select a planning',
        SelectASkillToChangeAssignments: 'Select a skill to change assignments',
        selectATrainingToAdd: 'Select a training to add',
        selectAssignmentOption: 'Select assignment option:',
        selectCategory: 'Select category',
        selectCompetenceAndFunctions: 'Select competence and roles',
        selectDepartment: 'Select department',
        selectDomain: 'Select domain',
        selectDownloadType: 'Select download type',
        selectEmployee: 'Select employee',
        selectExportToMakeAnotherUserAssessorForTheSameActivitiesAsThisUser: `Select ''EXPORT'' to make another user assessor for the same activities as this user.`,
        SelectExternalLearningPlan: 'Select external e-learning(s)',
        selectFile: 'Select file',
        selectFunctions: 'Select functions',
        SelectPhoto: 'Select photo',
        SelectManagerCatalogAuthorization: 'Select a manager to submit the request to',
        selectReviewer: 'Select your reviewer',
        selectScormItem: 'Select scorm item',
        selectStatus: 'Select status',
        selectTargetType: 'Select type',
        selectType: 'Select type',
        selectReportType: 'Select report type',
        send: 'Send',
        sendAMessage: 'Send a message',
        sendMessage: 'Send message',
        senderDomain: 'Sender domain',
        sendUserConfirmationMail: 'Send a confirmation by e-mail',
        sentMessages: 'Sent messages',
        session: 'session',
        sessionName: 'Session name',
        sessions: 'sessions',
        settings: 'Settings',
        showEmptyValues: 'Show empty values',
        showExpiredCatalogItems: 'Show expired items',
        skill: 'Skill',
        skillTitle: 'Skill title',
        skills: 'Skills',
        skillsReport: 'Skills report',
        skillsReportWidgetInfo: 'Monitor skill progress',
        skillsAlmostExpired: 'almost expired',
        skillsExpired: 'expired',
        skillslabtest: 'Skills lab test',
        skillsPassport: 'Skills passport',
        'SkillsReport.WhatToExport': 'What do you want to export?',
        'SkillsReport.SummaryExport': 'Skills summary',
        'SkillsReport.TotalExport': 'Overview of all skills including department & function',
        'SkillsReport.Present': 'Present',
        'SkillsReport.DisplayProvenSkills': 'Display proven skills',
        'SkillsReport.History': 'History',
        'SkillsReport.HistoryConflictNoDate': 'Select a date',
        'SkillsReport.HistoryConflictNoType': 'Select a report type',
        'SkillsReport.HistoryConflictNoUser': 'Select a user',
        'SkillsReport.HistoryConflictMessage': 'Select a date and the preferred history data',
        'SkillsReport.PresentOverview': 'Present overview ',
        'SkillsReport.HistoryOverview': 'History overview',
        'SkillsReport.SelectUser': 'Select a user',
        'SkillsReport.ShowHistory': 'Show history of...',
        'SkillsReport.SpecificUser': 'Specific user',
        'SkillsReport.ProvenSkills': 'Proven skills',
        showAll: 'Show all',
        showAlsoExpiredAndFutureCatalogItems: 'Also show expired / future items',
        showAssessment: 'Show assessment',
        showDepartments: 'Show departments',
        showDisabledAccounts: 'Show disabled accounts',
        showExpiredSchedules: 'Show expired plannings',
        showForm: 'Show form',
        showFutureCatalogItems: 'Show future items',
        showOnly: 'Show only',
        showOverview: 'Show overview',
        signIn: 'Sign in',
        signInToUse: 'Sign in to use SDB Leerplatform',
        signOut: 'Sign out',
        size: 'Size',
        someoneElse: 'Someone else',
        sortBy: 'Sort by',
        sortByDate: 'Sorteer op datum',
        sortedByDate: 'Sorted by date',
        spaceFillingProportionalStandard: 'Space-filling, proportional (standard)',
        ssosearch: 'Search user',
        ssoUserInformation: 'User account',
        ssoUserOrGroup: 'User / group',
        start: 'Start',
        startDate: 'Start date',
        startDates: 'Planning',
        started: 'Started',
        Started: 'Started',
        startedOn: 'Started on',
        StartTime: 'Start time',
        statusAll: 'All',
        statusAccept: 'For accept',
        startedStatus: 'Started',
        statusReview: 'For review',
        statusReject: 'Rejected',
        Status: 'Status',
        statusCompleted: 'Accepted/Completed',
        stillToDo: 'still to do',
        Strikethrough: 'Strikethrough',
        stronglyAgree: 'Strongly Agree',
        stronglyDisagree: 'Strongly Disagree',
        studyLoad: 'Study load',
        style: 'Style',
        subject: 'Subject',
        submit: 'Submit',
        submitAssessment: 'Submit assessment',
        submitForm: 'Submit form',
        submitRequest: 'Submit request',
        submitCatalogAuthorizationRequest:
            'You are about to file a request with your manager to follow this course. These are the details:',
        subtitle: 'Subtitle',
        subDepartment: 'Subdepartment',
        subDepartments: 'Subdepartment(s)',
        SuccesRegistrationIsPossible: 'It is possible to register the person on this planning.',
        Summary: 'Summary',
        tabDetails: 'Details',
        tabBijlagen: 'Attachments',
        tabComments: 'Remarks',
        'targetType.all': 'All',
        'targetType.learningplan': 'E-learning',
        'targetType.composition': 'E-learning',
        'targetType.classroom': 'Classical',
        'targetType.expertise': 'Personal development',
        'targetType.microlearning': 'Micro Learning',
        'targetType.scorm': 'Scorm',
        'targetType.lti': 'LTI',

        'targetType.microlearning.video': 'Video',
        'targetType.microlearning.movie': 'Video',
        'targetType.microlearning.audio': 'Audio',
        'targetType.microlearning.animation': 'Animation',
        'targetType.microlearning.infographic': 'Infographic',
        'targetType.microlearning.exercise': 'Exercise',
        'targetType.microlearning.microcourse': 'Microcourse',
        'targetType.microlearning.text': 'Text',
        'targetType.microlearning.test': 'Test',
        'targetType.microlearning.pretest': 'Sssessment',
        'targetType.microlearning.complete': 'E-learning',
        'targetType.microlearning.elearning': 'E-learning',

        tcgPasswordStrength: {
            Label: 'strength',
            None: 'none',
            VeryWeak: 'very weak',
            Weak: 'weak',
            Medium: 'medium',
            Strong: 'strong',
            VeryStrong: 'very strong',
        },
        Taught: 'Taught',
        teacher: 'Teacher',
        teacherOverviewDescription: 'Overview teachers',
        teachers: 'Teachers',
        teacherIsMandatory: 'Teacher is mandatory',

        Temporary: 'Temporary',
        temporaryParticipants: 'Temporary registered participants',
        temporaryParticipantsTab: 'Temporary enrollements',
        template: 'Template',
        tertiaryBackgroundColor: 'Tertiary background color',
        testResult: 'Result',
        text: 'Text',
        textField: 'Text field',
        theBlockMustAtleastContainAquestion: 'The block must at least contain one question',
        theActivityIsNoLongerAvailable: 'The activity is no longer available',
        theAllowedNumberOfRegistrationsHasBeenReached:
            'The allowed number of registrations for this user has been reached',
        theAllowedNumberOfRegistrationsForMyselfHasBeenReached:
            'You have reached the maximum number of enrollments for this course',
        theAllowedNumberOfRegistrationsForThisUserHasBeenReached:
            'This user has reached the maximum number of enrollments for this course',
        theColleagueWhoWillAssessYou: 'The colleague who will assess you',
        theFirstBeginsOn: 'the first begins on',
        theFollowingParticipantsAreStillRegisteredOnTheSchedule:
            'The following participants are still registered on the schedule:',
        TheLearningPlansThatWillGetActivated: 'The e-learnings that will get activated',
        theListOfLinkedSkills: 'Linked skills',
        themeColors: 'Theme colors',
        themeColorPrimary: 'Primary theme color',
        themeColorSecondary: 'Secondary theme color',
        themeColorTertiary: 'Tertiary theme color',
        themeColorButton: 'Button color',
        themeColorProgress: 'Progressbar color',
        themeColorProgress100: 'Progressbar 100% color',
        themeDescription:
            'Easily set 2 theme colors. The text color and the supporting colors will be automatically calculated. Not exactly what you want? Set the colors yourself below!',
        theMinimumNumberOfMandatoryActivitiesIs:
            'The minimum number of mandatory activities is {number}',
        theNextActivityStarts: 'Next activity:',
        theNextLessonStarts: 'Next activity:',
        theNextOneIsOn: 'the next one is on',
        thereAreEvaluationRequests: 'Evaluate your training',
        thereAreListsThatNeedToBeUpdated: 'Update attendance lists',
        thereAreNoAssessmentsFound: 'There are no assessments found',
        thereAreNoAssessmentsFoundTitle: 'No assessments',
        ThereArenoAssignmentsFound: 'There are no assignments found',
        thereAreNoDepartmentsFound: 'There are no departments found',
        thereAreNoDocuments: 'There are no documents',
        thereAreNoFormsFound: 'There are no forms found',
        thereAreNoFormsFoundTitle: 'No forms',
        thereAreNoFunctionsLinkedToThisSkill: 'There are no functions linked to this skill',
        thereAreNoFuturePlannedLessons: 'There are no future planned lessons',
        thereAreNoListsToUpdate: 'There are no lists to update',
        thereAreNoMessageFound: 'There are no messages found',
        thereAreNoMessageTitle: 'No messages found',
        thereAreNoOpenAssessmentRequests: 'There are no open assessment requests',
        thereAreNoPastPlannedLessons: 'There are no expired lessons found',
        thereAreNoParticipantsConnectedToThisSchedule:
            'There are no participants connected to this planning',
        thereAreNoParticipantsFound: 'There are no participants found',
        thereAreNoRequiredTrainings: 'There are no required trainings',
        thereAreNoRequiredTrainingsFound: 'No required trainings found',
        thereAreNoRequiredTrainingsAssigned: 'No required training assigned',
        thereAreNoSkillsCategoriesFoundTitle: 'No skills categories found',
        thereAreNoSkillsCategoriesFoundText: 'Add a skills category or reset filters',
        thereAreNoSkillsFound: 'There are no skills found',
        thereAreNoSkillsLinked: 'There are no skills linked',
        thereAreNoSkillsFoundTitle: 'No skills found',
        thereAreNoSkillsFoundText: 'Try changing the filter options',
        thereAreOpenAssessmentRequests: 'There are open assessment requests',
        thereAreThecomingDays: 'There are the coming',
        thereIsAccreditationFound: 'There is no accreditation found',
        thereIsAnEvaluationRequests: 'Evaluate your training',
        thereIsNoIndividualConnected: 'There is no individual connected',
        thereIsNoLocationSelected: 'There is no location selected',
        thereIsNoTeacherSelected: 'There is no teacher selected',
        theSelectedAssessor: 'The selected assessor',
        theorems: 'Theorems',
        thisElearningCourseIs: 'This E-learning course is',
        thisExaminerIsNotSkilled: 'This examiner is not skilled',
        thisMinervaPortalDoesNotExist: 'This SDB Leerplatform portal does not exist',
        thisScheduleIsFullTheNewRegistrationsWillBeQueued:
            'This schedule is full. The new registrations will be queued',
        thisScheduleConflictsWithAnotherScheduleForWhichThisParticipantIsRegistered:
            'This schedule conflicts with another schedule for which this participant is registered',
        thisScheduleConflictsWithAnotherScheduleForWhichYouAreRegistered:
            'This schedule conflicts with another schedule for which you are registered',
        thisScheduleCannotBeEditedBecauseItIsInTheFuture:
            'This schedule can not be edited because it is in the future',
        time: 'Time',
        Title: 'Title',
        to: 'To',
        today: 'Today',
        todolist: 'Todolist',
        tool: 'Tool',
        toolDeleted: 'Tool deleted',
        tools: 'Tools',
        Tooltip: 'Tooltip',
        toPlannings: 'To plannings',
        total: 'Total',
        totalAfterANumber: 'total',
        totalNumberOfEvaluations: 'Total number of evaluations',
        TotalNumberOfHours: 'Total number of hours',
        training: 'Training',
        trainingDeleted: 'Training deleted',
        trainingInformation: 'Training information',
        trainingonthejob: 'Training on the job',
        trainingsStarted: 'started',
        trainingType: 'Training type',
        transferEnrollment: 'Transfer',
        transferInsufficientRights: 'You have insufficient rights to transfer',
        true: 'yes',
        toj: 'Training on the job',
        trainings: 'Trainings',
        translate: 'Translate',
        types: 'types',
        Uncouple: 'Uncouple',
        UncoupleEmployee: 'Uncouple employee',
        Underline: 'Underline',
        underlyingDepartments: 'Underlying departments',
        Undo: 'Undo',
        unenroll: 'unenroll',
        unenrollment: 'Unenrollment',
        unenrollmentDeadline: 'Unenrollment deadline',
        unenrollmentDeadlineInDays: 'Unenrollment deadline in days',
        units: {
            size: ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        },
        uniqueUsers: 'Unique users',
        'Unordered List': 'Unordered List',
        unknown: 'Unknown',
        Unknown: 'Unknown',
        unKnownOn: 'Unknown on',
        unlockAccount: 'Unlock account',
        unregister: 'Unregister',
        unRegisterInsufficientRights: 'You have insufficient rights to unenroll.',
        unregisterOrTansferOptions: `{ option, select,
            myUnregister {You are not allowed to unregister because your attendance / result has already been saved.}
            myTransfer {You are not allowed to transfer because your attendance / result has already been saved.}
            someoneElseUnregister {You can't unregister this employee, because the attendance / result has already been saved.}
            someoneElseTransfer {You can't transfer this employee, because the attendance / result has already been saved.}
            other {{option}}
        }`,
        unRegisterPeriodExceeded: 'It is no longer possible to unenroll.',
        unRegisterPeriodExceededTitle: 'Unenroll period expired',
        unsupportedBrowserHeader: 'Outdated browser',
        unsupportedBrowserMessage:
            'You are using a browser we no longer support. Update your browser for a better experience!',
        until: 'until',
        untilTime: 'Until',
        up: 'Up',
        upcomingTrainings: 'Upcoming trainings',
        UpdateValidityForExistingAssignments: 'Overwrite validity for existing assignments',
        upload: 'Upload',
        uploadAutomatically:
            'Attachments in the queue will be automatically uploaded when you save the dossier item.',
        uploadCertificateBackgroundImage1: 'Background image',
        uploadCertificateBackgroundImage2:
            'A 150dpi image at 1240px by 1754px is sufficient for a printed A4.',
        uploading: {
            status: {
                connecting: 'Connecting...',
                stalled: 'Stalled.',
                processing: 'Processing File...',
                held: 'Queued',
            },
        },
        unableToRemoveAccreditation: 'Unable to delete accreditation',
        unregistered: 'Unregistered',
        Unregistered: 'Unregistered',
        unregisteredParticipants: 'Unregistered participants',
        updateAttendance: 'Update attendance',
        url: 'URL',
        URL: 'URL',
        usageReport: 'Usage Report',
        'usageReport.subtitle': 'E-learnings & classical courses',
        'usageReport.generate': 'Generate report',
        'usageReport.usageUniqueCoursesUsers': 'Number of unique users that started this course',
        'usageReport.usageUniqueUsers': 'Number of unique users that started this e-learning',
        'usageReport.activeUsersPeriod': 'Active users in selected period',
        'usageReport.elearningDetails.user': 'User',
        'usageReport.elearningDetails.searchUser': 'Search user',
        'usageReport.elearningDetails.status': 'Status',
        'usageReport.info.usersStartedBetween': 'Users started between {selectedDates}',
        useCustomValue: 'Use custom value',
        useDefault: 'Use default',
        useExaminerFunctionality: 'Use examiner functionality',
        UseOnlyDefaultTemplates: 'Use only default mail templates',
        user: 'User',
        'user.course.result.validUntil': 'Valid until',
        userGroupDeleted: 'User/group deleted',
        userProfile: 'User profile',
        users: 'Users',
        userInfo: 'User information',
        uservoiceIcon: 'Submit an improvement',
        userManagement: 'Usermanagement',
        usersName: `User''s name`,
        vacantPlace: 'seat',
        vacantPlaces: 'seats',
        valid: 'Valid',
        validity: 'Validity',
        validityPeriodInMonths: 'Validity period in months',
        validUntil: 'Valid until',
        Version: 'Version',
        version: 'version',
        refresher: 'Refresher',
        viewComment: 'View comment',
        viewPlanning: 'View planning',
        viewPreconditions: 'View preconditions',
        viewSkill: 'View skill',
        viewTheClassActivitiesForWhichYouAreRegistered:
            'View the class activities for which you are registered',
        viewYourScheduledLessons: 'View your scheduled lessons',
        virtualQueue: 'Watch list',
        week: 'Week',
        weekAbout: '{number} week',
        weekAgo: '{number} week ago',
        weekOverview: 'Week overview',
        weekOverviewReport: 'Week overzicht report',
        weeks: 'Weeks',
        weeksAbout: '{number} weeks',
        weeksAgo: '{number} weeks ago',
        welcomeToTCGMinerva: 'Welcome to the learning environment',
        whatAreYouSearchingFor: 'What are you searching for?',
        when: 'When',
        WhoDoYouWantToRegister: 'Register',
        width: 'Width',
        withdrawTheApplication: 'Withdraw the application',
        WithCertificate: 'With certificate',
        WithoutCertificate: 'Without certificate',
        workLearningMethods: 'Work forms & Learning methods',
        workPhone: 'Work phone',
        writtentest: 'Written test',
        year: 'Year',
        years: 'Years',
        yearAbout: '{number} year',
        yearsAbout: '{number} years',
        yearAgo: '{number} year ago',
        yearsAgo: '{number} years ago',
        yes: 'Yes',
        youAreAlreadyRegistered: 'You are already registered',
        youAreNotAuthorizedToViewThisInformation:
            'You are not authorized to view this information.',
        youCurrentlyHaveNoLesson: 'You currently have no lesson',
        YouDoNotHaveAddedFavorites: 'You do not have added favorites.',
        youHaveMadeChangesDoYouWantToContinueWithoutSavingIt:
            'You have made changes. Do you want to continue without saving it?',
        youHaveNoLessonInTheNextSelectedDays: 'You have no lesson in the next selected days',
        youMustAddRecipients: 'You must add recipients',
        YourDepartments: 'Your departments',
        YourFavoriteCourses: 'Your favorite courses',
        YouAreSignedInAsAndDoesNotHaveRightsOn:
            'You are signed in as {name} and with this account you have no rights to enter {portal}',
        youWillBeAssessedBy: 'You will be assessed by',
        yourProgress: 'Your progress',
        viewYourProgress: 'View your progress',
        zipCode: 'Zip code',

        showChildDepartmentsWhenNotManager:
            'Show child departments to a manager of a parent department',
        'ssoManagement.mainTitle': 'SSO Management',
        'ssoManagement.pageInfo': 'SSO Management PageInfo',
        'ssoManagement.listHeading': 'SSO List',
        'ssoManagement.addSsoUser': 'Create SSO User',
        'ssoManagement.updateSsoUser': 'Update SSO User',
        'ssoManagement.enableDisableLabel': 'Enable/Disable',
        'ssoManagement.lockedOut': 'Locked Out',
        'ssoManagement.disabledSince': 'Disabled per',

        'ssoManagement.logOnName': 'Logon',
        'ssoManagement.logOnAlias': 'Logon Alias',
        'ssoManagement.displayName': 'Displayname',
        'ssoManagement.description': 'Description',
        'ssoManagement.emailAddress': 'Email Address',

        'ssoManagement.password': 'Password',
        'ssoManagement.resetPassword': 'Reset Password',

        'ssoManagement.filter.enabled': 'Enabled',
        'ssoManagement.filter.disabled': 'Disabled',
        'ssoManagement.filter.lockedOut': 'Locked Out',

        'ssoManagement.tab.users': 'Users',
        'ssoManagement.tab.groups': 'Groups',

        'ssoManagement.actions.changePassword': 'Change password',
        'ssoManagement.actions.generatePassword': 'Generate password',
        'ssoManagement.actions.sendResetLink': 'Send reset link',
        'ssoManagement.actions.unlock': 'Unlock',
        'ssoManagement.actions.enableDisable': `{ disabled, select,
            true {Enable}
            false {Disable}
            undefined {Disable}
            other {{disabled}}
        }`,

        'ssoManagement.errors.mustBeUnique': `Logonname and LogonAlias must be different!`,
        'ssoManagement.errors.alreadyInUse': `Logonname is already in use!`,

        'ssoGroupManagement.groupDetails': 'Group details',
        'ssoGroupManagement.mainTitle': 'SSO Group Management',
        'ssoGroupManagement.pageInfo': 'SSO Group Management PageInfo',
        'ssoGroupManagement.listHeading': 'SSO Group List',
        'ssoGroupManagement.addSsoGroup': 'Create SSO Group',
        'ssoGroupManagement.updateSsoGroup': 'Update SSO Group',
        'ssoGroupManagement.updateSsoGroupMembers': 'Update SSO Group Members',
        'ssoGroupManagement.memberOf': 'Member Of',

        tags: 'Tags',
        labels: 'Labels',
        addLabel: 'Add Label',
        noLabels: 'No Labels',

        Supplier: 'Supplier',

        supplierTags: 'Supplier Tags',
        addTag: 'Add Tag',
        noTags: 'No Tags',

        'scormManagement.mainTitle': 'SCORM Management',
        'scormManagement.pageInfo': 'SCORM Management PageInfo',
        'scormManagement.listHeading': 'SCORM List',
        'scormManagement.uploadScormPackage': 'Upload Scorm 1.2 (.zip) Package',

        'scormManagement.filter.zipped': 'Zipped',
        'scormManagement.filter.unzipped': 'Unzipped',

        'scormManagement.actions.upload': 'Upload',
        'scormManagement.actions.delete': 'Delete',

        'tcgLearningPlan.resetDialogTitle': 'Do again?',
        'tcgLearningPlan.resetDialogBody':
            'Are you sure you want to do this e-learning again? All your current progress will then be lost. It does not affect previous results.',
        'tcgLearningPlan.somethingWentWrong': 'Something went wrong',
        'tcgLearningPlan.retryProcessProgress': 'Retry to save your progress.',
        'tcgLearningPlan.resetIfNotWorking': 'Not working? Reset the course to start over.',
        'tcgLearningPlan.handInDialogTitle': 'Hand in?',
        'tcgLearningPlan.handInDialogText':
            'Are you sure you want to hand in {title}? It is not possible to make changes after hand in!',
        'tcgLearningPlan.handIn': 'Hand in',
        'tcgLearningPlan.skip': 'Skip',
        'tcgLearningPlan.completed': 'Completed',
        'tcgLearningPlan.open': 'Open',
        'tcgLearningPlan.notAvailableYet': 'Not available',
    },
};
