import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import {hasRight} from '../utils/rights';

export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const SAVE_SETTINGS = 'SAVE_SETTINGS';
export const ADD_ADMIN = 'ADD_ADMIN';
export const ADD_REVIEWER = 'ADD_REVIEWER';
export const REMOVE_ADMIN = 'REMOVE_ADMIN';
export const REMOVE_REVIEWER = 'REMOVE_REVIEWER';

const getHeaders = (user) => {
    if (user) {
        return {
            Authorization: `Bearer ${user.access_token}`,
        };
    } else return {};
};

function* doFetchSettings() {
    const state = yield select();
    const oidc = state.oidc;
    const idString = oidc.user ? oidc.user.profile.idString : null;
    const headers = getHeaders(oidc.user);
    const environment = yield call(fetchEnvironmentSettings);

    const portalSettings = yield call(fetchPortalSettings, environment.settingsUrl, headers);

    if (portalSettings.notFound) {
        yield put({
            type: 'MINERVASETTINGS_NOT_FOUND',
            payload: {
                ...portalSettings,
            },
        });

        return;
    }

    if (portalSettings.forceLogout) {
        const settings = {
            settings: {},
            forceLogout: true,
        };

        // Yield empty settings to prevent further loading
        yield put({
            type: 'MINERVASETTINGS_RECEIVED',
            payload: {
                ...settings,
            },
        });

        return;
    }

    // convert enabledFeatureFlags to object with keys as truthy booleans
    // Example: when feature with key 'uservoice' is enabled settings.enabledFeatureFlags.uservoice is true
    portalSettings.enabledFeatureFlags = portalSettings.enabledFeatureFlags.length
        ? Object.assign(...portalSettings.enabledFeatureFlags.map((x) => ({[x]: true})))
        : {
              /* no features enabled */
          };

    if (!portalSettings.enabledFeatureFlags.HLIntegrationEnabled) {
        delete environment.hlUrl;
    }

    portalSettings.planningIntegrationEnabled =
        portalSettings.planningIntegrationEnabled ||
        portalSettings.enabledFeatureFlags.planningIntegration;

    if (!portalSettings.planningIntegrationEnabled) {
        delete environment.planningIntegrationApiUrl;
    }

    if (portalSettings.shouldOpenWelcomeVideo === 'undefined') {
        portalSettings.shouldOpenWelcomeVideo = true;
    }

    let skillsSettingsPromise;
    let rights = [];
    let departmentsWhereManager = [];
    let departmentsWhereManagerRights = [];
    let domain = {};
    let isManager = false;

    if (oidc.user) {
        if (window.appInsights && idString) {
            window.appInsights.setAuthenticatedUserContext(
                idString.replace('\\', '\\\\').replace(/[,;=| ]+/g, '_'),
            );

            if (!window.appInsights_roleSet) {
                const telemetryInitializer = (envelope) => {
                    envelope.tags['ai.cloud.role'] = 'SDB Leerplatform';
                    envelope.tags['ai.cloud.roleInstance'] = portalSettings.portalIdentifier;
                };

                window.appInsights.addTelemetryInitializer(telemetryInitializer);
                window.appInsights_roleSet = true;
            }
        }

        if (portalSettings.skillsEnabled) {
            skillsSettingsPromise = call(
                fetchSkillsSettings,
                environment.skillsApiUrl,
                portalSettings.portalIdentifier,
                headers,
            );
        } else {
            // set skillsApiUrl to null to prevent requests to the api
            environment.skillsApiUrl = null;
        }

        let departmentsWhereManagerResult;

        [rights, departmentsWhereManagerResult] = yield all([
            call(fetchUserRights, portalSettings, environment, headers),
            call(fetchDepartmentsWhereManager, portalSettings, environment, headers),
        ]);

        departmentsWhereManager = departmentsWhereManagerResult.departmentIdsWhereManager;
        departmentsWhereManagerRights =
            departmentsWhereManagerResult.departmentIdsWhereManagerRights;

        isManager = departmentsWhereManagerRights && departmentsWhereManagerRights.length > 0;

        if (isManager) {
            rights = _injectManagerRights(rights);
        }

        const lowerIdString = idString.toLowerCase();
        const tempDomain = portalSettings.signInDomains.find((x) =>
            lowerIdString.startsWith(`//${x.domainId.toLowerCase()}//`),
        );

        if (tempDomain) {
            domain = tempDomain;
        }
    }

    const skillsSettings = skillsSettingsPromise ? yield skillsSettingsPromise : {};

    const settings = {
        ...portalSettings,
        ...environment,
        notFound: false,
        headers,
        idString,
        skillsSettings,
        isManager,
        departmentsWhereManager,
        departmentsWhereManagerRights,
        hideClassroomParticipants: domain.hideClassroomParticipants,
        profileReadonly: domain.profileReadonly,
        anonymizeParticipantsFromOtherDomains: domain.anonymizeParticipantsFromOtherDomains,
        portfolioLandingSuffix: portalSettings.skillsEnabled ? '/skills/list' : '/progress/list',
        rights,
        departmentSkillsMatrixViewEnabled: true,
    };

    const adminMenu = generateAdminMenu(settings);
    const mainMenu = generateMainMenu(settings, adminMenu);

    yield put({
        type: 'MINERVASETTINGS_RECEIVED',
        payload: {
            ...settings,
            mainMenu,
            adminMenu,
        },
    });
}

function _injectManagerRights(rights) {
    const result = new Set(rights);

    result.add('classroom.manage_enrollments');

    return Array.from(result);
}

function* fetchEnvironmentSettings() {
    const response = yield call(fetch, '/environment.json');

    return yield call([response, response.json]);
}

function* fetchPortalSettings(settingsUrl, headers) {
    const response = yield call(fetch, `${settingsUrl}/api/settings`, {
        headers,
    });

    if (response.status === 404) {
        return {
            settings: {},
            notFound: true,
        };
    }

    return yield call([response, response.json]);
}

function* fetchSkillsSettings(skillsApiUrl, portalIdentifier, headers) {
    const response = yield call(fetch, `${skillsApiUrl}/api/6.0/settings/${portalIdentifier}`, {
        headers,
    });

    if (response.status === 404) {
        return {};
    }

    return yield call([response, response.json]);
}

function* fetchUserRights(portalSettings, environment, headers) {
    if (portalSettings.isAdmin) {
        return ['*']; // use '*' as special case for all rights
    }

    if (headers.Authorization) {
        const rightsResponse = yield call(
            fetch,
            `${environment.accessManagementApiUrl}/api/rights/${portalSettings.portalIdentifier}/identity`,
            {
                headers,
            },
        );

        return yield call([rightsResponse, rightsResponse.json]);
    }

    return [];
}

function* fetchDepartmentsWhereManager(portalSettings, environment, headers) {
    const response = yield call(
        fetch,
        `${environment.academyDepartmentsUrl}/api/1.0/departmentsWhereManager/${portalSettings.portalIdentifier}`,
        {
            headers,
        },
    );

    const result = yield call([response, response.json]);

    return result;
}

function generateMainMenu(settings, adminMenu) {
    const menu = [];

    addMenuItem(menu, 'start', '/', 'fal fa-house');
    addMenuItem(menu, 'catalog', '/training/list/all');

    if (!settings.isAdmin && settings.departmentsWhereManager.length) {
        addMenuItem(menu, 'departments', '/department/list');

        if (settings.skillsEnabled) {
            addMenuItem(menu, 'skills', '/skills/actions');
        }
    }

    if (!settings.departmentsWhereManager.length && settings.isDepartmentReviewer) {
        addMenuItem(menu, 'departments', '/department/list');
    }

    if (settings.isAdmin) {
        addMenuItem(menu, 'departments', '/department/list');
        if (settings.skillsSettings.onlyAssignToFunctions) {
            addMenuItem(menu, 'functions', '/roles');
        }
    }

    if (settings.enabledFeatureFlags.HLIntegrationEnabled) {
        addMenuItem(menu, 'hlapps', '/hl-dashboard');
    }

    addMenuItem(menu, 'myMinerva', `/user/my${settings.portfolioLandingSuffix}`);

    if (adminMenu.length) {
        addMenuItem(menu, 'administration', `/admin/${adminMenu[0].link}`);
    }

    return menu;
}

function generateAdminMenu(settings) {
    const menu = [];

    if (settings.isAdmin) {
        addMenuItem(menu, 'settings', `settings/general`, 'fal fa-gear');
    }

    if (settings.isAdmin) {
        addMenuItem(menu, 'catalog', `catalog/assign`, 'fal fa-album-collection');
    }

    if (settings.isAdmin) {
        if (
            settings.enabledFeatureFlags.lti ||
            settings.enabledFeatureFlags.scorm ||
            settings.enabledFeatureFlags.compositions
        ) {
            addMenuItem(menu, 'compositionmanagement', `compositionmanagement/lti`, 'fal fa-cubes');
        }

        if (settings.evaluationEnabled) {
            addMenuItem(menu, 'evaluations', `evaluations`, 'fal fa-clipboard-list-check');
        }
        if (settings.expertiseEnabled) {
            addMenuItem(menu, 'definities', `definities/list`, 'fal fa-folder-open');
        }
    }

    if (settings.classroomEnabled) {
        if (settings.isAdmin) {
            const link = `classroom/locations`;

            addMenuItem(menu, 'classroom', link, 'fal fa-screen-users');
        }
    }

    if (settings.isAdmin) {
        if (settings.skillsEnabled) {
            addMenuItem(menu, 'skills', `skills/actions`, 'fal fa-shield-check');
        }
        addMenuItem(menu, 'certificates', 'certificates/list', 'fal fa-file-certificate');
        addMenuItem(menu, 'examinermanagement', 'examinermanagement/assessors', 'fal fa-gear');
        addMenuItem(menu, 'importmanagement', `importmanagement`, 'fal fa-file-import');
    }

    if (hasRight(settings.rights, 'roles_and_rights')) {
        const link = `usermanagement/${settings.isAdmin ? 'ssomanagement' : 'rolemanagement'}`;

        addMenuItem(menu, 'usermanagement', link, 'fal fa-users-gear');
    }

    if (settings.isAdmin) {
        addMenuItem(menu, 'maillogoverview', `maillogoverview`, 'fal fa-envelope');
    }

    return menu;
}

function addMenuItem(menu, label, link, icon = null) {
    if (menu.some((el) => el.label === label)) return; // item already exists

    menu.push({
        label,
        active: menu.length === 0,
        link,
        icon,
    });
}

// export const saveSettings = (settings) => async (dispatch, getState) => {
//     const {minervaSettings, oidc} = getState();
//     const url = `${minervaSettings.settingsUrl}/api/settings/${minervaSettings.portalIdentifier}`;
//     const body = JSON.stringify(settings);

//     await dispatch({
//         type: SAVE_SETTINGS,
//         async payload() {
//             // console.log("save starting");
//             const response = await fetch(url, {
//                 headers: {
//                     ...getHeaders(oidc.user),
//                     'Content-Type': 'application/json',
//                 },
//                 method: 'PUT',
//                 body: body,
//             });

//             const savedSettings = await response.json();

//             return savedSettings;
//         },
//     });
// };

// export const addAdmin = (user) => async (dispatch, getState) => {
//     const {minervaSettings, oidc} = getState();
//     const url = `${minervaSettings.settingsUrl}/api/admin/${minervaSettings.portalIdentifier}`;
//     dispatch({
//         type: ADD_ADMIN,
//         async payload() {
//             const response = await fetch(url, {
//                 headers: {
//                     ...getHeaders(oidc.user),
//                     'Content-Type': 'application/json',
//                 },
//                 method: 'PUT',
//                 body: JSON.stringify(user),
//             });

//             await dispatch(fetchSettings(oidc.user));
//         },
//     });
// };

// export const addReviewer = (user) => async (dispatch, getState) => {
//     const {minervaSettings, oidc} = getState();
//     const url = `${minervaSettings.settingsUrl}/api/departmentReviewer/${minervaSettings.portalIdentifier}`;

//     dispatch({
//         type: ADD_REVIEWER,
//         async payload() {
//             const response = await fetch(url, {
//                 headers: {
//                     ...getHeaders(oidc.user),
//                     'Content-Type': 'application/json',
//                 },
//                 method: 'PUT',
//                 body: JSON.stringify(user),
//             });

//             await dispatch(fetchSettings(oidc.user));
//         },
//     });
// };

// export const removeAdmin = (idString) => async (dispatch, getState) => {
//     const {minervaSettings, oidc} = getState();
//     const url = `${minervaSettings.settingsUrl}/api/admin/${minervaSettings.portalIdentifier}?idString=${encodeURIComponent(idString)}`;

//     dispatch({
//         type: REMOVE_ADMIN,
//         async payload() {
//             const response = await fetch(url, {
//                 headers: {
//                     ...getHeaders(oidc.user),
//                     'Content-Type': 'application/json',
//                 },
//                 method: 'DELETE',
//             });

//             await dispatch(fetchSettings(oidc.user));
//         },
//     });
// };

// export const removeReviewer = (idString) => async (dispatch, getState) => {
//     const {minervaSettings, oidc} = getState();
//     const url = `${minervaSettings.settingsUrl}/api/departmentReviewer/${minervaSettings.portalIdentifier}?idString=${encodeURIComponent(idString)}`;

//     dispatch({
//         type: REMOVE_REVIEWER,
//         async payload() {
//             const response = await fetch(url, {
//                 headers: {
//                     ...getHeaders(oidc.user),
//                     'Content-Type': 'application/json',
//                 },
//                 method: 'DELETE',
//             });

//             await dispatch(fetchSettings(oidc.user));
//         },
//     });
// };

function* doSetWelcomeVideoOpened() {
    yield put({
        type: 'SET_WELCOME_VIDEO_OPENED',
        payload: {
            shouldOpenWelcomeVideo: false,
        },
    });
}

function* setHideWelcomeVideo(action) {
    const payload = action.payload;
    const state = yield select();
    const oidc = state.oidc;
    const headers = getHeaders(oidc.user);

    yield call(
        fetch,
        `${state.minervaSettings.settingsUrl}/api/userSettings/${state.minervaSettings.portalIdentifier}`,
        {
            headers: {
                ...headers,
                'Content-type': 'application/json',
            },
            method: 'PUT',
            body: JSON.stringify({
                hideIntroVideoVersion: payload.version,
            }),
        },
    );
}

function* setDepartmentMatrixVisible(action) {
    const {minervaSettings} = yield select();

    yield put({
        type: 'DEPARTMENT_MATRIX_VISIBLE_SET',
        payload: {
            departmentSkillsMatrixViewEnabled: !minervaSettings.departmentSkillsMatrixViewEnabled,
        },
    });
}

export function* settingSaga() {
    yield takeLatest('MINERVASETTINGS_REQUESTED', doFetchSettings);
    yield takeLatest('WELCOME_VIDEO_OPENED', doSetWelcomeVideoOpened);
    yield takeLatest('SET_HIDE_WELCOME_VIDEO', setHideWelcomeVideo);
    yield takeLatest('TOGGLE_DEPARTMENT_MATRIX_VISIBLE', setDepartmentMatrixVisible);
}
